<div class="color-picker">
<!--  <div class="summary-info" (click)="toggleColors()">
    <div class="info" *ngIf="show">
      <p class="style">{{ heading }}</p>
      <p class="style-value">{{ color }}</p>
    </div>

    <div class="circle" [ngStyle]="{'background': color}"></div>
  </div>-->

  <div *ngIf="show" class="opened">
    <div class="colors">
      <div (click)="changeColor(paint)" *ngFor="let paint of defaultColors" [class.circle]="true" (keyup)="onKeyboardEvent($event)"
           [ngStyle]="{'background': paint}" [class.usedColor]="isAUsedColor(paint)" [class.currentColor]="color == paint"><span style="color:#FFFFFF; font-size:.5em; position: relative; top: 6px; left: -5px;" *ngIf="paint == '#000000'">ERASE</span></div>
    </div>

<!--    <div class="hex-code">
      <p>Hex Code</p>
      <div class="g-input">
        <input type="text" maxlength="7" [value]="color"
               (keyup)="changeColorManual(paintInput.value)"
               (keydown.enter)="toggleColors()"
               #paintInput/>
      </div>
    </div>-->
  </div>
</div>
