import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import {NetflixSeries} from "../../app/domain/database/NetflixSeries";

@Component({
	selector: '.series-undergoing-captioning',
	templateUrl: './series-undergoing-captioning.component.html',
	styleUrls: ['./series-undergoing-captioning.component.scss']
})
export class SeriesUndergoingCaptioningComponent implements OnInit {

	public icons = {
		faArrowRight: faArrowRight
	};

	@Input()
	public series:NetflixSeries;

	constructor(protected router:Router) {

	}

	ngOnInit() {

	}

	public view() {
		this.router.navigate(['series', this.series.guid]);
	}


}
