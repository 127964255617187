


export class UserRef {
  public guid:string = "anonymous-giraffe";
  public nickname:string = ""; //rather than the guid

  constructor() {

  }
  public get easyName():string {
  	return (this.nickname != '') ? this.nickname : this.guid;
  }
}
