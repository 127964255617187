import {UserRef} from './UserRef';
import {Type} from "class-transformer";
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export class CaptionComment {
	public guid:string;
	public movieId:string;
	public captionGuid:string;
	public captionIndex:number;
	@Type(()=> UserRef)
	public userRef:UserRef = new UserRef();
	public comment:string;
	public date:Timestamp;

}
