import {Exclude} from "class-transformer";

export class UpcomingEvent {

    @Exclude({toPlainOnly:true})
    public guid:string;
    public title:string = "Tuesdays: Avatar, The Last Airbender (The Series)";//"Easter Sunday ʻŌlelo Jam: `Hop`";
    public thumbnail:string = "";

    public date:string = "2020-09-29 12:00"
    public location:string = 'A community event hosted by Hiʻilani Shibata';
    public epochStartTime:number;
    public epochEndTime:number;
    public completed:boolean = false;

}
