import {Equals, MinLength} from "class-validator";
import {Exclude, Type} from "class-transformer";

import {UserAgreement} from "./UserAgreement";
import firebase from "firebase";
import firestore = firebase.firestore;
import Timestamp = firestore.Timestamp;


export class OleloflixUser {

	@Exclude()
	public guid:string; //User GUID

	@Type(()=> Timestamp)
	public created:Timestamp;

	@MinLength(2)
	public firstName:string = "";

	@MinLength(2)
	public lastName:string = "";

	@Type(()=>UserAgreement)
	public agreement:UserAgreement = new UserAgreement();

	public preferences = {
		color: null
	};

	//---------------------------------------------------------------------
	public get fullName():string {
		return `${this.firstName} ${this.lastName}`;
	}
}
