<div class="responsive-header">
	<div class="mh-head first Sticky">
		<span class="mh-btns-left">
			<a class="" href="#menu"><i class="fa fa-align-justify"></i></a>
		</span>
		<span class="mh-text">
			<a href="newsfeed.html" title=""><img src="/assets/header/oleloflix-website-logo-44h.png" alt=""></a>
		</span>
		<span class="mh-btns-right">
			<a class="fa fa-sliders" href="#shoppingbag"></a>
		</span>
	</div>
	<div class="mh-head second">
		<form class="mh-form">
			<input #search name="foo" type="text" placeholder="Search Wehe²wiki²" (keyup.enter)="searchWehewehe($event.target.value)" [(ngModel)]="search.query">
			<a href="javascript:void(0)" (click)="searchWehewehe(search.query)" class="fa fa-search"></a>
		</form>
	</div>
	<div class="mh-head third">
		<ul class="setting-area bg-danger pl-2 pr-2">
			<li class="bg-danger"><h4 class="mt-3 px-2"><a class="text-white underline" href="https://olelo.link/jam">See the Jam Schedule!</a></h4></li>
		</ul>
	</div>

<!--
	<nav id="menu" class="res-menu">
		<ul>
			<li><span>Home Pages</span>
				<ul>
					<li><a href="index.html" title="">Pitnik Default</a></li>
					<li><a href="pitrest.html" title="">Pitrest</a></li>
					<li><a href="redpit.html" title="">Redpit</a></li>
					<li><a href="redpit-category.html" title="">Redpit Category</a></li>
					<li><a href="soundnik.html" title="">Soundnik</a></li>
					<li><a href="soundnik-detail.html" title="">Soundnik Single</a></li>
					<li><a href="career.html" title="">Pitjob</a></li>
					<li><a href="shop.html" title="">Shop</a></li>
					<li><a href="classified.html" title="">Classified</a></li>
					<li><a href="pitpoint.html" title="">PitPoint</a></li>
					<li><a href="pittube.html" title="">Pittube</a></li>
					<li><a href="chat-messenger.html" title="">Messenger</a></li>
				</ul>
			</li>
			<li><span>Pittube</span>
				<ul>
					<li><a href="pittube.html" title="">Pittube</a></li>
					<li><a href="pittube-detail.html" title="">Pittube single</a></li>
					<li><a href="pittube-category.html" title="">Pittube Category</a></li>
					<li><a href="pittube-channel.html" title="">Pittube Channel</a></li>
					<li><a href="pittube-search-result.html" title="">Pittube Search Result</a></li>
				</ul>
			</li>
			<li><span>PitPoint</span>
				<ul>
					<li><a href="pitpoint.html" title="">PitPoint</a></li>
					<li><a href="pitpoint-detail.html" title="">Pitpoint Detail</a></li>
					<li><a href="pitpoint-list.html" title="">Pitpoint List style</a></li>
					<li><a href="pitpoint-without-baner.html" title="">Pitpoint without Banner</a></li>
					<li><a href="pitpoint-search-result.html" title="">Pitpoint Search</a></li>
				</ul>
			</li>
			<li><span>Pitjob</span>
				<ul>
					<li><a href="career.html" title="">Pitjob</a></li>
					<li><a href="career-detail.html" title="">Pitjob Detail</a></li>
					<li><a href="career-search-result.html" title="">Job seach page</a></li>
					<li><a href="social-post-detail.html" title="">Social Post Detail</a></li>
				</ul>
			</li>
			<li><span>Timeline</span>
				<ul>
					<li><a href="timeline.html" title="">Timeline</a></li>
					<li><a href="timeline-photos.html" title="">Timeline Photos</a></li>
					<li><a href="timeline-videos.html" title="">Timeline Videos</a></li>
					<li><a href="timeline-groups.html" title="">Timeline Groups</a></li>
					<li><a href="timeline-friends.html" title="">Timeline Friends</a></li>
					<li><a href="timeline-friends2.html" title="">Timeline Friends-2</a></li>
					<li><a href="about.html" title="">Timeline About</a></li>
					<li><a href="blog-posts.html" title="">Timeline Blog</a></li>
					<li><a href="friends-birthday.html" title="">Friends' Birthday</a></li>
					<li><a href="newsfeed.html" title="">Newsfeed</a></li>
					<li><a href="search-result.html" title="">Search Result</a></li>
				</ul>
			</li>
			<li><span>Favourit Page</span>
				<ul>
					<li><a href="fav-page.html" title="">Favourit Page</a></li>
					<li><a href="fav-favers.html" title="">Fav Page Likers</a></li>
					<li><a href="fav-events.html" title="">Fav Events</a></li>
					<li><a href="fav-event-invitations.html" title="">Fav Event Invitations</a></li>
					<li><a href="fav-page-create.html" title="">Create New Page</a></li>
				</ul>
			</li>
			<li><span>Forum</span>
				<ul>
					<li><a href="forum.html" title="">Forum</a></li>
					<li><a href="forum-create-topic.html" title="">Forum Create Topic</a></li>
					<li><a href="forum-open-topic.html" title="">Forum Open Topic</a></li>
					<li><a href="forums-category.html" title="">Forum Category</a></li>
				</ul>
			</li>
			<li><span>Featured</span>
				<ul>
					<li><a href="chat-messenger.html" title="">Messenger (Chatting)</a></li>
					<li><a href="notifications.html" title="">Notifications</a></li>
					<li><a href="badges.html" title="">Badges</a></li>
					<li><a href="faq.html" title="">Faq's</a></li>
					<li><a href="contribution.html" title="">Contriburion Page</a></li>
					<li><a href="manage-page.html" title="">Manage Page</a></li>
					<li><a href="weather-forecast.html" title="">weather-forecast</a></li>
					<li><a href="statistics.html" title="">Statics/Analytics</a></li>
					<li><a href="shop-cart.html" title="">Shop Cart</a></li>
				</ul>
			</li>
			<li><span>Account Setting</span>
				<ul>
					<li><a href="setting.html" title="">Setting</a></li>
					<li><a href="privacy.html" title="">Privacy</a></li>
					<li><a href="support-and-help.html" title="">Support & Help</a></li>
					<li><a href="support-and-help-detail.html" title="">Support Detail</a></li>
					<li><a href="support-and-help-search-result.html" title="">Support Search</a></li>
				</ul>
			</li>
			<li><span>Authentication</span>
				<ul>
					<li><a href="login.html" title="">Login Page</a></li>
					<li><a href="register.html" title="">Register Page</a></li>
					<li><a href="logout.html" title="">Logout Page</a></li>
					<li><a href="coming-soon.html" title="">Coming Soon</a></li>
					<li><a href="error-404.html" title="">Error 404</a></li>
					<li><a href="error-404-2.html" title="">Error 404-2</a></li>
					<li><a href="error-500.html" title="">Error 500</a></li>
				</ul>
			</li>
			<li><span>Tools</span>
				<ul>
					<li><a href="typography.html" title="">Typography</a></li>
					<li><a href="popup-modals.html" title="">Popups/Modals</a></li>
					<li><a href="post-versions.html" title="">Post Versions</a></li>
					<li><a href="widgets.html" title="">Widgets</a></li>
				</ul>
			</li>
		</ul>
	</nav>
-->
<!--
	<nav id="shoppingbag">
		<div>
			<div class="">
				<form method="post">
					<div class="setting-row">
						<span>use night mode</span>
						<input type="checkbox" id="nightmode"/>
						<label for="nightmode" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Notifications</span>
						<input type="checkbox" id="switch2"/>
						<label for="switch2" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Notification sound</span>
						<input type="checkbox" id="switch3"/>
						<label for="switch3" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>My profile</span>
						<input type="checkbox" id="switch4"/>
						<label for="switch4" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Show profile</span>
						<input type="checkbox" id="switch5"/>
						<label for="switch5" data-on-label="ON" data-off-label="OFF"></label>
					</div>
				</form>
				<h4 class="panel-title">Account Setting</h4>
				<form method="post">
					<div class="setting-row">
						<span>Sub users</span>
						<input type="checkbox" id="switch6" />
						<label for="switch6" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>personal account</span>
						<input type="checkbox" id="switch7" />
						<label for="switch7" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Business account</span>
						<input type="checkbox" id="switch8" />
						<label for="switch8" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Show me online</span>
						<input type="checkbox" id="switch9" />
						<label for="switch9" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Delete history</span>
						<input type="checkbox" id="switch10" />
						<label for="switch10" data-on-label="ON" data-off-label="OFF"></label>
					</div>
					<div class="setting-row">
						<span>Expose author name</span>
						<input type="checkbox" id="switch11" />
						<label for="switch11" data-on-label="ON" data-off-label="OFF"></label>
					</div>
				</form>
			</div>
		</div>
	</nav>
-->
</div><!-- responsive header -->

<div class="topbar stick oleloflix-header-bg"  style="position: fixed;width: 100%; top:0px;">
	<div class="logo">
		<a title="Home" [routerLink]="['']"><img id="oleloflix-header-logo" src="/assets/images/header/oleloflix-logo-single-waa.png"  alt=""></a>
	</div>
	<div class="top-area">
		<div class="top-search">
			<form method="post" class="">
				<input #search name="foo" type="text" placeholder="Search Wehewehe Wikiwiki..." (keyup.enter)="searchWehewehe($event.target.value)" [(ngModel)]="search.query">
				<button data-ripple  (click)="onSearchClear()"><i class="fa fa-times" ></i></button>
			</form>
		</div>

		<ul class="setting-area">
			<li><a [routerLink]="['']" title="Home" data-ripple=""><i class="fa fa-home text-white"></i></a></li>
		</ul>
		<ul class="setting-area bg-danger pl-2 pr-2">
			<li class="bg-danger">
				<h4 class="mt-3 px-2"><a class="text-white underline" href="https://olelo.link/jam">See the Jam Schedule!</a>
				</h4>
			</li>
		</ul>

		<ul class="setting-area bg-danger pl-2 pr-2" *ngIf="settings && settings.isActive">
			<li class="bg-danger">
				<p class="pt-3 px-2 mb-0 text-center text-white">
					<a class="text-white underline" href="https://olelo.link/jam">
						An ʻŌlelo Jam in in Progress. <button (click)="launchZoom()">Join the Jam</button>
					</a>
				</p>
			</li>
		</ul>
		<div class="user-img" >
			<h5>{{user ? user.easyName : "Anonymous"}}</h5>
			<img [src]="getAvatarUrl()" alt="" (click)="settingsVisible=!settingsVisible">
			<span class="status f-online"></span>
			<div class="user-setting" *ngIf="settingsVisible && false" [@fadeInOut]>
				<span class="seting-title">User settings <a href="#" title="" class="d-none">see all</a></span>
				<ul class="log-out">
					<li><a href="javascript:void(0)" title="Logout" (click)="this.logout()" ><i class="ti-power-off"></i>log out</a></li>
				</ul>
			</div>
		</div>
<!--
		<span class="ti-settings main-menu" data-ripple=""></span>
-->
	</div>
</div><!-- topbar -->
<div class="header-spacing"></div>
