import {Injectable} from '@angular/core';
import {NetflixStubData} from '../data/NetflixStubData';
import {TimedNetflixCaptionRepository} from '../repository/TimedNetflixCaptionRepository';
import {TimedTextCaption} from '../domain/timedtext/TimedText';
import {ConversionService} from './ConversionService';
import {TimedNetflixCaption} from '../domain/database/TimedNetflixCaption';
import {Episode, NetflixSeries, SeasonOrPart} from "../domain/database/NetflixSeries";
import {NetflixSeriesService} from "./NetflixSeriesService";
import {Subscription} from "rxjs";

@Injectable()
export class ProvisioningService {
    constructor(private netflixStubData: NetflixStubData,
                private timedNetflixCaptionRepository: TimedNetflixCaptionRepository,
                private conversionService: ConversionService,
                protected seriesService: NetflixSeriesService
    ) {

    }

    public provisionData(): void {
        let ttArray: Array<TimedTextCaption> = this.netflixStubData.getThorStubData();
        let netflixCaptionsToSave: Array<TimedNetflixCaption> = [];
        for (let i: number = 0; i < ttArray.length; i++) {
            let timedTextCaption: TimedTextCaption = ttArray[i];

            let timedNetflixCaption: TimedNetflixCaption = this.conversionService.convert(timedTextCaption, TimedNetflixCaption);
            timedNetflixCaption.index = i;

            netflixCaptionsToSave.push(timedNetflixCaption);

        }
        this.timedNetflixCaptionRepository.saveAll$(netflixCaptionsToSave);
    }

/*    public enableAvatar(): void {
        this.seriesService.setSeriesEpisodeEnabledStatus("avatar-the-last-airbender", true);
    }
    public disableAvatar(): void {
        this.seriesService.setSeriesEpisodeEnabledStatus("avatar-the-last-airbender", false);
    }*/


    public generateEpisode: Function = (array: Array<string>): Episode => {
        return new Episode({
            movieGuid: array[0],
            episodeNumber: parseInt(array[1]),
            label: array[2],
            minutesLength: array[3],
            description: array[4],
            enabled: true,
            creditPlain: "",
        });
    }
    public generateEpisodes = (arrays: Array<string[]>): Array<Episode> => {
        let episodes: Array<Episode> = [];
        for (let array of arrays) {
            episodes.push(this.generateEpisode(array));
        }
        return episodes;
    }
    public generateMoviePickNight():void {

        let moviePickNight = new SeasonOrPart()
        moviePickNight.label = "Your Pick Movie Night";
        moviePickNight.seasonId = "your-pick-movie-night-1";
        moviePickNight.episodes = this.generateEpisodes([
            ["60010110", "1", "Back to the Future","1h 56m",""],
            ["563104",   "2", "Groundhog Day","1h 41m",""],
            ["70196252", "3", "Breaking Bad, Season 1, Episode 1","58m",""],
            ["81232501", "4", "Jeopardy","20m",""],
            ["80077368", "5", "Stranger Things, Chapter 1 Episode 1","48m",""],
            ["81044813", "6", "Secret Life of Pets 2","1h 26m",""],
            ["60036164", "7", "The Karate Kid","2h 6m",""],
            ["986823",	 "8", "Spaceballs","1h 36m",""],
            ["81029280", "9", "Pose","1h 18m",""],
            ["60022398", "10", "E.T","1h 54m",""],
            ["81054338", "11", "The floor is lava, Season 1, Episode 4","31m",""],
            ["80168188", "12", "Chasing Coral","1h 29m",""],
            ["70041962", "13", "Charlotte's Web","1h 37m",""],
            ["80217130", "14", "Mary and the Witches Flower","1h 43m",""],
            ["70115629", "15", "Despicable Me","1h 34m",""],
            ["60024997", "16", "Frida","1h 34m",""]
        ]);


        let yourPickSeries: NetflixSeries = new NetflixSeries();
            yourPickSeries.guid = "your-pick-series";
            yourPickSeries.title = "Your Pick Movie Night";
            yourPickSeries.image = null;
            yourPickSeries.hasCustomPoster = true;
            yourPickSeries.seasons.push(moviePickNight);
            yourPickSeries["@episodes"] = yourPickSeries.toEpisodes().map(episode => {
                return episode.movieGuid;
            });

        this.seriesService.save$(yourPickSeries);

    }


    public generateAvatar(): void {


        return;
        let generateEpisode: Function = (array: Array<string>): Episode => {

            return new Episode({
                movieGuid: array[0],
                episodeNumber: parseInt(array[1]),
                label: array[2],
                minutesLength: array[3],
                description: array[4],
                enabled: true,
                creditPlain: "",
            });
        }
        //"70116061", "1", "The Boy in the Iceberg", "23m", "Katara and Sokka make a startling discovery while fishing: a boy frozen in an iceberg, perfectly preserved and -- amazingly -- alive."],

        let generateEpisodes = (arrays: Array<string[]>): Array<Episode> => {
            let episodes: Array<Episode> = [];
            for (let array of arrays) {
                episodes.push(generateEpisode(array));
            }
            return episodes;
        }


        let book1 = new SeasonOrPart()
        book1.label = "Avatar: The Last Airbender: Book 1";
        book1.seasonId = "book-1";
        book1.episodes = generateEpisodes([
            ["70116061", "1", "The Boy in the Iceberg", "23m", "Katara and Sokka make a startling discovery while fishing: a boy frozen in an iceberg, perfectly preserved and -- amazingly -- alive."],
            ["70116062", "2", "The Avatar Returns", "23m", "An accident results in Aang's expulsion from the Southern Water Tribe, and the villagers soon find themselves beset by sinister Prince Zuko."],
            ["70116063", "3", "The Southern Air Temple", "23m", "Aang returns to his childhood temple, hoping to find evidence that his people still thrive in the region, but he makes a shocking discovery."],
            ["70116064", "4", "The Warriors of Kyoshi", "23m", "Aang, Sokka and Katara go to the island of Kyoshi, where they receive a chilly reception. After Aang proves he's the Avatar, he becomes a celebrity."],
            ["70116065", "5", "The King of Omashu", "23m", "Sokka and Katara must again indulge Aang when he drags them to the Earth Kingdom city of Omashu, another favorite haunting ground of his youth."],
            ["70116066", "6", "Imprisoned", "23m", "Aang and the others find an Earth Kingdom mining town under the thumb of the Fire Nation, and Katara feels guilty when her actions lead to an arrest."],
            ["70116067", "7", "Winter Solstice: Part 1: The Spirit World", "23m", "Aang takes on the task of defending a town from an interdimensional monster, but his untested skills and uncertainty could prove the town's undoing."],
            ["70116068", "8", "Winter Solstice: Part 2: Avatar Roku", "23m", "During the winter solstice, Aang must travel into the Fire Nation -- which wants to capture him -- to communicate with his predecessor, Avatar Roku."],
            ["70116069", "9", "The Waterbending Scroll", "23m", "While teaching Aang about waterbending, Katara realizes her skills are inadequate. Luckily, she comes across a waterbending scroll at a boutique."],
            ["70116070", "10", "Jet", "23m", "A band of rebel guerillas with a charming, roguish leader rescues Aang, Sokka and Katara as they're fleeing the Fire Nation's minions."],
            ["70116071", "11", "The Great Divide", "24m", "Arriving at a giant gorge, Aang and his friends encounter two feuding refugee groups fighting over the right to cross the abyss."],
            ["70116072", "12", "The Storm", "24m", "When Aang, Katara and Sokka find themselves broke, Katara urges Sokka to take a fishing job. But the plan goes awry when the angler recognizes Aang."],
            ["70116073", "13", "The Blue Spirit", "23m", "With Sokka and Katara still ailing from the effects of the storm, Aang must find some frozen frogs to cure his cohorts."],
            ["70116074", "14", "The Fortune-Teller", "24m", "Aang, Sokka and Katara come across a village that's dependent on its resident fortune-teller, who has reliably predicted its future for generations."],
            ["70116075", "15", "Bato of the Water Tribe", "24m", "Aang acts childish during a reunion between Sokka, Katara and a longtime friend. Fearing that Sokka and Katara will desert him, Aang betrays them."],
            ["70116076", "16", "The Deserter", "24m", "Aang and the others slip into a Fire Nation town so he can observe firebending firsthand. But their plans go quickly wrong, and they must seek refuge."],
            ["70116077", "17", "The Northern Air Temple", "23m", "On their continuing journey north, Aang and the others hear rumors about a surviving band of airbenders, requiring a visit to the Northern Air Temple."],
            ["70116078", "18", "The Waterbending Master", "24m", "After going to the home of the Northern Water Tribe, Aang and Katara search for a waterbending master, and Sokka becomes smitten with a princess."],
            ["70116079", "19", "The Siege of the North: Part 1", "23m", "After hunting his prey halfway around the world, Adm. Zhao zeroes in on Aang's location and prepares to lay siege to the entire Northern Water Tribe."],
            ["70116080", "20", "The Siege of the North: Part 2", "24m", "As Adm. Zhao's army continues its assault on the beleaguered Northern Water Tribe, Sokka, Katara and Yue try to retrieve Aang from the spirit world."]
        ]);


        let book2 = new SeasonOrPart()
        book2.label = "Avatar: The Last Airbender: Book 2";
        book2.seasonId = "book-2";
        book2.episodes = generateEpisodes([
            ["70136314", "1", "The Avatar State", "24m", "Aang and the group meet an Earth Kingdom general who wants to use Aang's powerful \"Avatar State\" as a weapon to defeat the Fire Nation."],
            ["70136315", "2", "The Cave of Two Lovers", "24m", "On their way to Omashu, the kids become lost in the treacherous \"Cave of Two Lovers.\""],
            ["70136316", "3", "Return to Omashu", "24m", "Aang enters Omashu so he can learn Earthbending from King Bumi, but the city is in the hands of the Fire Nation."],
            ["70136317", "4", "The Swamp", "24m", "When the kids end up in a mysterious and strange swamp, their fears are exposed."],
            ["70136318", "5", "Avatar Day", "24m", "Aang must clear up a wave of anti-Avatar sentiment and atone for something he did in the past."],
            ["70136319", "6", "The Blind Bandit", "24m", "Aang discovers a possible Earthbending mentor at an underground tournament."],
            ["70136320", "7", "Zuko Alone", "24m", "Traveling without uncle Iroh now, Zuko wanders alone into an Earth Kingdom town where he bonds with a local boy."],
            ["70136321", "8", "The Chase", "24m", "As the kids are pursued relentlessly by a mysterious machine, their exhaustion puts them at each other's throats."],
            ["70136322", "9", "Bitter Work", "24m", "When Aang struggles with a block while trying to learn Earthbending from Toph, he wonders if the problem is with him or his teacher."],
            ["70136323", "10", "The Library", "24m", "A professor leads the kids to a spirit library in the middle of the desert, where Sokka hopes to discover secrets to use against the Fire Nation."],
            ["70136324", "11", "The Desert", "24m", "Aang searches for Appa, while Katara struggles to keep everyone together so they can survive in the vast desert."],
            ["70136355", "12", "Journey to Ba Sing Se: Part 1: The Serpent's Pass", "24m", "Leaving the desert for Ba Sing Se, Suki and friends guide Earth Kingdom refugees through a treacherous pass. Meanwhile, Zuko meets a new friend, Jet."],
            ["70136356", "13", "Journey to Ba Sing Se: Part 2: The Drill", "24m", "At the outer wall of Ba Sing Se, the gang faces the Fire Nation army, while Jet becomes suspicious of Zuko and Iroh, whose cover might be blown."],
            ["70136325", "14", "City of Walls and Secrets", "24m", "Aang and the kids finally arrive in Ba Sing Se to see the Earth King, only to find mysterious forces within the city conspiring to stop them."],
            ["70136326", "15", "Tales of Ba Sing Se", "24m", "This series of short stories highlights different characters and their individual adventures in the city of Ba Sing Se."],
            ["70136327", "16", "Appa's Lost Days", "24m", "In this special episode, we go back in time to the moment Appa was stolen."],
            ["70136328", "17", "Lake Laogai", "24m", "While searching for Appa, the kids run into Jet but are torn about whether to trust him or not. Meanwhile, Zuko also hunts for Appa."],
            ["70136329", "18", "The Earth King", "24m", "The kids battle Long Feng and the Dai Li when they attempt to show the Earth King the vast conspiracy taking place in his city."],
            ["70222657", "19", "The Guru", "24m", "A guru at the Eastern Air Temple helps Aang take the next step in his Avatar journey. Sokka meets his long lost father."],
            ["70222658", "20", "The Crossroads of Destiny", "24m", "Azula takes control of the Dai Li. Zuko is forced to make a fateful choice, leading to a battle that endangers Aang and all of his friends."]
        ]);
        let book3 = new SeasonOrPart();
        book3.label = "Avatar: The Last Airbender: Book 3";
        book3.seasonId = "book-3";
        book3.episodes = generateEpisodes([
            ["70136332", "1", "The Awakening", "24m", "After sustaining serious injuries at the end of Season 2, Aang awakens to find himself aboard a Fire Nation ship. Meanwhile, Zuko journeys home."],
            ["70136333", "2", "The Headband", "24m", "To better camouflage themselves as real Fire Nation citizens, the kids check out a Fire Nation school. Also, Zuko confronts Uncle."],
            ["70136334", "3", "The Painted Lady", "24m", "When the gang comes to a suffering fishing village, a mysterious spirit appears to help the villagers."],
            ["70136335", "4", "Sokka's Master", "24m", "When Sokka feels he's not contributing enough to the group, he seeks out a mysterious master to teach him the ways of the sword."],
            ["70136336", "5", "The Beach", "24m", "Zuko, Azula, Mai and Ty Lee go on vacation, where they learn a lot about themselves and each other. Meanwhile, the kids face a new enemy."],
            ["70136337", "6", "The Avatar and the Firelord", "24m", "Aang and Zuko are given insight into their forefathers' pasts -- but how does the tale of Roku and Sozin matter to them now?"],
            ["70136338", "7", "The Runaway", "24m", "When Toph discovers a quick way to make cash, Katara disapproves, and the rift between them has disastrous consequences."],
            ["70136339", "8", "The Puppetmaster", "24m", "The kids investigate mysterious disappearances in a spooky town. Katara makes a special connection."],
            ["70136340", "9", "Nightmares and Daydreams", "24m", "On the eve of the eclipse, Aang's anxiety gets the better of him. His dreams become nightmares, and soon he can no longer tell dream from reality."],
            ["70231579", "10", "The Day of the Black Sun: Part 1", "24m", "With the eclipse almost upon them, the kids prepare to invade the Fire Nation."],
            ["70231580", "11", "The Day of the Black Sun: Part 2", "24m", "While the forces siege the capital of the Fire Nation, the kids help Aang find the Fire Lord before the eclipse. But they're met with surprises..."],
            ["70136341", "12", "The Western Air Temple", "24m", "When our gang regroups at the Western Air temple, they find someone there they weren't expecting."],
            ["70136342", "13", "The Firebending Masters", "24m", "When it comes time for Zuko to teach Aang Firebending, the two set out to learn the true meaning of Firebending from the original teachers."],
            ["70231581", "14", "The Boiling Rock: Part 1", "24m", "Sokka and Zuko head toward the best-guarded prison in the Fire Nation, the Boiling Rock, hoping to find and break out the invading forces."],
            ["70231582", "15", "The Boiling Rock: Part 2", "24m", "Sokka and Zuko have to revise their escape plan when something goes wrong. They end up receiving help from unexpected places."],
            ["70136343", "16", "The Southern Raiders", "24m", "Katara sets out to confront the Fire Nation soldier who killed her mother. But what will she do when she finds him?"],
            ["70136344", "17", "The Ember Island Players", "24m", "The kids see a play about themselves and all their past adventures. But they aren't happy with the production."],
            ["70231583", "18", "Sozin's Comet: Part 1: The Phoenix King", "24m", "The kids get some new information on Fire Lord Ozai's master plan and decide to attack earlier than planned. Will Aang be ready?"],
            ["70231584", "19", "Sozin's Comet: Part 2: The Old Masters", "24m", "When Fire Lord Ozai puts his master plan in action, the group tries to stop him. Meanwhile, Aang seeks advice from his past lives."],
            ["70231585", "20", "Sozin's Comet: Part 3: Into the Inferno", "24m", "In the final battle, Zuko faces Azula and Aang finally confronts the Fire Lord."],
            ["70231586", "21", "Sozin's Comet: Part 4: Avatar Aang", "24m", "In the final battle, Zuko faces Azula and Aang finally confronts the Fire Lord."]
        ]);


        let avatarSeries: NetflixSeries = new NetflixSeries();
        avatarSeries.guid = "avatar-the-last-airbender";
        avatarSeries.title = "Avatar: The Last Airbender";
        avatarSeries.image = null;
        avatarSeries.hasCustomPoster = false;
        avatarSeries.seasons.push(book1, book2, book3);
        avatarSeries["@episodes"] = avatarSeries.toEpisodes().map(episode => {
            return episode.movieGuid;
        });

        this.seriesService.save$(avatarSeries);


    }
}
