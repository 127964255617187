// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    clientId: 'Y6oKpoV5OtGVjluNlQjecv6yhbiRqUrw',
    clientDomain: 'manastudios.auth0.com', // e.g., you.auth0.com
    audience: 'https://captioner-prd.firebaseapp.com/', // e.g., http://localhost:1337/
    redirect: 'https://captioner-prd.firebaseapp.com/#/callback',
    logoutUri: 'https://captioner-prd.firebaseapp.com/#/logout',
    scope: 'openid profile email',
  },
  environment: {
    baseUrl: 'https://captioner-prd.firebaseapp.com',
    env: 'local'
  },
  firebase: {
    apiKey: 'AIzaSyBoYom5dJ0Kycls0iU8sCDLTOOoMGG6oCA',
    authDomain: 'captioner-prd.firebaseapp.com',
    databaseURL: 'https://captioner-prd.firebaseio.com',
    projectId: 'captioner-prd',
    storageBucket: 'captioner-prd.appspot.com',
    messagingSenderId: '1017102668497',
    appId: '1:1017102668497:web:78ddc2ab8bee8b27'
  },
  zapier: {
    addMovieRequest: "https://hooks.zapier.com/hooks/catch/7287864/orqgzrw/",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
