<!--
<button (click)="translateService.prefillCaptionsForCurrentMovie()">prefillCaptionsForCurrentMovie</button>
-->
<div class="container m-0" style="max-width: inherit">
	<div class="row">
		<div class="col-8 bg-secondary text-white p-3 d-inline-block w-100">
			<button [routerLink]="['/series/', seriesGuid]" class="btn btn-dark" *ngIf="seriesGuid">
				<fa-layers size="1x">
					<fa-icon [icon]="icons.faArrowLeft" transform="grow-5" [inverse]="false"></fa-icon>
				</fa-layers>
				Return to Series Catalog</button>
			<button [routerLink]="['/']" class="btn btn-dark" *ngIf="!seriesGuid">
				<fa-layers size="1x">
					<fa-icon [icon]="icons.faArrowLeft" transform="grow-5" [inverse]="false"></fa-icon>
				</fa-layers>
				Return to Main Catalog</button>
			<img [src]="movieThumbnail == '1' ? posterUrl : movieThumbnail" alt="" class="rounded episode-thumbnail-header">
			<span *ngIf="netflixMovie" class="ml-3 text-warning"><strong class="text-white">Now captioning</strong> `{{movieLabel}}`</span><span class="ml-3 text-white-50"> ({{users.length}} kānaka)</span>
		</div>
		<div class="col-4 bg-secondary text-white p-3 d-inline-block w-100 text-right">
			<div *ngIf="user" class="d-inline-block text-right">
				<span>You are:<br/><b *ngIf="user.nickname == '' || user.nickname == null || this.nameEditMode == true; else useNickname">{{this.user.guid}}<input type="text" placeholder="enter your name" [(ngModel)]="this.input.desiredNickname" (keyup.enter)="this.saveNickname()"><button *ngIf="this.input.desiredNickname.trim().length >= 3" (click)="this.saveNickname()">Save </button>  </b><ng-template #useNickname><b><a (click)="nameEditMode = true; this.input.desiredNickname = user.nickname;">{{this.user.nickname}}</a></b></ng-template></span>
				<div *ngIf="this.user && this.user.guid" class="tag is-large d-inline-block text-center" >
					<!--<img [src]="this.user.avatar.getAvatarUrl(100)" class="clip-circle" [ngbTooltip]="this.user.guid" >-->
					<Animal circle="true" size="30px"  [ngbTooltip]="tooltip" [name]="user.avatar.animal" [color]="user.avatar.color"></Animal>
				</div>
			</div>
	</div>
</div>


<div class="spinner" *ngIf="groupedCaptionSet == null">

	<div class="double-bounce1"></div>
	<div class="double-bounce2"></div>
</div>
<div class="text-muted text-center" *ngIf="groupedCaptionSet == null"><br/>Loading...<br/>This is taking longer than normal..<br/>please be patient<br/>(at times, ten seconds or more). </div>
<br/>
<br/>
<div class="caption-count-container">
	<div class="caption-count"
		 [ngStyle]="{'left': 'calc(' + ((captionIndex/captions.length)* 100)+ '%)'}">{{captionIndex}}/{{captions.length}}</div>
</div>
<div class="user-container">
	<ng-container *ngFor="let iUser of users">
		<app-user-status #userStatus class="user-item" *ngIf="user" [guid]="iUser.guid" [url]="iUser.avatar.getAvatarUrl(50)"
						 [user]="iUser" [isCurrent]="iUser && this.user && iUser.guid == this.user.guid"
						 [ngStyle]="{'left': this.avatarLoc(iUser.caption/captions.length, 0) }"
						 (click)="onUserClick(iUser)"></app-user-status>
	</ng-container>
</div>
<app-grid-timeline count="100" (selected)="onTimelineSelectEvent($event)" [captionDiary]="captionDiary" *ngIf="captionDiary"></app-grid-timeline>

<div class="film-strip" *ngIf="groupedCaptionSet != null">
	<div class="top"></div>
	<div class="middle w-100 h-100">
		<div class="row w-100 h-100">
			<div class="col-1  w-100 h-100">
				<button (click)="previousUncaptionedCaption()" ngbTooltip="Previous Uncaptioned" class="w-100 h-50" *ngIf="this.toolMode != ToolMode.Flag">
					<fa-layers size="1x">
						<fa-icon [icon]="icons.faStepBackward" transform="shrink-2" [inverse]="false"></fa-icon>
					</fa-layers>
				</button>
				<button (click)="previousCaption()" ngbTooltip="Back 1 Caption" class="w-100 h-50">&#x3C;</button>
				<button (click)="previousFlag()" class="w-100 h-50" ngbTooltip="Back 1 Flag" *ngIf="toolMode == ToolMode.Flag">&#x3C;
					<br>
					<fa-layers size="1x">
						<fa-icon [icon]="faFlag" transform="shrink-2" [inverse]="false"></fa-icon>
					</fa-layers>
				</button>
			</div>
			<div class="col-10 p-2">
				<button (click)="this.flagged = !this.flagged" class="captioner-button" ngbTooltip="Ask for Help/Not Sure" [ngClass]="{enabled: this.flagged}" ><!--*ngIf="this.toolMode == ToolMode.Flag"-->
					<fa-layers size="2x">
						<fa-icon [icon]="faCircle" transform="grow-5" [inverse]="false"></fa-icon>
						<fa-icon [icon]="faFlag" transform="shrink-2" [inverse]="true"></fa-icon>
					</fa-layers>
				</button>

				<button (click)="watch()" class="captioner-button" ngbTooltip="Play on Netflix*" class="ml-3 border-0"><!--*ngIf="this.toolMode == ToolMode.Flag"-->
					<fa-layers size="2x">
						<fa-icon [icon]="faCircle" transform="grow-5" [inverse]="false"></fa-icon>
						<fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="true"></fa-icon>
					</fa-layers>
				</button>

				<button (click)="saveFlashcard()" class="captioner-button" ngbTooltip="Save Flashcard" *ngIf="false" [disabled]="!this.currentCaption.hasColors" class="ml-3 border-0">
					<fa-layers size="2x">
						<fa-icon [icon]="faCircle" transform="grow-5" [inverse]="false"></fa-icon>
						<fa-icon [icon]="icons.faPlus" transform="shrink-9 right-4" [inverse]="true"></fa-icon>
						<fa-icon [icon]="icons.faHeart" transform="shrink-9 left-4" [inverse]="true"></fa-icon>
					</fa-layers>
				</button>

				<div class="m-2" *ngIf="this.flagged">
					<b>Enter a comment related to this caption (#{{captionIndex}}):</b><br/>
					<input type="text" (keyup.enter)="captionSocialComponent.submitComment()" *ngIf="this.flagged" [(ngModel)]="this.captionSocialComponent.newCaptionComment.comment" placeholder="(optional) enter a comment related to this" class="w-75">&nbsp;
					<button (click)="captionSocialComponent.submitComment()">Submit</button>
				</div>

				<div style="text-align:center" (click)="onClick($event)" *ngIf="groupedCaptionSet != null"
					 (keyup.arrowLeft)="onKeyboardEvent($event)" (keyup.arrowRight)="onKeyboardEvent($event)">
					<div>
						<p *ngIf="currentCaption">
            <span style="font-style: italic; color: #999999;"
				  *ngIf="currentCaption.originalContent != groupedCaptionSet.set1Content"
				  [textContent]="currentCaption.originalContent"></span>
						</p>
						<textarea autosize [(ngModel)]="groupedCaptionSet.set1Content" (focus)="onTextBoxFocus($event)"
								  (blur)="onTextBoxBlur($event);" *ngIf="mode[1]==MODE.EDIT"
								  [class.singleLine]="isSingleLine(2)"
								  placeholder="Enter an alternate English caption..." [ngStyle]="{cursor: this.toolMode == ToolMode.Question ? 'help' : 'auto'}"></textarea>

						<app-selectable-caption-set
								*ngIf="mode[1]==MODE.COLOR"
								[cursor]="wordCursor"
								[selectedColor]="color" [captionWords]="groupedCaptionSet.set1"
								(onWordClick)="onWordClick($event)"></app-selectable-caption-set>
						<button (click)="onEditClick(1)" class="captioner-button" ngbTooltip="Edit" *ngIf="mode[1]!=MODE.EDIT && this.toolMode == ToolMode.Translate">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faEdit" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
						<button (click)="onUndoClick(1)" class="captioner-button" ngbTooltip="Undo" *ngIf="mode[1]==MODE.EDIT && this.toolMode == ToolMode.Translate">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faUndo" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
						<!-- SAVE -->
						<button (click)="onAcknowledgeClick(1)" class="captioner-button" ngbTooltip="Save" *ngIf="mode[1]==MODE.EDIT && this.toolMode == ToolMode.Translate">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faSave" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
					</div>

					<div>
          <textarea autosize [(ngModel)]="groupedCaptionSet.set2Content" (focus)="onTextBoxFocus($event)" #oleloEntryControl
					(blur)="onTextBoxBlur($event);" *ngIf="mode[2]==MODE.EDIT && this.toolMode == ToolMode.Translate" [class.singleLine]="isSingleLine(2)"
					placeholder="Enter a ʻŌlelo Hawaiʻi translation...">></textarea>
						<!-- UNDO -->
						<button (click)="onUndoClick(2)" class="captioner-button" ngbTooltip="Undo" *ngIf="mode[2]==MODE.EDIT && this.toolMode == ToolMode.Translate">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faUndo" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
						<!-- SAVE -->
						<button (click)="onAcknowledgeClick(2)" class="captioner-button" ngbTooltip="Save" *ngIf="mode[2]==MODE.EDIT  && this.toolMode == ToolMode.Translate">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faSave" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
						<button (click)="onTranslateClick()" class="captioner-button"  ngbTooltip="Use Google Translate" *ngIf="(mode[2]==MODE.EDIT  && this.toolMode == ToolMode.Translate) || (this.toolMode == ToolMode.AutomatedTranslate)">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faGlobe" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
						<app-selectable-caption-set *ngIf="mode[2]==MODE.COLOR" [selectedColor]="color"
													[captionWords]="groupedCaptionSet.set2"
													[cursor]="wordCursor"
													(onWordClick)="onWordClick($event)"></app-selectable-caption-set>
						<app-olelo-entry-controls (letter)="appendTextArea($event)" *ngIf="this.toolMode == ToolMode.Translate && mode[2]==MODE.EDIT"></app-olelo-entry-controls>
						<!-- Edit Button -->
						<button (click)="onEditClick(2)" class="captioner-button" ngbTooltip="Edit" *ngIf="mode[2]!=MODE.EDIT && this.toolMode == ToolMode.Translate">
							<fa-layers size="1x">
								<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"></fa-icon>
								<fa-icon [icon]="faEdit" transform="shrink-2" [inverse]="true"></fa-icon>
							</fa-layers>
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-12 p-2">

					</div>
				</div>
				<div class="row">
					<div class="col-12 p-2">
						Modes:&nbsp;
						<button (click)="this.toolMode = ToolMode.Translate" ngbTooltip="Caption Mode" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Translate}">
							<fa-layers size="2x">
								<fa-icon [icon]="faClosedCaptioning" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>
						<button (click)="this.toolMode = ToolMode.Flag" ngbTooltip="Navigate Flags" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Flag}">
							<fa-layers size="2x">
								<fa-icon [icon]="faFlag" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>
						<button (click)="this.toolMode = ToolMode.Color" ngbTooltip="Mark Grammar" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Color}">
							<fa-layers size="2x">
								<fa-icon [icon]="faFillDrip" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>
						<button (click)="this.toolMode = ToolMode.Bank" ngbTooltip="Mark Interesting Words" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Bank}">
							<fa-layers size="2x">
								<fa-icon [icon]="faPiggyBank" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>
						<button (click)="this.toolMode = ToolMode.Question" class="captioner-button" ngbTooltip="Word Kōkua Lookup" [ngClass]="{enabled: this.toolMode == ToolMode.Question}">
							<fa-layers size="2x">
								<fa-icon [icon]="faQuestion" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>
<!--						<button (click)="this.toolMode = ToolMode.AutomatedTranslate" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.AutomatedTranslate}">
							<fa-layers size="1x">
								<fa-icon [icon]="faGlobe" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>-->
<!--						<button (click)="this.toolMode = ToolMode.Record" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Record}">
							<fa-layers size="1x">
								<fa-icon [icon]="faMicrophone" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>-->
<!--						<button (click)="this.toolMode = ToolMode.Discuss" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Discuss}">
							<fa-layers size="2x">
								<fa-icon [icon]="faComment" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>-->
<!--						<button (click)="this.toolMode = ToolMode.Mobile" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Mobile}">
							<fa-layers size="1x">
								<fa-icon [icon]="faMobileAlt" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>-->
<!--						<button (click)="this.toolMode = ToolMode.Preview" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Preview}">
							<fa-layers size="2x">
								<fa-icon [icon]="icons.faEye" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>-->
						<button (click)="this.toolMode = ToolMode.Publish" class="captioner-button" ngbTooltip="Publish Mode" [ngClass]="{enabled: this.toolMode == ToolMode.Publish}">
							<fa-layers size="2x">
								<fa-icon [icon]="icons.faCloudUploadAlt" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>
<!--						<button (click)="this.toolMode = ToolMode.Review" class="captioner-button" [ngClass]="{enabled: this.toolMode == ToolMode.Review}">
							<fa-layers size="1x">
								<fa-icon [icon]="icons.faCheck" transform="shrink-2" [inverse]="false"></fa-icon>
							</fa-layers>
						</button>-->
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</div>
				</div>
			</div>
			<div class="col-1 w-100 h-100">
				<button (click)="nextUncaptionedCaption()" class="w-100 h-50" ngbTooltip="Skip Next Uncaptioned" *ngIf="this.toolMode != ToolMode.Flag">
					<fa-layers size="1x">
						<fa-icon [icon]="icons.faStepForward" transform="shrink-2" [inverse]="false"></fa-icon>
					</fa-layers>
				</button>
				<button (click)="nextCaption()" class="w-100 h-50" ngbTooltip="Ahead 1 Caption">&#x3E;</button>
				<button (click)="nextFlag()" class="w-100 h-50" ngbTooltip="Ahead 1 Flag" *ngIf="toolMode == ToolMode.Flag">&#x3E;
					<br>
					<fa-layers size="1x">
						<fa-icon [icon]="faFlag" transform="shrink-2" [inverse]="false"></fa-icon>
					</fa-layers>
				</button>
			</div>
		</div>

	</div>
	<div class="bottom"></div>
	<div class="row">
		<div class="col-12">
			<div class="row">
				<div class="used-color-container" *ngIf="toolMode == ToolMode.Color">
					<div class="row mx-0">
						<div class="col-12 d-flex justify-content-center">
							<div class="used-color eraser" [ngClass]="{selected: (this.color == '000000')}" (click)="onUsedColorClick('#000000')">
								<fa-layers size="1x">
									<fa-icon [icon]="faCircle" transform="grow-2" [inverse]="false" [ngStyle]="{'color': '#000000'}"></fa-icon>
									<fa-icon  [icon]="faEraser" transform="shrink-4" [inverse]="true"></fa-icon>
								</fa-layers>
							</div>
							<div class="used-color" *ngFor="let usedColor of groupedCaptionSet.usedColors"
								 [ngStyle]="{'background-color': usedColor}" (click)="onUsedColorClick(usedColor)"
								 [ngClass]="{selected: (usedColor == '#' + this.color)}"></div>

							<div class="used-color" (click)="onColorDropperClick()">
								<fa-layers size="1x">
									<fa-icon [icon]="faCircle" transform="grow-10" [inverse]="false"
											 [ngStyle]="{'color': '#' + color}"></fa-icon>
									<fa-icon class="eyedropper" (click)="showColors=!showColors"
											 [ngClass]="{active: isPick}" [icon]="faEyeDropper" transform="shrink-2"
											 [inverse]="true"></fa-icon>
								</fa-layers>
							</div>
						</div>
							<div class="row w-100">
							<app-color-picker [show]="true" class="w-100" *ngIf="groupedCaptionSet"
											  color="{{'#' + color}}" heading="Select a Color "
											  (event)="setColor('main', $event)"
											  [usedColors]="groupedCaptionSet.usedColors"></app-color-picker>
						</div>
					</div>

				</div>
			</div>
			<div class="container m-0" style="max-width: inherit" *ngIf="this.usersOnSlide.length > 0">
				<div class="row">
					<div class="col bg-secondary text-white p-3 d-inline-block w-100 text-center d-inline-flex" *ngFor="let user of this.usersOnSlide">
						<!--<img [src]="this.user.avatar.getAvatarUrl(50)" class="clip-circle small" [ngbTooltip]="user.easyName" >-->
						<button *ngIf="followingUserGuid == null" class="btn btn-sm btn-dark" (click)="followingUserGuid=user.guid">
							<Animal circle="true" size="20px"  [ngbTooltip]="tooltip" [name]="user.avatar.animal" [color]="user.avatar.color"></Animal>&nbsp;Follow {{user.easyName}}</button>
						<button *ngIf="followingUserGuid != null && user.guid == followingUserGuid" (click)="followingUserGuid=null" class="btn btn-sm btn-danger">
							<Animal circle="true" size="20px"  [ngbTooltip]="tooltip" [name]="user.avatar.animal" [color]="user.avatar.color"></Animal>
							&nbsp;Stop Following</button>
					</div>
				</div>
			</div>
			<div class="row nav-container mx-auto">
				Go to: <input
					type="text" [(ngModel)]="desiredIndex" pattern="[0-9]*" (blur)="navigateTo(-1, desiredIndex)"
					(keyup.enter)="navigateTo(-1, desiredIndex);">
			</div>
			<div class="row" *ngIf="!isHelpModeOn">
				<div class="col-8">
				</div>
				<div class="col-4">
					<button (click)="isHelpModeOn = true" *ngIf="!isHelpModeOn" style="background-color: aquamarine">Show Help (Please click me!)</button>
				</div>
			</div>
			<div class="row">
				<div class="col">

					<app-flashcard-collection-section [groupedCaptionSet]="groupedCaptionSet" *ngIf="false">
					</app-flashcard-collection-section>
					<div id="word-bank" class="row container-fluid text-left">
						<h4><fa-icon [icon]="icons.faPiggyBank" [inverse]="false"></fa-icon> Word Bank <button class="btn btn-sm btn-outline-secondary"  type="button" (click)="addWordBank()">+</button></h4>
						<div class="container-fluid">
							<button [tooltip]="bankWord.suggestionsLabel" class="mx-1" *ngFor="let bankWord of bankWords" (click)="showWordBankModal(bankWord)" (shift)="shiftOn=$event">{{bankWord.set1Content}} <span class="badge badge-primary badge-pill">{{bankWord.suggestions.length}}</span></button>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="m-2">
								<b>Enter a comment related to this caption (#{{captionIndex}}):</b><br/>
								<app-caption-social #captionSocialComponent [captionGuid]="'subtitle' + captionIndex" [captionIndex]="captionIndex" [movieId]="this.currentMovie.movieId" [userRef]="this.currentUserRef" (gotoCaption)="gotoCaptionAt($event)"></app-caption-social>
							</div>
							<div *ngIf="toolMode == ToolMode.Record">
								<app-audio-recording-list></app-audio-recording-list>
							</div>

							<div *ngIf="toolMode == ToolMode.Mobile" class="row mx-auto">
								<div class="col-12 mt-2">
<!--
									<qrcode class="qr-code" [level]="1" [qrvalue]="routerUrl" size="200"></qrcode>
-->
								</div>
							</div>
							<div *ngIf="toolMode == ToolMode.Record">
								<app-audio-recording-list></app-audio-recording-list>
							</div>

							<div *ngIf="toolMode == ToolMode.Publish">
								<br/>
								<div class="col-12 text-center">
									<span class="font-weight-bold">Completeness</span>
									<h1>{{captionDiary.prettyPercentage}}</h1>
									<span>{{captionDiary.done}} / {{captionDiary.length}}</span>
									<br/>
									<br/>
									<p>
										<a class="playButton" (click)="watch({seconds: 0})">
											<fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
											<span class="playButtonText">Play Movie</span>
										</a>
									</p>
									<h3>
										<button href="javascript:void(0);" (click)="onSaveAllClick()">Publish to Netflix</button>
									</h3>
								</div>
							</div>
							<div *ngIf="toolMode == ToolMode.Review">
								<div *ngIf="this.currentCaption">
									<label for="caption">Caption</label>
									<input type="checkbox" id="caption" [(ngModel)]="this.currentCaption.stats.review.caption.approved" (change)="updateReviewItem(this.currentCaption.stats.review.captions)" />
									<br/>
									<label for="color">Color</label>
									<input type="checkbox" id="color" [(ngModel)]="this.currentCaption.stats.review.color.approved" (change)="updateReviewItem(this.currentCaption.stats.review.color)" />
									<br/>
									<label for="audio">Audio</label>
									<input type="checkbox" id="audio" [(ngModel)]="this.currentCaption.stats.review.audio.approved" (change)="updateReviewItem(this.currentCaption.stats.review.audio)" />
								</div>
								<!-- Caption -->
							</div>
							<div class="text-center mt-3">
								Hawaiian Keyboard Setup Instructions: <a href="https://www.hawaii.edu/askus/1767#430" target="_blank">Windows</a>&nbsp;or&nbsp;<a href="https://www.hawaii.edu/askus/428" target="_blank">Mac</a>
							</div>
						</div>

					</div>
				</div>
				<div class="col" *ngIf="isHelpModeOn">
					<app-help-assist #helpAssistComponent [toolMode]="this.toolMode" [on]="isHelpModeOn" (update)="isHelpModeOn = $event"></app-help-assist>
				</div>
			</div>



	</div>
</div>
