import {Injectable} from "@angular/core";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable} from "rxjs";

import {AbstractFirebaseFunctionsFactory} from "./standard/AbstractFirebaseFunctionsFactory";
import {AbstractFirebaseFunction, IServerError} from "./standard/AbstractFirebaseFunction";
import {NGXLogger} from 'ngx-logger';
import {SimpleResponse} from "../../core/responses/SimpleResponse";
import {DisneyPlusRequestData} from "./messages/disneyplus/DisneyPlusRequestData";

interface OleloflixFunctionWrappers {
	addCaptionFromSrt: AbstractFirebaseFunction<DisneyPlusRequestData, SimpleResponse>,
}

@Injectable({
	providedIn: 'root',
} as any)
export class OleloflixFunctions extends AbstractFirebaseFunctionsFactory {

	public methods:OleloflixFunctionWrappers;

	constructor(protected functions:AngularFireFunctions, protected logger:NGXLogger) {
		super(functions, {addCaptionFromSrt: [DisneyPlusRequestData, SimpleResponse]});
	}

	/** @throws IServerError<DisneyPlusRequestData>> */
	 public addCaptionFromSrt$(message:DisneyPlusRequestData):Observable<SimpleResponse | IServerError<DisneyPlusRequestData>> {
		this.logger.info(`OleloflixFunctions::register$`);

		let response = this.methods.addCaptionFromSrt.call(message);
		this.logger.info(`OleloflixFunctions::register$ done`);

		return response;
	}

}
