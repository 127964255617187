import {Subject, throwError} from "rxjs";

import {classToPlain, plainToClass} from "class-transformer";
import {filter, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';

import {AngularFireFunctions} from "@angular/fire/functions";

//Second Type is optional and defaults to the Type of the first Generic Parameter
export abstract class AbstractFirebaseFunction<T, R=T> {

	protected SendType:new () => T;
	protected ResponseType:new () => R;
	protected functions: AngularFireFunctions;
	protected ref:(data: T) => Observable<any>

	protected uri:string;

	constructor(functions:AngularFireFunctions, uri:string, Type: ((new () => T) | (Array<new () => (T|R)>))) {
		if(Array.isArray(Type)) {
			let typeAsArray:Array<new () => (T|R)> = Type;
			if(typeAsArray[0] == null|| typeAsArray[1] == null) {
				throw new Error("AbstractFirebaseFunction when passed an array needs two parts");
			}
			this.SendType = typeAsArray[0] as new () => (T);
			this.ResponseType = typeAsArray[1]  as new () => (R);
		} else {
			this.SendType = Type;
			this.ResponseType = Type as any as new () => (R);
		}
		this.functions = functions;
		this.uri = uri;
		this.ref = this.functions.httpsCallable(uri);
	}

	public call(message:T):Observable<R|IServerError<T>> {
		let item$:Subject<R|IServerError<T>> = new Subject();
		classToPlain(message);

		let plain:any = message;
		this.ref(plain)
			.catch((err, caught) => {
				let errorAs:IServerError<T> = err as IServerError<T>;
				console.error(`Something went wrong: ${err.message}`);
				return Observable.of(new ServerError<T>(err, message))
			})
			.pipe(
				take(1),
				filter(item => item!=null),
				map(doc => {
						//Get document data
						const data = doc;
						if(!(data instanceof ServerError)) {
							const dataAsClass:R = plainToClass<R, any>(this.ResponseType, data);
							return dataAsClass;
						} else {
							//throw(data as IServerError<T>)
							return data as IServerError<T>;
						}
					}
				))
			.subscribe(item => {
				item$.next(item);
				item$.complete();
			});
		return item$;
	}

}

export interface IFirebaseFunctions<T> {
	httpsCallableRef(functionName:string):(data: T) => Observable<any>;
	call(ref:(data: T) => Observable<any>, message:T):Observable<T>;
	callToArray(ref:(data: T) => Observable<any>, message:T):Observable<T[]>;
}
export class ServerError<T> implements IServerError<T>{
	code:string;
	details:any;
	message:string;
	sent:T;

	constructor(o:IServerError<T>, sent:T) {
		this.code = o.code;
		this.message = o.message;
		this.details = o.details;
		this.sent = sent;
	}
}
export interface IServerError<T> {
	details: any,
	/** What we called the error on the server */
	code:string,
	/** A description */
	message:string,
	sent:T;
}
