import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NotificationService} from "../services/NotificationService";
import {OleloJamRegistrationModalComponent} from "../modals/olelo-jam-registration-modal/olelo-jam-registration-modal.component";
import {OleloJamRegistrationRepository} from "../repository/OleloJamRegistrationRepository";
import {map} from "rxjs/operators";

@Component({
	selector: 'app-upcoming-event',
	templateUrl: './upcoming-event.component.html',
	styleUrls: ['./upcoming-event.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UpcomingEventComponent implements OnInit {

	@Input()
	public title:string;

	@Input()
	public thumbnail:string;
	@Input()
	public _editable:boolean;

	public countDownDate:Date;
	public registrationCount:number = 0;
	@Input()
	public date:string;
	@Input()
	public location:string = '';
	public eventInfo = {};
	public timeRemaining = {
		future: false,
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	};

	constructor(public notificationService:NotificationService, public oleloJamRegistrationRepository:OleloJamRegistrationRepository) {
		console.log("loading registrations...")
/*		this.oleloJamRegistrationRepository.list$()/!*.pipe(
			map(registrations => {
				console.log("total registrations: " +registrations.length);
				return registrations.filter(registration => {
					return registration.currentJam == "avatar-the-last-airbender/season-1/episode-3";
				})
			})
		)*!/.subscribe(registrations => {
			console.log("matching registrations: " + registrations.length);
			this.registrationCount = registrations.length;
			let output:string = "";
			for(let reg of registrations) {
				output += `${reg.firstName}\t${reg.lastName}\t${reg.email}\n`;
			}
			console.log(output);
		});*/
	}

	public get toFriendlyDate():string {
		if (this.countDownDate) {
			let months:Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			let monthFriendly:string = months[this.countDownDate.getMonth()];
			return `${monthFriendly} ${this.countDownDate.getDate()}, ${this.countDownDate.getFullYear()} at ${this.formatAMPM(this.countDownDate)}`;
		}
		return '';
	}

	ngOnInit() {
		this.countDownDate = new Date(this.date);

		this.calculateTimeRemaining();
		let interval = setInterval(() => {
			this.calculateTimeRemaining();
			if (this.timeRemaining.future == false) {
				clearInterval(interval);
			}
		}, 1000);
	}

	public calculateTimeRemaining() {
		// Get today's date and time
		let now:number = new Date().getTime();

		// Find the distance between now and the count down date
		let distance:number = this.countDownDate.getTime() - now;

		// Time calculations for days, hours, minutes and seconds
		this.timeRemaining.days = Math.floor(distance / (1000 * 60 * 60 * 24));
		this.timeRemaining.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		this.timeRemaining.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		this.timeRemaining.seconds = Math.floor((distance % (1000 * 60)) / 1000);

		this.timeRemaining.future = (distance > 0);

	}

	public formatAMPM(date:Date) {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		let strMinutes:string = minutes < 10 ? '0' + minutes : '' + minutes;
		let strTime = hours + ':' + strMinutes + ' ' + ampm;
		return strTime;
	}

	public register():void {
		window.open("https://olelo.link/avatar-series-register","_blank")
		//this.notificationService.displayModal(OleloJamRegistrationModalComponent, this);
	}


}
