import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CaptionDiaryEntryRangeSummary} from '../../app/domain/CaptionDiary';

@Component({
  selector: 'svg rect.bar',
  templateUrl: './grid-timeline-block.component.html',
  styleUrls: ['./grid-timeline-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTimelineBlockComponent implements OnInit {

	@Input()
	public fillColor:string = "#000000";

	@Input()
	public x:string;

	@Input()
	public y:string;

	@Input()
	public width:string;

	@Input()
	public height:string;
	public index:number;

	@Input()
	public entry:CaptionDiaryEntryRangeSummary;

	public colors = {
		normal: "#000000",
		fill: "#FF0000",
		flagged: "#AAAAFF",
		complete: "#00FF00",
		partial: "#FFFF00",
		over: "#F26137"
	};

  constructor() { }

  ngOnInit() {
  	this.fillColor = this.calculatedFillColor;
  }

  public onMouseOver():void {
  	this.fillColor = this.colors.over;
  }
  public get calculatedFillColor():string {
	 if(this.entry.flagged) {
	 	return this.colors.flagged;
	 }
	 if(this.entry.complete) {
	 	return this.colors.complete;
	 }
	 if(this.entry.partial) {
	 	return this.colors.partial;
	 }
	 return this.colors.normal;

  }


  public onMouseOut():void {
	  this.fillColor = this.calculatedFillColor;
  }


}
