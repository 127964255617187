import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {RemoteConfigurationRepository} from '../../repository/RemoteConfigurationRepository';


@Component({
  selector: 'app-watch-party-extension-required-modal',
  templateUrl: './watch-party-extension-required-modal.component.html',
  styleUrls: ['./watch-party-extension-required-modal.component.scss']
})
export class WatchPartyExtensionRequiredModalComponent implements OnInit {

	public component:any;
	public content:{watchPartyUrl:string};

	constructor(public bsModalRef:BsModalRef, public router:Router, private remoteConfigurationRepository:RemoteConfigurationRepository) {


	}

	ngOnInit() {
	}
	public onProperties(properties:{watchPartyUrl:string}):void {
		this.content = properties;
	}

	public dismiss() {
		this.bsModalRef.hide();
	}


	install() {
		window.open("https://chrome.google.com/webstore/detail/netflix-party/oocalimimngaihdkbihfgmpkcpnmlaoa?hl=en", "_blank");
	}

	reload() {
		window.location.reload();
	}

	launchParty() {
		window.location.href = this.content.watchPartyUrl;
	}
}
