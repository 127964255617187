import {AbstractFirestoreRepository} from "../AbstractFirestoreRepository";
import {AngularFirestore} from "@angular/fire/firestore";
import {Pledge} from "../../domain/patrons/Pledge";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
} as any)
export class PledgeRepository extends AbstractFirestoreRepository<Pledge> {

    constructor(protected db:AngularFirestore) {
        super(Pledge, db, db.collection('Pledge'));
    }

}