import {AbstractService} from "./AbstractService";
import {Episode, NetflixSeries, SeasonOrPart} from "../domain/database/NetflixSeries";
import {Injectable} from "@angular/core";
import {NetflixSeriesRepository} from "../repository/NetflixSeriesRepository";
import {Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NetflixSeriesService extends AbstractService<NetflixSeries>{
    constructor(protected repo:NetflixSeriesRepository) {
        super(repo);
    }
/*
    public populateOnePunchMan():void {

        let e1:Episode = new Episode({movieGuid: "80117348", label: "The Strongest Man", enabled: true});
            e1.creditPlain = "Beau Shishido";

        let e2:Episode = new Episode({movieGuid: "80117349", label: "The Lone Cyborg", enabled: true});
            e2.creditPlain = "Kyle Kolomona Nakatsuka & Lāhui";
        let e3:Episode = new Episode({movieGuid: "80117350", label: "The Obsessive Scientist", enabled: false});
        let e4:Episode = new Episode({movieGuid: "80117351", label: "The Modern Ninja", enabled: false});
        let e5:Episode = new Episode({movieGuid: "80117352", label: "The Ultimate Mentor", enabled: false});
        let e6:Episode = new Episode({movieGuid: "80117353", label: "The Terrifying City", enabled: false});
        let e7:Episode = new Episode({movieGuid: "80117354", label: "The Ultimate Disciple", enabled: false});
        let e8:Episode = new Episode({movieGuid: "80117355", label: "The Deep Sea King", enabled: false});
        let e9:Episode = new Episode({movieGuid: "80117356", label: "Unyielding Justice", enabled: false});
        let e10:Episode = new Episode({movieGuid: "80117357", label: "Unparalleled Peril", enabled: false});
        let e11:Episode = new Episode({movieGuid: "80117358", label: "The Dominator of the Universe", enabled: false});
        let e12:Episode = new Episode({movieGuid: "80117359", label: "The Strongest Hero", enabled: false});



        let season1:SeasonOrPart = new SeasonOrPart();
            season1.label = "Season 1";
            season1.episodes.push(e1,e2,e3,e4,e5,e6,e7,e8,e9,e10,e11,e12);


        let onePunchMan:NetflixSeries = new NetflixSeries();
            onePunchMan.guid = "one-punch-man";
            onePunchMan.title = "One Punch Man";
            onePunchMan.seasons.push(season1);
            onePunchMan["@episodes"] = onePunchMan.toEpisodes().map(episode => {
                return episode.movieGuid;
            });

            this.repo.save$(onePunchMan);
    }

    public setSeriesEpisodeEnabledStatus(seriesGuid:string, enabled:boolean): void {
        let s:Subscription = this.get$(seriesGuid).subscribe(series => {
            s.unsubscribe();
            for (let season of series.seasons) {
                season.episodes = season.episodes.map(episode => {
                    episode.enabled = enabled;
                    return episode;
                });
            }
            this.save(series)
        });
    }

    public setSeasonEnabledStatus(seriesGuid:string, seasonGuid:string, enabled:boolean): void {
        let s:Subscription = this.get$(seriesGuid).subscribe(series => {
            s.unsubscribe();
            for (let season of series.seasons) {
                if(seasonGuid == season.seasonId) {
                    season.episodes = season.episodes.map(episode => {
                        episode.enabled = enabled;
                        return episode;
                    });
                }
            }
            this.save(series)
        });
    }


    public save(series:NetflixSeries):void {
        this.repo.save$(series);
    }*/
}
