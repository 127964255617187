import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {
	faFacebook,
	faInstagram,
	IconDefinition
} from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],

})
export class FooterComponent implements OnInit {


  public sanitized_background_image:SafeStyle;

  @Input()footer:IFooter;

  public icons = {
	  faFacebook:faFacebook,
	  faInstagram:faInstagram,
  }

  constructor(private domSanitizer:DomSanitizer) {
  	this.footer = {
	    background_image:  "/assets/images/footer/hop-site-footer-1200.jpg",
		firstColumnTitle:  "About Mana Studios",
		firstColumnShortText:  "Mana Studios, an upcoming 501(c)(3) non-profit (registration pending) who is committed to empowering lāhui to reclaim and renormalize ʻŌlelo Hawaiʻi and other indigenous languages in digital spaces.",
		logo:  "/assets/images/footer/mana-studios-logo-footer.png",
		secondColumnTitle:  "",
		links:  [],
		thirdColumnTitle:  ""
    };

  }

  ngOnInit() {
    if(this.footer && this.footer.background_image) {
      this.sanitized_background_image = this.domSanitizer.bypassSecurityTrustStyle(`url(${this.footer.background_image})`);
    }
  }


}

export interface IFooter {
  background_image:string;

  firstColumnTitle:string;
  firstColumnShortText:string;
  logo:string;

  secondColumnTitle:string;
  links:Array<{label:string, link:string}>;

  thirdColumnTitle:string
}
