import {Component, Input, OnInit} from '@angular/core';
import {Patron} from "../../../app/domain/patrons/Patron";

@Component({
    selector: '.app-patron-in-list',
    templateUrl: './patron-in-list.component.html',
    styleUrls: ['./patron-in-list.component.scss']
})
export class PatronInListComponent implements OnInit {

    @Input()
    public patron: Patron

    constructor() {
    }

    ngOnInit() {
    }



}
