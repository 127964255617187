import {Exclude, Type} from 'class-transformer';

/** A Series on Netflix */
export class NetflixSeries {
	public guid:string = null;

	public hasCustomPoster:boolean = false;
	public visible:boolean = true;

	@Type(()=>SeasonOrPart)
	public seasons:Array<SeasonOrPart> = [];
	public title:string = "";

	@Exclude()
	public image:string;

	public ['@episodes'] = [];

	public toEpisodes():Array<Episode> {
		let allMovies:Array<Episode> = [];
		this.seasons.map(season => {
			let episodes: Array<Episode> = season.episodes.map(movie => {
				return movie;
			});
			allMovies.push(...episodes);
			return episodes;
		});
		return allMovies;
	}
	public findSeasonForEpisode(episode:Episode):SeasonOrPart {
		return this.seasons.find(season => {
			return season.episodes.find(episodeInSeason => {
				return episode.movieGuid == episodeInSeason.movieGuid;
			}) != null;
		});
	}


}

export class SeasonOrPart {
	public seasonId:string = "";
	public label:string = "";
	@Type(()=>Episode)
	public episodes:Array<Episode> = [];
}

export class Episode {
	public label:string = "";
	public movieGuid:string = null;
	public creditPlain:string = "";
	public enabled:boolean = false;
	public description:string = "";
	public episodeNumber:number = 1;
	public minutesLength:string = "";

	constructor(initializer?:{movieGuid?:string, label?:string, enabled?:boolean, description?:string, creditPlain?:string, episodeNumber?:number, minutesLength?:string}) {
		if(initializer) {
			this.label = initializer.label;
			this.movieGuid = initializer.movieGuid;
			this.enabled = initializer.enabled;
			this.description = initializer.description;
			this.creditPlain = initializer.creditPlain;
			this.episodeNumber = initializer.episodeNumber;
			this.minutesLength = initializer.minutesLength;

		}
	}
}