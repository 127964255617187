import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../app/domain/User';
import {UserRepository} from '../../app/repository/UserRepository';
import {NetflixMovieService} from '../../app/services/NetflixMovieService';
import {NetflixMovie} from '../../app/domain/database/NetflixMovie';
import {SubscriptionCleaner} from '../../app/util/SubscriptionCleaner';
import {ImdbSearchResult, OmdbMovieService} from '../../app/services/OmdbMovieService';
import {Observable, Subscription} from 'rxjs';
import "rxjs/add/observable/zip";
import {NotificationService} from '../../app/services/NotificationService';
import {AddSlipperModalComponent} from '../modals/add-slipper-modal/add-slipper-modal.component';
import {UrlService} from '../../app/services/UrlService';
import {SortType} from '../../app/filters/SortPipe';
import {CaptionDiary} from '../../app/domain/CaptionDiary';
import {CaptionDiaryService} from '../../app/services/CaptionDiaryService';
import {NetflixSeriesService} from "../../app/services/NetflixSeriesService";
import {NetflixSeries} from "../../app/domain/database/NetflixSeries";
import {
	faPlay,
	faClosedCaptioning,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {OleloflixIntroModalComponent} from "../../app/modals/oleloflix-intro-modal-component/oleloflix-intro-modal.component";
import {MovieLink} from "../../app/domain/MovieLink";
import {MovieLinkRepository} from "../../app/repository/MovieLinkRepository";

@Component({
	selector: 'app-start-captioning',
	templateUrl: './start-captioning.component.html',
	styleUrls: ['./start-captioning.component.scss']
})
export class StartCaptioningComponent extends SubscriptionCleaner implements OnInit {

	public desiredIndex:{ [key:string]:string } = {};
	public series:Array<NetflixSeries> = [];
	public movies:Array<NetflixMovie|NetflixSeries> = [];

	public diaries:{ [key:string]:CaptionDiary } = {};
	public icons = {
		faPlay: faPlay,
		faClosedCaptioning: faClosedCaptioning
	};
	public searchResults:Array<object>;
	public filter:string = '';

	public sortType:SortType = SortType.Alphabetical;

	public moviesOrSeries:Array<NetflixMovie|NetflixSeries> = []
	public isLoading:boolean = true;

	public user:User;

	public makaiwakeiki:MovieLink;

	public kapaemahu:MovieLink;
	public manaleo:MovieLink;

	public dataProvider = {
		movieLinks: {
			ready: false,
			data: [],
		},
	};

	constructor(public notificationService:NotificationService, public netflixSeriesService:NetflixSeriesService, protected diaryService:CaptionDiaryService, protected urlService:UrlService, protected omdbMovieService:OmdbMovieService, protected router:Router, protected userRepository:UserRepository, private netflixMovieService:NetflixMovieService, protected movieLinkRepository:MovieLinkRepository) {
		super();
		let user:User = this.userRepository.get();
		this.user = this.userRepository.get();

		this.trackSubscription(this.movieLinkRepository.list$().subscribe(movieLinks => {
			this.dataProvider.movieLinks = {
				data: movieLinks.filter(movieLink => movieLink.enabled),
				ready: true
			};
		}));

		let series$:Observable<NetflixSeries[]> = this.netflixSeriesService.watchList$();
		let movies$:Observable<NetflixMovie[]> = this.netflixMovieService.movie.watchList();
		let moviesAndSeries$:Observable<[NetflixSeries[],NetflixMovie[]]> = Observable.zip(series$,movies$);

		moviesAndSeries$.subscribe(value => {
			this.isLoading = false;
			let series:NetflixSeries[] = value[0];
			let movies:NetflixMovie[] = value[1];

			console.log(`Loaded ${series.length} Series.`);
			console.log(`Loaded ${movies.length} Movies.`);

			let moviesOrSeries:Array<NetflixMovie|NetflixSeries> = [];
			movies = movies.filter(netflixMovie => {
				return netflixMovie.enabled == true || netflixMovie.enabled == null;
			});

			for(let movie of movies) {
				let matchingSeries:NetflixSeries = series.find(iSeries => {
					//return iSeries["@episodes"].indexOf(movie.titleId) != -1;
					return iSeries.toEpisodes().find(episode => {
						return episode.movieGuid == movie.titleId;
					}) != null;
				});

				if(matchingSeries) {
					if(moviesOrSeries.indexOf(matchingSeries) == -1) {
						console.log("found matching series for " + movie.titleId);
						moviesOrSeries.push(matchingSeries);
						if(matchingSeries.hasCustomPoster) {
							matchingSeries.image = this.urlService.series.poster.url(matchingSeries.guid);
						} else {
							let s:Subscription = this.omdbMovieService.getMovies(movie.title).subscribe(searchResults => {
								let imdbSearchResult: ImdbSearchResult = searchResults.Search[0];
								matchingSeries.image = imdbSearchResult.Poster;
								s.unsubscribe();
								//this.movies = movies;
							});
						}

					} else {
						console.log("found matching series earlier for " + movie.titleId + " so not adding anything.");
					}
				} else {
					moviesOrSeries.push(movie);
					console.log("did not find matching series for " + movie.titleId);
					if (movie.hasCustomPoster) {
						movie.image = this.urlService.poster.url(movie.provider, movie.titleId);
					} else {
						let s:Subscription = this.omdbMovieService.getMovies(movie.title).subscribe(searchResults => {
							let imdbSearchResult:ImdbSearchResult = searchResults.Search[0];
							movie.image = imdbSearchResult.Poster;
							s.unsubscribe();
							//this.movies = movies;
						});
					}
				}
				this.diaryService.currentMovie = movie.titleId;
				this.diaryService.watchDiary$().subscribe((diary:CaptionDiary) => {
					this.diaries[movie.titleId] = diary;
				});
			}

			this.movies = movies;
			this.moviesOrSeries = moviesOrSeries;
			console.log(`moviesOrSeries ${moviesOrSeries.length}`);

			let seriesCount:number = 0;
			let movieCount:number = 0;
			for(let m:number = 0; m < moviesOrSeries.length; m++) {
				let ms = moviesOrSeries[m];
				if(ms instanceof NetflixSeries) {
					seriesCount++
				} else if(ms instanceof NetflixMovie) {
					movieCount++;
				}
			}
			console.log(`series: ${seriesCount}, movies: ${movieCount}`);

		});

		if (user && user.movieId) {
			this.desiredIndex[user.movieId] = user.caption.toString();
		}
	}


	public isMovie(value:NetflixMovie|NetflixSeries):boolean {
		return (value instanceof NetflixMovie);
	}

	public isSeries(value:NetflixMovie|NetflixSeries):boolean {
		return (value instanceof NetflixSeries);
	}

	public get SortType() {
		return SortType;
	}

	public get AddSlipperModalComponent() {
		return AddSlipperModalComponent;
	}
	public asNetflixSeries(item:any) {
		return item as NetflixSeries;
	}

	ngOnInit() {

	}

	public getDiary(titleId:string) {
		return this.diaries[titleId];
	}
	public showIntroModal():void {
		this.notificationService.displayModal(OleloflixIntroModalComponent, this)
	}

	kumuContact() {
		window.open("https://docs.google.com/forms/d/e/1FAIpQLSeHF5BwD1luuhtPt7YZw0IujcUW7rBLCKuLvT4SFVrfBDFAjQ/viewform", "_blank");
	}
	statementSupport() {
		window.open("https://docs.google.com/forms/d/e/1FAIpQLSdKtwryBTlvlmbyElkGVLkpukhge7rZQwdyoZfj1xIOBBMZyw/viewform", "_blank");
	}
}
