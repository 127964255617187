import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../app/services/AuthService';
import {IServerError, ServerError} from '../../../app/services/functions/standard/AbstractFirebaseFunction';
import {NewUserRegistration} from '../../../app/domain/registration/NewUserRegistration';
import {CompleteUserRegistration} from '../../../app/domain/registration/CompleteUserRegistration';
import {ValidationException} from '../../../app/services/exceptions/ValidationException';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LoginAttempt} from '../../../app/areas/auth/login/LoginAttempt';
import {FormGroup} from '@angular/forms';
import {ValidationService} from '../../../app/services/ValidationService';
import {ErrorMessage} from 'ng-bootstrap-form-validation';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: [
		'../auth.scss',
		'./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

	public mode:PasswordMode = PasswordMode.password;
	public loaded:boolean = false;

	public loginAttempt:LoginAttempt = new LoginAttempt();

	public faClass:string = '';

	@Output()
	public forgotPassword:EventEmitter<any> = new EventEmitter<any>();

	@Output()
	public register:EventEmitter<any> = new EventEmitter<any>();

	public formGroup: FormGroup;

	public error:string = "";

	constructor(private authService:AuthService, private validationService:ValidationService) {

		this.loginAttempt.username = "";
		this.loginAttempt.password = "Password1?";

		this.formGroup = new FormGroup(this.validationService.createForm(LoginAttempt, this.loginAttempt));
		this.formGroup.markAllAsTouched();
	}

	public errorsFor(property:string) {
		return this.validationService.getErrorMessages(this.loginAttempt, property, this.formGroup);
	}

	public toggleMode():void {
		this.mode = this.mode == PasswordMode.password ? PasswordMode.text : PasswordMode.password;
	}


	public onImagesLoaded():void {


	}


	ngAfterViewInit():void {


	}

	ngOnInit() {

		this.loaded = true;

		/*
		  $("[data-bg-image]").each(function() {
			  var img = $(this).data("bg-image");
			  $(this).css({
				  backgroundImage: "url(" + img + ")"
			  });
		  });*/


		/*-------------------------------------
		Toggle Class
		-------------------------------------*/
		/*
			  $(".toggle-password").on('click', function() {
				  $(this).toggleClass("fa-eye fa-eye-slash");
				  var input = $($(this).attr("toggle"));
				  if (input.attr("type") == "password") {
					  input.attr("type", "text");
				  } else {
					  input.attr("type", "password");
				  }
			  });*/
	}

	public onLoginClick():void {
		console.log(`Logging in..`);
		try {
			this.authService.login$(this.loginAttempt).subscribe( response => {

			});
		} catch(ex) {
			if(ex instanceof ValidationException) {
				this.error = ex.toString();
			} else {
				throw(ex);
			}
		}
	}
}

export enum PasswordMode {
	text = 'text',
	password = 'password',

}
