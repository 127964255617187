import {GroupedCaptionSet} from "../GroupedCaptionSet";
import {Exclude, Type} from "class-transformer";
import {SkillLevel} from "./FlashcardCollection";

export class Flashcard extends GroupedCaptionSet {

    public guid:string = null;
    public skill:SkillLevel = 1;

    public _selectedSet1Index:number = -1;
    public selectedSet2Index:number = -1;

    public get selectedSet1():GroupedCaptionSet {

        return this.set1[this._selectedSet1Index] as unknown as GroupedCaptionSet;
    }
    public get selectedSet2():GroupedCaptionSet {
        return this.set2[this.selectedSet2Index] as unknown as GroupedCaptionSet;
    }

}
