import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {TimedNetflixCaption} from '../domain/database/TimedNetflixCaption';
import {AngularFirestore} from "@angular/fire/firestore";
import {NetflixMovieLocalization} from '../domain/database/NetflixMovieLocalization';
import {Observable} from 'rxjs';
import {AbstractCompoundFirestoreRepository} from "./AbstractCompoundFirestoreRepository";

@Injectable({
  providedIn: 'root',
} as any)
export class NetflixMovieLocalizationRepository extends AbstractCompoundFirestoreRepository<NetflixMovieLocalization> {

  private _currentMovie:string = "";

  public static get PATH():string {
    return "Netflix/%1$s/Captions"
  };


  constructor(protected db:AngularFirestore) {
    super(NetflixMovieLocalization, db, NetflixMovieLocalizationRepository.PATH, "guid");
  }
  public get currentMovie():string {
    return this._currentMovie;
  }
  public set currentMovie(value:string) {
    this._currentMovie = value;
  }

  public englishToHawaiian$():Observable<NetflixMovieLocalization> {
    return this.get$("en-haw", false, this.currentMovie.toString());
  }
}
