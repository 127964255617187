import {IRepository} from "../repository/IRepository";
import {Observable} from "rxjs";
import {validateSync, ValidationError} from "class-validator";
import {ValidationException} from "./exceptions/ValidationException";
import {AbstractFirestoreRepository} from "../repository/AbstractFirestoreRepository";
import {QueryFn} from "@angular/fire/firestore";

export abstract class AbstractService<T>  {


    constructor(protected repo:AbstractFirestoreRepository<T>) {

    }



    public updatePartial$(value:object):Observable<T> {
        return this.repo.updatePartial$(value);

    }

    public save$(value:T):Observable<T> {

        let validationErrors:Array<ValidationError> = validateSync(value as any);
        if(validationErrors.length > 0) {
            console.error(validationErrors);
            throw new ValidationException(validationErrors);
        }
        return this.repo.create$(value);
    }

    public saveAll$(...values:Array<T>):Observable<T[]> {
        for (let value of values) {
            let validationErrors:Array<ValidationError> = validateSync(value as any);
            if(validationErrors.length > 0) {
                console.error(validationErrors);
                throw new ValidationException(validationErrors);
            }
        }
        return this.repo.saveAll$(values);
    }

    public get$(guid:string):Observable<T> {
        return this.repo.get$(guid);
    }

    public list$():Observable<T[]> {
        return this.repo.list$();
    }

    public watch$(guid:string):Observable<T> {
        return this.repo.watch$(guid);
    }

    public watchList$():Observable<T[]> {
        return this.repo.watchList$();
    }

    public query$(queryFn?:QueryFn):Observable<T[]> {
        return this.repo.query$(queryFn);
    }

    public watchQuery$(queryFn:QueryFn):Observable<T[]> {
        return this.repo.watchQuery$(queryFn);
    }
}
