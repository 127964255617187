import {Injectable} from '@angular/core';
import {Auth0Adapter} from './adapters/auth0/Auth0Adapter';
import {Observable, Subscription} from 'rxjs';
import {LoginAttempt} from '../areas/auth/login/LoginAttempt';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DeviceInformation, DeviceType} from '../areas/auth/login/DeviceInformation';


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public constructor(protected auth0Adapter:Auth0Adapter, protected deviceDetectorService:DeviceDetectorService) {
		
	}

	public handleAuthentication():void {
		this.auth0Adapter.handleAuthentication();
	}

	public isAuthenticated():boolean {
		return this.auth0Adapter.isAuthenticated();
	}

	public renewTokens() {
		this.auth0Adapter.renewTokens();
	}


	public login$(loginAttempt:LoginAttempt):Observable<boolean> {
		loginAttempt.deviceInformation = this.generateDeviceDetails();

		let login$:Observable<boolean> = this.auth0Adapter.login$(loginAttempt.username, loginAttempt.password);
		let loginAttemptSubscription$ = login$.subscribe(loginSuccess => {
			loginAttemptSubscription$.unsubscribe();
			//todo write to repository about it..
		});
		return login$;
	}

	protected generateDeviceDetails():DeviceInformation {
		let dds = this.deviceDetectorService;
		let deviceInformation:DeviceInformation = new DeviceInformation();
			deviceInformation.type =
					dds.isDesktop() ? DeviceType.desktop
					: dds.isTablet() ? DeviceType.tablet
					: dds.isMobile() ? DeviceType.mobile
					: null;

		deviceInformation.deviceInfo = dds.getDeviceInfo();
		return deviceInformation;
	}

	public logout():void {
		this.auth0Adapter.logout();
	}
/*	public authorizeWithToken(token:Token):Observable<void> {

	}*/
	// Only the callback component should call this method
	// Call when app reloads after user logs in with Auth0
	public handleAuthCallback() {
		this.auth0Adapter.defaultCallback("callback got called");
	}


	public onAuthenticationComplete():Observable<boolean> {
		return this.auth0Adapter.ssoAuthComplete$;

	}

}
