import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlashcardCollectionRepository} from "../../../repository/FlashcardCollectionRepository";
import {FlashcardCollection} from "../../../domain/flashcard/FlashcardCollection";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-list-flashcard-collections',
    templateUrl: './list-flashcard-collections.component.html',
    styleUrls: ['./list-flashcard-collections.component.scss']
})
export class ListFlashcardCollectionsComponent extends SubscriptionCleaner implements OnInit {



    @Output()
    public select:EventEmitter<FlashcardCollection> = new EventEmitter<FlashcardCollection>();

    @Input()
    public filter:string = "";

    @Input()
    public selected:FlashcardCollection = null;

    @Output()
    public addClick:EventEmitter<void> = new EventEmitter<void>();

    public dataProvider: {
        owner: string,
        list: Array<FlashcardCollection>
    } = {
        owner: null,
        list: null,
    };

    constructor(private flashcardCollectionRepository:FlashcardCollectionRepository) {
        super();

    }

    ngOnInit(): void {
        this.dataProvider.owner = this.filter;
        let s:Subscription = this.trackSubscription(this.flashcardCollectionRepository.list$().subscribe((items) => {
            s.unsubscribe();
            this.dataProvider.list = items.filter(item => {
                return this.flashcardCollectionRepository.by.client.filters.accessible(item,this.dataProvider.owner)
            })
        }));
    }
}
