import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {GroupedCaptionSet} from '../../app/domain/GroupedCaptionSet';
import {ProvisioningService} from '../../app/services/ProvisioningService';
import {TimedNetflixCaptionRepository} from '../../app/repository/TimedNetflixCaptionRepository';
import {TimedNetflixCaption} from '../../app/domain/database/TimedNetflixCaption';
import {EditCaptionSetComponent} from '../../app/edit-caption-set/edit-caption-set.component';
import {SelectableCaption} from '../../app/domain/SelectableCaption';
import * as createjs from 'createjs-module';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

import {
	faArrowLeft,
	faCheck,
	faCircle,
	faClosedCaptioning,
	faCloudUploadAlt,
	faComment,
	faEdit,
	faEraser,
	faEye,
	faEyeDropper,
	faFillDrip,
	faFlag,
	faGlobe,
	faHeart,
	faMicrophone,
	faMobileAlt,
	faPiggyBank,
	faPlay,
	faPlus,
	faQuestion,
	faSave,
	faStar,
	faStepBackward,
	faStepForward,
	faUndo,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '../../app/services/TranslateService';
import {Observable, Subscription} from 'rxjs';
import {MODE, Mode} from '../../app/domain/Mode';
import {RealtimePresenceService} from '../../app/services/RealtimePresenceService';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {User} from '../../app/domain/User';
import {NetflixMovieService} from '../../app/services/NetflixMovieService';
import {UserRepository} from '../../app/repository/UserRepository';
import {SubscriptionCleaner} from '../../app/util/SubscriptionCleaner';
import {UserRef} from '../../app/domain/UserRef';
import {CaptionDiary, CaptionDiaryEntry} from '../../app/domain/CaptionDiary';
import {CaptionDiaryService} from '../../app/services/CaptionDiaryService';
import {CaptionStatsReviewItem} from '../../app/domain/database/CaptionStats';
import {NetflixMovie} from '../../app/domain/database/NetflixMovie';
import {MovieWordBankRepository} from "../../app/repository/MovieWordBankRepository";
import {classToClass} from "class-transformer";
import {WordBankGroupedCaptionSet} from "../../app/domain/bank/WordBankGroupedCaptionSet";
import {NotificationService} from "../../app/services/NotificationService";
import {
	IWordBankEditModalComponentInputProperties,
	WordBankEditModalComponent,
	WordBankEditModalComponentMode
} from "../../app/modals/word-bank-edit-modal/word-bank-edit-modal.component";
import {NetflixSeriesService} from "../../app/services/NetflixSeriesService";
import {Episode, NetflixSeries, SeasonOrPart} from "../../app/domain/database/NetflixSeries";
import {UrlService} from "../../app/services/UrlService";
import {CaptionSocialComponent} from "../caption-social/caption-social.component";
import {HelpAssistComponent} from "../help-assist/help-assist.component";
import {
	AddDisneyPlusMovieModalComponent,
	IAddDisneyPlusMovieModalInputProperties
} from "../../app/areas/movie/add-disney-plus-movie-modal/add-disney-plus-movie-modal.component";
import {DisneyPlusRequestData} from "../../app/services/functions/messages/disneyplus/DisneyPlusRequestData";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Provider} from "../../app/domain/providers/Provider";

export class WordCursor {
	type:string;
	constructor(type:string) {
		this.type = type;
	}
	toString():string {
		return this.type;
	}
}


export interface SetBackup {
	set1:{ content:string, backup:Array<SelectableCaption | string> };
	set2:{ content:string, backup:Array<SelectableCaption | string> };
}

export enum PaintMode {
	'color' = 'color',
	'pick' = 'pick'
}

declare var window;

export enum ToolMode {
	Translate='Translate',
	Flag='Flag',
	Color='Color',
	Bank='Bank',
	Question='Question',
	AutomatedTranslate='GoogleTranslate',
	Record='Record',
	Discuss='Discuss',
	Mobile='Mobile',
	Preview='Preview',
	Publish='Publish',
	Review='Review',
}

@Component({
	selector: 'app-captioning',
	templateUrl: './captioning.component.html',
	styleUrls: ['./captioning.component.scss'],
})
export class CaptioningComponent extends SubscriptionCleaner implements OnInit, AfterViewInit {
	public title = 'Captioner';
	public captionDiary:CaptionDiary;
	public desiredIndex:number = 0;
	public mode:Mode

	public wordCursor:WordCursor = new WordCursor("pointer");

	public bankWords:Array<WordBankGroupedCaptionSet> = [];
	@ViewChild("oleloEntryControl")
	public oleloEntryControl:ElementRef;
	public tempDisneyModalRef:BsModalRef;
	public icons = {
		faCloudUploadAlt: faCloudUploadAlt,
		faArrowLeft: faArrowLeft,
		faEye: faEye,
		faPiggyBank: faPiggyBank,
		faPlay: faPlay,
		faStepForward: faStepForward,
		faStepBackward: faStepBackward,
		faCheck: faCheck,
		faPlus: faPlus,
		faHeart: faHeart,
	};
	public shiftOn:boolean = false;
	public paintMode:PaintMode;
	public faComment:IconDefinition = faComment;
	public faEyeDropper:IconDefinition = faEyeDropper;
	public faStar:IconDefinition = faStar;
	public faEraser:IconDefinition = faEraser;
	public faEdit:IconDefinition = faEdit;
	public faCircle:IconDefinition = faCircle;
	public faQuestion:IconDefinition = faQuestion;
	public faClosedCaptioning:IconDefinition = faClosedCaptioning;
	public faMicrophone:IconDefinition = faMicrophone;
	public faMobileAlt:IconDefinition = faMobileAlt;
	public flaggedCaptions:Array<TimedNetflixCaption> = [];


	public faUndo:IconDefinition = faUndo;
	public faGlobe:IconDefinition = faGlobe;
	public faSave:IconDefinition = faSave;
	public faFlag:IconDefinition = faFlag;
	public faFillDrip:IconDefinition = faFillDrip;
	public faPiggyBank:IconDefinition = faPiggyBank;
	private _showColors:boolean = false;
	public nameEditMode:boolean = false;

	public input = {
		desiredNickname: ""
	}


	public _toolMode:ToolMode = ToolMode.Translate;

	/*
	  public keyboardAnimation:KeyboardAnimation = new KeyboardAnimation();
	*/
	public groupedCaptionSet:GroupedCaptionSet;
	public backup:SetBackup = {
		set1: {
			content: '',
			backup: []
		},
		set2: {
			content: '',
			backup: []
		}
	};
	private _isHelpModeOn:boolean = false;
	@ViewChild('textInput1') public textInput1:ElementRef;
	@ViewChild('textInput2') public textInput2:ElementRef;
	public isTextInputFocused:boolean = false;

	@ViewChild('captionSocialComponent')
	public captionSocialComponent:CaptionSocialComponent;

	@ViewChild('helpAssistComponent')
	public helpAssistComponent:HelpAssistComponent;

	public color:string = '#3e6158';
	public users:Array<User> = [];
	public usersOnSlide:Array<User> = [];
	public routerUrl:string;
	public currentMovie:{
		movieId?:string
		captionId?:number
	} = {
		movieId: null,
		captionId: null
	};
	protected currentCaption:TimedNetflixCaption;
	public captions:Array<string> = [];
	public _captionIndex:number = 0;
	@ViewChild('editCaptionSetComponent')
	protected editCaptionSetComponent:EditCaptionSetComponent;
	private totalCaptions:number = 40;
	private alreadyInitialized:boolean = false;

	public netflixMovie:NetflixMovie;

	public currentCaptionSubscription:Subscription;
	private flagSubscription:Subscription;
	public hasLoadedDiary:boolean = false;
	public seriesGuid:string = null;
	public series:NetflixSeries = null;
	public season:SeasonOrPart = null;
	public episode:Episode = null;

	public followingUserGuid:string = null;
	public posterUrl:string = "";

	constructor(private netflixMovieService:NetflixMovieService,
				private netflixSeriesService:NetflixSeriesService,
				public translateService:TranslateService,
	            private provisioningService:ProvisioningService,
	            private timedNetflixCaptionRepository:TimedNetflixCaptionRepository,
	            protected notificationService:NotificationService,
	            public realtimePresenceService:RealtimePresenceService,
	            private router:Router,
	            private userRepository:UserRepository,
	            private route:ActivatedRoute,
	            private captionDiaryService:CaptionDiaryService,
	            private movieWordBankRepository:MovieWordBankRepository,
				protected urlService:UrlService,
				private changeDetectorRef:ChangeDetectorRef) {
		super();
		//'movies/:movieId?/:captionId?',ʻ

		this.seriesGuid = this.route.snapshot.params['seriesGuid'] != '' ? this.route.snapshot.params['seriesGuid'] : null;
		if(this.seriesGuid) {
			this.trackSubscription(this.netflixSeriesService.watch$(this.seriesGuid).subscribe(series => {
				this.series = series;
				this.episode = this.series.toEpisodes().find(episodeInSeries => {
					return episodeInSeries.movieGuid == ("" + this.currentMovie.movieId);
				});
				this.season = this.series.findSeasonForEpisode(this.episode);

			}));
		}


		let movieId:string = this.route.snapshot.params['movieId'] != '' ? this.route.snapshot.params['movieId'] : null;
		let captionId:string = this.route.snapshot.params['captionId'] != '' ? this.route.snapshot.params['captionId'] : null;
		console.log(`movieId: ${movieId}, captionId: ${captionId} ${JSON.stringify(this.route.snapshot.params, null, 2)}`);

		if (movieId) {
			this.currentMovie.movieId = movieId;
		}
		if (captionId) {
			this.currentMovie.captionId = parseInt(captionId);
		}

		this.netflixMovieService.setNetflixMovie(this.currentMovie.movieId);
		this.setCurrentMovie(movieId);

		if(movieId) {
			let b:Subscription = this.trackSubscription(this.netflixMovieService.movie.get(movieId).subscribe(movie => {
				b.unsubscribe();
				this.netflixMovie = movie;
			}));
		}

		this.trackSubscription(this.netflixMovieService.getNetflixMovieData().subscribe(netflixMovie => {
			this.captions = netflixMovie.captions$;
			this.totalCaptions = this.captions.length;

		}));

		this.routerUrl = `${environment.environment.baseUrl}/#${router.url}`;
		console.log(`router.url: ${router.url}`);

		this.trackSubscription(this.router.events.subscribe((event) => {
			console.log('router event ' + event);

			if (event instanceof NavigationEnd) {
				// Hide loading indicator
				this.routerUrl = `${environment.environment.baseUrl}/#${router.url}`;

				movieId = this.route.snapshot.params['movieId'] != '' ? this.route.snapshot.params['movieId'] : null;
				captionId = this.route.snapshot.params['captionId'] != '' ? this.route.snapshot.params['captionId'] : null;
				try {
					let captionInt:number = parseInt(captionId);
					if(!isNaN && captionInt >= 0) {
						this._captionIndex = captionInt;
					}
				} catch(err) {
					console.log("not a number")
				}
				if (movieId) {
					if (movieId != this.currentMovie.movieId) {
						this.netflixMovieService.setNetflixMovie(movieId);
						this.timedNetflixCaptionRepository.currentMovie = movieId;
						this.netflixMovieService.getNetflixMovieData().subscribe(netflixMovie => {
							this.captions = netflixMovie.captions$;
							this.totalCaptions = this.captions.length;
						});
					}
					this.currentMovie.movieId = movieId;

				}
				if (captionId) {
					this.currentMovie.captionId = parseInt(captionId);
				}

				this.netflixMovieService.setNetflixMovie(this.currentMovie.movieId);

				if (captionId != null) {
					this.gotoCaptionAt(parseInt(captionId));
				}

				/*this.routerUrl = router.url;*/
			}
		}));

		if (captionId) {
			this.captionIndex = parseInt(captionId);
		}

		this.mode = new Mode();
		this.mode.onModeChange.on(modeChange => {
			//Backup Set 1
			if (modeChange.modeType == 1 && modeChange.from == MODE.COLOR && modeChange.to == MODE.EDIT) {
				this.backup.set1.content = this.groupedCaptionSet.set1Content;
				this.backup.set1.backup = this.groupedCaptionSet.set1;
			}
			//Backup Set 2
			if (modeChange.modeType == 2 && modeChange.from == MODE.COLOR && modeChange.to == MODE.EDIT) {
				this.backup.set2.content = this.groupedCaptionSet.set2Content;
				this.backup.set2.backup = this.groupedCaptionSet.set2;
			}

		});

		this.trackSubscription(this.realtimePresenceService.getUsers().pipe(
				debounceTime(250), //wait 500 ms for other things to happen
				distinctUntilChanged() //ignore until it changes
			).subscribe(users => {
			this.users = users;
			this.usersOnSlide = this.usersOnSameSlide();
			if(this.followingUserGuid != null) {
				let followingUser:User = this.users.find(user => {
					return user.guid == this.followingUserGuid;
				});
				if(followingUser && followingUser.caption != this.captionIndex) {
					this.gotoCaptionAt(followingUser.caption);
				}

			}
		}));
	}

	public get isHelpModeOn(): boolean {
		return this._isHelpModeOn;
	}

	public set isHelpModeOn(value: boolean) {
		this._isHelpModeOn = value;

	}
	public get captionIndex():number {
		return this._captionIndex;
	}
	public set captionIndex(value:number) {
		this._captionIndex = Number(value);
	}

	public get movieLabel():string {
		if(this.series == null) {
			return this.netflixMovie.title
		} else {
			let episode:Episode = this.series.toEpisodes().find(episodeInSeries => {
				return episodeInSeries.movieGuid == ("" + this.currentMovie.movieId);
			});

			if(episode) {
				let season:SeasonOrPart = this.series.findSeasonForEpisode(episode);

				return `${season.label}: ${episode.label}`;
			}
		}
		return "";
	}

	public get movieThumbnail():string {
		if(this.episode) {
			this.posterUrl = this.episodeThumbnail;
			return this.episodeThumbnail;
		}
		if(this.netflixMovie == null) {
			this.posterUrl = this.urlService.poster.url(Provider.Netflix, this.currentMovie.movieId);
		} else {
			this.posterUrl = this.urlService.poster.url(this.netflixMovie.provider, this.currentMovie.movieId);
		}
		return this.posterUrl;
	}
	public get episodeThumbnail():string {
		return this.urlService.series.episode.thumbnail.url(this.series.guid, this.episode.movieGuid, this.season.seasonId);
	}
	public get user():User {
		return this.realtimePresenceService.user;
	}


	get showColors():boolean {
		return this._showColors;
	}

	set showColors(value:boolean) {
		this._showColors = value;
		/*

			this.leftArrowPress.visible = !this._showColors;
			this.rightArrowPress.visible = !this._showColors;
		*/
	}

	public get MODE() {
		return MODE;
	}

	/*  public get upArrowPress():MovieClip {
		return this.keyboardAnimation.stage.children[0].getChildByName("upArrowPress");
	  }

	  public get downArrowPress():MovieClip {
		return this.keyboardAnimation.stage.children[0].getChildByName("downArrowPress");
	  }

	  public get leftArrowPress():MovieClip {
		return this.keyboardAnimation.stage.children[0].getChildByName("leftArrowPress");
	  }

	  public get rightArrowPress():MovieClip {
		return this.keyboardAnimation.stage.children[0].getChildByName("rightArrowPress");
	  }*/


	protected get isPick():boolean {
		return this.paintMode == PaintMode.pick;
	}

	protected get isColor():boolean {
		return this.paintMode == PaintMode.color;
	}

	ngOnInit():void {
		//{useHash: true}
		this.start();
		this.setColor('main', this.color);

		this.movieWordBankRepository.watchList$().subscribe(wordlist => {
			this.bankWords = wordlist;
		});
	}

	public ngAfterViewInit() {

	}

	public isCurrentUser(user:User):boolean {
		return this.user == user;
	}
	public get currentUserRef():UserRef {
		let user:User = this.userRepository.get();
		let userRef:UserRef = new UserRef();

		if(user) {
			userRef.guid = user.guid;
			userRef.nickname = user.nickname;
		}

		return userRef;
	}

	public start():void {
		//this.captionIndex = 0;
		let captionGuid:string = 'subtitle' + this.captionIndex;
		console.log('start this.captionIndex: ' + this.captionIndex + ` ${captionGuid}`);
		//Dont use watch here...
		this.renderAndWatchCaption(captionGuid);
	}

	public renderAndWatchCaption(captionGuid:string):void {
		if(this.currentCaptionSubscription != null) {
			this.unsubscribeAndStopTracking(this.currentCaptionSubscription);
		}
		this.currentCaptionSubscription = this.trackSubscription(this.timedNetflixCaptionRepository.watch$(captionGuid).subscribe(timedNetflixCaption => {
			this.renderCaption(timedNetflixCaption);
		}));
	}

	public innerWidth():number {
		return window.innerWidth;
	}

	/**
	 * Set color from color picker
	 * @param type The type of color to set
	 * @param color The color to set
	 */
	public setColor(type:string, color:string) {
		switch (type) {
			case 'main':
				this.color = color.replace('#', '');
				break;
			default:
				break;
		}
	}

	public previousCaption():void {

		if(this.mode['1'] == MODE.EDIT || this.mode['2'] == MODE.EDIT) {
			this.saveCaption(this.currentCaption);

		}
		this.captionIndex = this.captionIndex - 1 < 0 ? this.totalCaptions - 1 : this.captionIndex - 1;
		this.navigateTo(this.currentMovie.movieId, this.captionIndex);
		return;

		let captionGuid:string = 'subtitle' + this.captionIndex;
		this.renderAndWatchCaption(captionGuid);

	}

	public gotoCaptionAt(index:number):void {
		//TODO: make sure index is valid
		this.captionIndex = index;
		if (this.user) {
			this.user.caption = index;
		}
		this.realtimePresenceService.setCaptionIndex(this.captionIndex);
		let captionGuid:string = 'subtitle' + this.captionIndex;
		this.renderAndWatchCaption(captionGuid);

	}

	public navigateTo(movieId:string = null, index?:number):void {
		if(movieId == null) {
			movieId = this.currentMovie.movieId;
		}
		// @ts-ignore
		index = parseInt(index);

		if(!isNaN(index)) {
			this.router.navigate(['/movies/', movieId, index]);
			this.gotoCaptionAt(index);
		}
	}

	public previousUncaptionedCaption():void {
		if(this.mode['1'] == MODE.EDIT || this.mode['2'] == MODE.EDIT) {
			this.saveCaption(this.currentCaption);
		}
		//These are the captions that still have work to be done on them
		let incompletePastEntries:Array<CaptionDiaryEntry> = this.captionDiary.toArray().filter(captionDiaryEntry => {
			return captionDiaryEntry.complete == false && captionDiaryEntry.index < this.captionIndex;
		});
		//These are the captions as sorted by their index
		let sortedIncompletePastEntries:Array<CaptionDiaryEntry> = incompletePastEntries.slice().sort((a:CaptionDiaryEntry, b:CaptionDiaryEntry) => {
			return a.index - b.index;
		}).reverse();

		if(sortedIncompletePastEntries.length > 0) {
			this.captionIndex = sortedIncompletePastEntries[0].index;
			let currentCaptionEntry:CaptionDiaryEntry = this.captionDiary.captions[this.captionIndex];
			this.captionIndex = currentCaptionEntry.index;
			this.navigateTo(this.currentMovie.movieId, this.captionIndex);
		}
	}
	public nextUncaptionedCaption():void {
		if(this.mode['1'] == MODE.EDIT || this.mode['2'] == MODE.EDIT) {
			this.saveCaption(this.currentCaption);
		}
		//These are the captions that still have work to be done on them
		let incompleteFutureEntries:Array<CaptionDiaryEntry> = this.captionDiary.toArray().filter(captionDiaryEntry => {
			return captionDiaryEntry.complete == false && captionDiaryEntry.index > this.captionIndex;
		});
		//These are the captions as sorted by their index
		let sortedIncompleteFutureEntries:Array<CaptionDiaryEntry> = incompleteFutureEntries.slice().sort((a:CaptionDiaryEntry, b:CaptionDiaryEntry) => {
			return a.index - b.index;
		});

		if(sortedIncompleteFutureEntries.length > 0) {
			this.captionIndex = sortedIncompleteFutureEntries[0].index;
			let currentCaptionEntry:CaptionDiaryEntry = this.captionDiary.captions[this.captionIndex];
			this.captionIndex = currentCaptionEntry.index;
			this.navigateTo(this.currentMovie.movieId, this.captionIndex);
		}
	}


	public nextCaption():void {
		if(this.mode['1'] == MODE.EDIT || this.mode['2'] == MODE.EDIT) {
			this.saveCaption(this.currentCaption);
		}

		this.captionIndex = this.captionIndex + 1 >= this.totalCaptions ? 0 : this.captionIndex + 1;
		this.navigateTo(this.currentMovie.movieId, this.captionIndex);
		return;
	}

	@HostListener('document:keyup', ['$event'])
	@HostListener('document:keydown', ['$event'])
	public onKeyboardEvent(event:KeyboardEvent):void {
		console.log('event.key :' + event.key);

		if (this.isTextInputFocused) {
			console.log('Ignoring keyboard event because we\'re focused in an input field');
			return;
		}
		if (event.key == 'ArrowLeft') {
			console.log('Go Left!');


			if (event.type == 'keyup') {
				if(event.shiftKey) {
					this.previousUncaptionedCaption();
				} else {
					this.previousCaption();
				}
				/*
						this.leftArrowPress.gotoAndPlay("up");
				*/
			} else if (event.type == 'keydown') {
				/*        this.leftArrowPress.gotoAndPlay("down");*/
			}
		} else if (event.key == 'ArrowRight') {
			console.log('Go Right!');

			if (event.type == 'keyup') {
				if(event.shiftKey) {
					this.nextUncaptionedCaption();
				} else {
					this.nextCaption();
				}
				/*
						this.rightArrowPress.gotoAndPlay("up");
				*/
			} else if (event.type == 'keydown') {
				/*
						this.rightArrowPress.gotoAndPlay("down");
				*/
			}
		}
	}


	public onSaveAllClick():void {
		this.netflixMovieService.updateNetflixMovie(this.currentMovie.movieId.toString());
	}

	public onUserClick(user:User) {
		this.navigateTo(this.currentMovie.movieId, user.caption);
	}

	public seekTo(seconds:number):void {
		console.log(`Seeking to to ${seconds}`);
		if (window.netflix) {
			let videoPlayerWrapper = window.netflix.appContext.state.playerApp.getAPI().videoPlayer;
			let sessionId = videoPlayerWrapper.getAllPlayerSessionIds()[0];
			let videoPlayer = videoPlayerWrapper.getVideoPlayerBySessionId(sessionId);
			videoPlayer.seek(seconds * 1000);
		}
	}

	protected onClick($event:MouseEvent) {
		console.log('$event.currentTarget: ' + $event.currentTarget);

		console.log('$event.target: ' + $event.target);
		console.log('$event.relatedTarget: ' + $event.relatedTarget);
	}

	protected renderCaption(caption:TimedNetflixCaption):void {
		this.groupedCaptionSet = caption.groupedCaptionSet;
		this.currentCaption = caption;

		this.mode[1] = this.currentCaption.groupedCaptionSet.set1Content == '' ? MODE.EDIT : MODE.COLOR;
		this.mode[2] = this.currentCaption.groupedCaptionSet.set2Content == '' ? MODE.EDIT : MODE.COLOR;
		this.seekTo(caption.beginTime);
	}

	public showSRTModal():void {
		let modalRef:BsModalRef = this.notificationService.displayModal<IAddDisneyPlusMovieModalInputProperties>(AddDisneyPlusMovieModalComponent, this,
			{
				dataProvider: new DisneyPlusRequestData()
			});
		this.tempDisneyModalRef = modalRef;
	}
	protected onSaveEditClick() {
		this.saveCaption(this.currentCaption);
	}

	public get flagged() {
		return this.currentCaption.flagged;

	}
	public set flagged(turnOn:boolean) {
		this.currentCaption.flagged = turnOn
		this.saveCaption(this.currentCaption);
	}

	protected onWordClick(selectableCaption:SelectableCaption) {
		console.log(`${selectableCaption.text} was clicked`);

		if(this.toolMode == ToolMode.Color) {
			selectableCaption.color = this.color;
			this.saveCaption(this.currentCaption);
		}  else if (this.toolMode == ToolMode.Question) {
			this.searchWehewehe(unescape(selectableCaption.text));
		} else if (this.toolMode == ToolMode.AutomatedTranslate) {
			console.log("trying to translate " + selectableCaption.text);
			let translateJob:Observable<string> = this.translateService.translate(selectableCaption.text);
			let s:Subscription = this.trackSubscription(translateJob.subscribe(translation => {
				console.log(`translation for ${selectableCaption.text} was ${translation}`);
				selectableCaption.text = translation as string;
				this.groupedCaptionSet.rerender();
				this.changeDetectorRef.detectChanges();
				this.saveCaption(this.currentCaption);
				//this.mode[2] = MODE.EDIT;
				//this.renderCaption(this.currentCaption);
				s.unsubscribe();
			}));
		} else if(this.toolMode == ToolMode.Bank) {
			this.bankWord(this.groupedCaptionSet, selectableCaption);
		}
	}

	public bankWord(groupedCaptionSet:GroupedCaptionSet, selectableCaption:SelectableCaption) {
		//which side is it in?

		//Subtract words where color does not match currently selected color



		let copy:GroupedCaptionSet = classToClass(groupedCaptionSet);
		let color:string = selectableCaption.color;

		for(let i:number = copy.set2.length-1; i >= 0; i--) {
			let item:SelectableCaption|string = copy.set2[i];
			if(item instanceof SelectableCaption && item.color == color) {
				continue;
			} else if ( typeof item == 'string') {
				continue
			}
			copy.set2.splice(i,1);
		}

		for(let i:number = copy.set1.length-1; i >= 0; i--) {
			let item:SelectableCaption|string = copy.set1[i];
			if(item instanceof SelectableCaption && item.color == color) {
				continue;
			} else if ( typeof item == 'string') {
				continue
			}
			copy.set1.splice(i,1);
		}
		let inSet1:boolean = groupedCaptionSet.set1.find(item => selectableCaption == item) != null;
		let inSet2:boolean = groupedCaptionSet.set2.find(item => selectableCaption == item) != null;
		copy.rerender();

		if(inSet1) {
			console.info(`Was on english side ${selectableCaption.text}`);

			this.movieWordBankRepository.convertAndSave$(copy);
			//english side
		} else if(inSet2) {
			// Hawaiian side..find matching english side
			console.info(`Was on Hawaiian side ${selectableCaption.text}`);
			this.movieWordBankRepository.convertAndSave$(copy);
		} else {
			console.error("weird. not found in either..not banking.");
		}

	}




	public searchWehewehe(value:string) {
		let weheweheUrl:string = 'https://hilo.hawaii.edu/wehe/?q=' + value;
		window.open(weheweheUrl, "wehewehe");
	}

	protected onEditClick(num:number):void {
		this.mode[num] = MODE.EDIT;
		console.log(`Edit ${num} clicked`);
		//this.saveCaption(this.currentCaption);
	}

	protected onAcknowledgeClick(num:number):void {
		this.mode[num] = MODE.COLOR;
		console.log(`Acknowledge ${num} clicked`);
		this.saveCaption(this.currentCaption);
	}
	public prefillCaptionsForCurrentMovie():void {
		this.translateService.prefillCaptionsForCurrentMovie();
	}


	protected onTextBoxFocus($event:FocusEvent) {
		this.isTextInputFocused = true;
	}

	protected onTextBoxBlur($event:FocusEvent) {
		this.isTextInputFocused = false;
	}

	protected isSingleLine(number:number) {
		//String.prototype.lines = function() { return this.split(/\r*\n/); }

	}

	protected onTranslateClick() {
		let translateJob:Observable<string> = this.translateService.translate(this.currentCaption.groupedCaptionSet.set1Content);
		this.trackSubscription(translateJob.subscribe(translation => {
			this.currentCaption.groupedCaptionSet.set2Content = translation;

			this.mode[2] = MODE.EDIT;
			//this.renderCaption(this.currentCaption);
		}));

	}

	protected onUndoClick(number:number) {
		console.log('should undo');
		//TODO: When mode changes to edit save the cur
		//TODO: Reset value to whatever it was before

		//Restore Set 1 from Backup
		if (number == 1) {
			this.groupedCaptionSet.set1Content = this.backup.set1.content;
			this.groupedCaptionSet.set1 = this.backup.set1.backup;
		}
		//Restore Set 2 from Backup
		if (number == 2) {
			this.groupedCaptionSet.set2Content = this.backup.set2.content;
			this.groupedCaptionSet.set2 = this.backup.set2.backup;
		}

		this.mode[number] = MODE.COLOR;
		this.paintMode = PaintMode.color;

	}

	protected onUsedColorClick(usedColor:string) {
		this.paintMode = PaintMode.color;
		this.setColor('main', usedColor);
	}

	protected onColorDropperClick() {
		this.paintMode = PaintMode.pick;
	}

	public saveNickname() {
		if(this.input.desiredNickname.trim().length < 3) {
			return;
		}
		this.realtimePresenceService.setNickname(this.input.desiredNickname);
		this.nameEditMode = false;
	}

	public toggleFlag():void {
		this.currentCaption.flagged = !this.currentCaption.flagged;
		this.saveCaption(this.currentCaption);
	}

	public set toolMode(value:ToolMode) {
		if(this._toolMode == value && this._toolMode == ToolMode.Translate) {
			//nothing
		} else if(value == ToolMode.Translate) {
			this._toolMode = value;
			this.mode['1'] = MODE.COLOR;
			this.mode['2'] = (this.groupedCaptionSet.set2Content == "") ? MODE.EDIT : MODE.COLOR;
		} else {
			this._toolMode = value;
			this.mode['1'] = MODE.COLOR;
			this.mode['2'] = MODE.COLOR;
		}
		if(this.flagSubscription) {
			this.flagSubscription.unsubscribe();
		}
		if(this.toolMode == ToolMode.Flag) {
			this.flagSubscription = this.trackSubscription(this.timedNetflixCaptionRepository.watchQuery$(this.timedNetflixCaptionRepository.by.flagged(true)).subscribe(flaggedCaptions => {
				this.flaggedCaptions = flaggedCaptions.sort(( a, b ) =>{
					let aIndex:number = parseInt(a.guid.match(/\d+/g).map(Number).join());
					let bIndex:number = parseInt(b.guid.match(/\d+/g).map(Number).join());
					if ( aIndex < bIndex ){
						return -1;
					}
					if ( aIndex > bIndex ){
						return 1;
					}
					return 0;
				});
				console.log("updated flaggedCaptions " + flaggedCaptions.length + JSON.stringify(this.flaggedCaptions));
			}));
			//this.trackSubscription(this.flagSubscription);
		}

		this.wordCursor.type = this.currentCursor;

	}

	public get toolMode():ToolMode {
		return this._toolMode;
	}
	public get ToolMode() {
		return ToolMode;
	}

	public usersOnSameSlide():Array<User> {
		return this.users.filter(user => {
			return user.caption == this.captionIndex && this.user && this.user.guid != user.guid;
		});
	}

	public previousFlag():void {
		console.log("previousFlag");
		let currentIndex:number = parseInt(this.currentCaption.guid.match(/\d+/g).map(Number).join());
		let reversedArray:Array<TimedNetflixCaption> = this.flaggedCaptions.slice().reverse();
		let previousFlag:TimedNetflixCaption = reversedArray.find(flagCaption => {
			let flagIndex:number = parseInt(flagCaption.guid.match(/\d+/g).map(Number).join());
			return flagIndex < currentIndex;
		});
		if(previousFlag) {
			let flagIndex:number = parseInt(previousFlag.guid.match(/\d+/g).map(Number).join());
			this.captionIndex = flagIndex;
			this.navigateTo(this.currentMovie.movieId, this.captionIndex);
		} else {
			let lastFlag:TimedNetflixCaption = reversedArray[0];
			if(lastFlag) {
				let lastFlagIndex:number = parseInt(lastFlag.guid.match(/\d+/g).map(Number).join());
				this.captionIndex = lastFlagIndex;
				this.navigateTo(this.currentMovie.movieId, this.captionIndex);
			}
		}
	}
	
	public nextFlag():void {
		console.log("nextFlag");
		let currentIndex:number = parseInt(this.currentCaption.guid.match(/\d+/g).map(Number).join());
		let nextFlag:TimedNetflixCaption = this.flaggedCaptions.find(flagCaption => {
			let flagIndex:number = parseInt(flagCaption.guid.match(/\d+/g).map(Number).join());
			return flagIndex > currentIndex;
		});
		if(nextFlag) {
			let flagIndex:number = parseInt(nextFlag.guid.match(/\d+/g).map(Number).join());
			this.captionIndex = flagIndex;
			this.navigateTo(this.currentMovie.movieId, this.captionIndex);
		} else {
			let firstFlag:TimedNetflixCaption = this.flaggedCaptions[0];
			if(firstFlag) {
				let firstFlagIndex:number = parseInt(firstFlag.guid.match(/\d+/g).map(Number).join());
				this.captionIndex = firstFlagIndex;
				this.navigateTo(this.currentMovie.movieId, this.captionIndex);
			}
		}
	}

	public setCurrentMovie(value:string) {
		this.timedNetflixCaptionRepository.currentMovie = value;
		this.captionDiaryService.currentMovie = value;
		this.movieWordBankRepository.currentMovie = value;

		this.currentMovie.movieId = value;

		this.trackSubscription(this.captionDiaryService.watchDiary$().pipe(
			debounceTime(500), //wait 500 ms for other things to happen
			distinctUntilChanged() //ignore until it changes
		).subscribe(captionDiary => {
			this.captionDiary = captionDiary;
		}));
		let s:Subscription = this.netflixMovieService.movie.get(value).subscribe(netflixMovie => {
			s.unsubscribe();
			this.netflixMovie = netflixMovie;
			this.posterUrl = this.urlService.poster.url(netflixMovie.provider, this.netflixMovie.titleId);
		});
	}



	public get currentCursor():string {
		if(this.toolMode == ToolMode.Question) {
			this.wordCursor.type = "help";
			return 'help';
		} else if (this.toolMode == ToolMode.Color) {
			this.wordCursor.type = "cell";
			return 'cell';
		}
		this.wordCursor.type = "pointer";
		return 'pointer';
	}
	public saveCaption(timedNetflixCaption:TimedNetflixCaption) {
		console.log("saving timedNetflixCaption");
		this.captionDiaryService.saveCaption(timedNetflixCaption);
		this.timedNetflixCaptionRepository.save$(this.currentCaption);

	}
	public onTimelineSelectEvent(captionDiaryEntry:CaptionDiaryEntry) {
		this.captionIndex = captionDiaryEntry.index;
		this.navigateTo(this.currentMovie.movieId, this.captionIndex);
	}

	public watch(options?:{seconds?:number, captionIndex?:number}):void {
		if(!this.netflixMovieService.configuration.isExtensionInstalled) {
			console.error("EXTENSION NOT INSTALLED.  IGNORING REQUEST.")
		} else {
			if(options) {
				this.netflixMovieService.playNetflixMovie(('' + this.currentMovie.movieId), options);
			} else {
				let seconds:number = Math.floor(this.currentCaption.beginTime);
				let captionIndex:number = this.captionIndex;
				this.netflixMovieService.playNetflixMovie(('' + this.currentMovie.movieId), {seconds: seconds, captionIndex: captionIndex});
			}
		}

	}

	public avatarLoc(number:number, width:number) {
		return (((number) * this.innerWidth()) - 11) + 'px';
	}

	public updateReviewItem(caption:CaptionStatsReviewItem) {
		if(caption.approved) {
			caption.date = new Date();
			caption.reviewer = this.currentUserRef;
		} else {
			caption.date = null;
			caption.reviewer = null;
		}
	}

	public appendTextArea(value:string) {
		let textArea:HTMLTextAreaElement = this.oleloEntryControl.nativeElement;

		console.log(`should append ${value} to ${textArea.value}`);

		if(textArea == null) {
			return;
		}

		let selectionStart:number = textArea.selectionStart;
		let selectionEnd:number = textArea.selectionEnd;
		function insertAtCursor(myField, myValue) {
			//IE support
			if (document['selection']) {
				myField.focus();
				let sel = document['selection'].createRange();
				sel.text = myValue;
			}
			//MOZILLA and others
			else if (myField.selectionStart || myField.selectionStart == '0') {
				let startPos:number = myField.selectionStart;
				let endPos:number = myField.selectionEnd;
				myField.value = myField.value.substring(0, startPos)
					+ myValue
					+ myField.value.substring(endPos, myField.value.length);
			} else {
				myField.value += myValue;
			}
		}
		insertAtCursor(textArea, value);
		this.groupedCaptionSet.set2Content = textArea.value;
		textArea.selectionStart = selectionStart+1;
		textArea.selectionEnd = selectionEnd+1;
		textArea.focus();
		textArea.selectionStart = selectionStart+1;
		textArea.selectionEnd = selectionEnd+1;

	}

	public showWordBankModal(bankWord: WordBankGroupedCaptionSet) {
		if(this.shiftOn && bankWord.guid) {
			this.movieWordBankRepository.delete$(bankWord.guid);
			return;
		}
		let properties:IWordBankEditModalComponentInputProperties = {
			wordBank: bankWord
		};
		this.notificationService.displayModal(WordBankEditModalComponent, this, properties);
	}

	addWordBank() {
		let properties:IWordBankEditModalComponentInputProperties = {
			wordBank: new WordBankGroupedCaptionSet(),
			mode: WordBankEditModalComponentMode.Create
		};
		this.notificationService.displayModal(WordBankEditModalComponent, this, properties);
	}

    getCurrentCursor() {
        return this.currentCursor;
    }

	saveFlashcard() {
		console.log("Should save flashcard to Collection; if collection is set.");
		//Choose a flashcard deck


	}
}

declare var AdobeAn;


export class KeyboardAnimation {

	public canvas;
	public stage;
	public exportRoot;
	public anim_container;
	public dom_overlay_container;
	public fnStartAnimation;

	public init() {


		this.canvas = document.getElementById('canvas');
		window.canvas = this.canvas;
		this.anim_container = document.getElementById('animation_container');
		window.anim_container = this.canvas;

		this.dom_overlay_container = document.getElementById('dom_overlay_container');
		window.dom_overlay_container = this.dom_overlay_container;
		let comp = AdobeAn.getComposition('7136F80BAC9A4EFAAA9B40A9DEE9C08E');
		let lib = comp.getLibrary();
		this.handleComplete({}, comp);
	}

	public handleComplete(evt, comp) {
		//This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
		let lib = comp.getLibrary();
		let ss = comp.getSpriteSheet();
		this.exportRoot = new lib.keyboardkeysanimation();
		window.exportRoot = this.exportRoot;

		this.stage = new lib.Stage(this.canvas);
		window.stage = this.stage;
		//Registers the "tick" event listener.
		this.fnStartAnimation = function() {
			this.stage.addChild(this.exportRoot);
			createjs.Ticker.setFPS(lib.properties.fps);
			createjs.Ticker.addEventListener('tick', this.stage);
		};
		window.fnStartAnimation = this.fnStartAnimation;

		//Code to support hidpi screens and responsive scaling.
		AdobeAn.makeResponsive(false, 'both', false, 2, [this.canvas, this.anim_container, this.dom_overlay_container]);
		AdobeAn.compositionLoaded(lib.properties.id);
		this.fnStartAnimation();
	}

}

