import {Component, OnInit} from '@angular/core';
import {PatronService} from "../../../app/services/PatronService";
import {Patron, PatronType} from "../../../app/domain/patrons/Patron";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {PatronRepository} from "../../../app/repository/patrons/PatronRepository";

@Component({
    selector: 'app-list-patrons',
    templateUrl: './list-patrons.component.html',
    styleUrls: ['./list-patrons.component.scss']
})
export class ListPatronsComponent implements OnInit {

    public patrons$: Observable<Array<Patron>>;
    public tiers: { hulo: Array<Patron>, lanakila: Array<Patron>, pookela: Array<Patron>, }
        = {hulo: [], lanakila: [], pookela: []};
    public tiers$: { hulo$: Observable<Array<Patron>>, lanakila$: Observable<Array<Patron>>, pookela$: Observable<Array<Patron>>, }
        = {hulo$: null, lanakila$: null, pookela$: null};

    constructor(private patronService: PatronService, private repo: PatronRepository) {

    }

    ngOnInit() {
        let list$ = this.patronService.list$();
        let rpo = this.repo.list$().subscribe(patron => {
            console.log(patron);

        });

        list$.subscribe(value => {
            console.log(value);
        });
        this.tiers$ = {
            hulo$: list$.pipe(map(patrons => patrons.filter(patron => patron.patronType == PatronType.Hulo))),
            lanakila$: list$.pipe(map(patrons => patrons.filter(patron => patron.patronType == PatronType.Lanakila))),
            pookela$: list$.pipe(map(patrons => patrons.filter(patron => patron.patronType == PatronType.Pookela)))
        };

        this.tiers$.hulo$.subscribe(hulo => { this.tiers.hulo = hulo;});
        this.tiers$.lanakila$.subscribe(lanakila => { this.tiers.lanakila = lanakila; });
        this.tiers$.pookela$.subscribe(pookela => { this.tiers.pookela = pookela; });
    }

    public getTierLabel(key: string) {

        switch (key) {
            case "hulo":
                return "Hulō!";
            case "lanakila":
                return "Lanakila!";
            case "pookela":
                return "Poʻokela!";
        }
        return "";
    }

}
