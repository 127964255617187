import {DeviceInfo} from 'ngx-device-detector';

export class DeviceInformation {

	public type:DeviceType;
	public deviceInfo:DeviceInfo;
}

export enum DeviceType {
	'desktop'='desktop',
	'mobile'='mobile',
	'tablet'='tablet'
}
