import {Injectable} from '@angular/core';
import {Auth0ConfigurationFactory} from './Auth0ConfigurationFactory';
import {WebAuth} from 'auth0-js';
import {Observable, Observer, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {Token} from '../../../domain/registration/Token';
import {Auth0AdapterException, Auth0AdapterExceptions} from './Auth0AdapterException';

@Injectable({
	providedIn: 'root'
})
export class Auth0Adapter {

	public popupHandler;
	public token:Token;

	public userProfile:any;
	public refreshSubscription: any;
	public observer: Observer<boolean>;
	public ssoAuthComplete$: Observable<boolean> = new Observable(
		obs => (this.observer = obs)
	);

	public clients:{regular:WebAuth, passwordless:WebAuth} = {
		regular: null,
		passwordless:null,
	};


	constructor(protected config:Auth0ConfigurationFactory, protected router:Router) {
		this.clients.regular = new WebAuth(this.config.webAuthOptions);
		this.token = new Token();
	}

	/** GOOD **/
	public isAuthenticated():boolean {
		return this.token && this.token.isAuthenticated()
	}



	public getProfile(cb): void {
		if (!this.token) {
			throw new Error('Access token must exist to fetch profile');
		}
		const self = this;
		this.clients.regular.client.userInfo(this.token.accessToken, (err, profile) => {
			if (profile) {
				self.userProfile = profile;
			}
			cb(err, profile);
		});
	}

	private localLogin(authResult): void {
		// Set the time that the access token will expire at
		const expiresAt = (authResult.expiresIn * 1000) + Date.now();
		this.token = new Token();
		this.token.accessToken = authResult.accessToken;
		this.token.idToken = authResult.idToken;
		this.token.expiresAt = expiresAt;

		this.scheduleRenewal();
	}

	public logout():void {
		// Remove tokens and expiry time
		this.clearSession();
		this.unscheduleRenewal();

		this.clients.regular.logout({}); //TODO: add returnTo ... maybe
	}



	public renewTokens() {
		this.clients.regular.checkSession({},
			(err, result) => {
				if (err) {
					alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
					//TODO: Will throwing an exception here even work...
					throw new Auth0AdapterException(Auth0AdapterExceptions.UNABLE_TO_AUTO_LOGIN, {error: err.error, description: err.error_description}, err.error_description);
				} else {
					this.localLogin(result);
					this.observer.next(true);
				}
			}
		);
	}

	public scheduleRenewal() {
		if(!this.isAuthenticated()) {
			return;
		}
		this.unscheduleRenewal();

		const source = Observable.of(this.token.expiresAt).flatMap(expiresAt => {
			const now = Date.now();

			// Use the delay in a timer to
			// run the refresh at the proper time
			return Observable.timer(Math.max(1, expiresAt - now));
		});

		// Once the delay time from above is
		// reached, get a new JWT and schedule
		// additional refreshes
		this.refreshSubscription = source.subscribe(() => {
			this.renewTokens();
			this.scheduleRenewal();
		});
	}

	public unscheduleRenewal() {
		if(!this.refreshSubscription) {
			return;
		}
		this.refreshSubscription.unsubscribe();
	}


	public handleAuthentication(): void {
		this.clients.regular.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				window.location.hash = '';
				this.setSession(authResult);
				this.router.navigate(['/home']);
			} else if (err) {
				this.router.navigate(['/home']);
				console.log(err);
				alert(`Error: ${err.error}. Check the console for further details.`);
			}
		});
	}

	private setSession(authResult): void {
		// Set the time that the access token will expire at
		const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
		localStorage.setItem('access_token', authResult.accessToken);
		localStorage.setItem('id_token', authResult.idToken);
		localStorage.setItem('expires_at', expiresAt);
	}

	public clearSession(): void {
		this.token = null;
		// Remove tokens and expiry time from localStorage
		localStorage.removeItem('access_token');
		localStorage.removeItem('id_token');
		localStorage.removeItem('expires_at');
	}


/*	public renewTokens(): void {
		this.clients.regular.checkSession({}, (err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.localLogin(authResult);
			} else if (err) {
				alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
				this.logout();
			}
		});
	}*/

/*	private handleRedirectWithHash() {
		this.router.events.take(1).subscribe(event => {
			let routerEvent:RouterEvent = event as RouterEvent;

			if (/access_token/.test(event.url) || /error/.test(event.url)) {


				let authResult = this.clients.regular.parseHash(window.location.hash);

				if (authResult && authResult.idToken) {
					this.lock.emit('authenticated', authResult);
				}

				if (authResult && authResult.error) {
					this.lock.emit('authorization_error', authResult);
				}
			}
		});
	}*/

	public parseHash():void {
		let self = this;
		this.clients.regular.parseHash(function(err, data) {
			console.log(err, data);
			if (err) {
				console.error(err);
			}
			if (data) {
				console.log(data);//TODO: Remove
				if (data.accessToken) {
					this.clients.regular.client.userInfo(data.accessToken,
						self.defaultCallback
					);
				}
			}
			window.location.hash = '';
		});
	};


	public login$(username:string, password:string):Observable<boolean> {
		let options = this.config.loginOptions({username: username, password: password});
		let loginSubject$:Subject<boolean> = new Subject<boolean>();
/*
		let s = {redirectUri:environment.auth.redirect, responseType:"token", responseMode:"fragment"};
*/

/*		this.clients.regular.authorize();*/
		this.clients.regular.login(
			{
				username: username,
				password: password
			},
			(info) => {
				console.log("info:");
				console.log(info);
				loginSubject$.next(true);
				loginSubject$.complete();
			});


/*		this.clients.regular.popup.loginWithCredentials({redirectUri:environment.auth.redirect, responseType:"token", responseMode:"fragment"}, (info) => {
			console.log("info:");
			console.log(info);
			loginSubject$.next(true)
			loginSubject$.complete();
		});*/
		return loginSubject$;
	}

	public defaultCallback = (info) => {
		console.log(info);
	}

	public renewAuth():void {
		this.clients.regular.renewAuth(
			{
				usePostMessage: true,
/*				redirectURI: REDIRECT_URL + '/callback.html'*/
			},
			this.defaultCallback
		);
	}


	/* ---------------------------------------------------------------------
	 * Login with Verification Code by Email
	 * -------------------------------------------------------------------*/
	public requestLoginVerificationCodeByEmail(username:string):void {
		this.clients.passwordless.passwordlessStart(
			{
				connection: 'email',
				email: username,
				send: 'code'
			},
			this.defaultCallback
		);
	}
	public loginWithVerificationCode(username:string, verificationCode:string):void {
		this.clients.passwordless.passwordlessLogin(
			{
				connection: 'email',
				email: username,
				verificationCode: verificationCode
			},
			this.defaultCallback
		);
	}

	public preloadPopup():void {
		//TODO: define window height and any other options
		this.popupHandler = this.clients.regular.popup.preload({});
	}

	public loginUsingPopupWithCredentials(username:string, password:string):void {
		this.clients.regular.popup.loginWithCredentials({}
			/*{
				connection: $('#popup-login-connection').val(),
				username: username,
				password: password
			}*/,
			this.defaultCallback
		);

	}


	public loginViaDb(username:string, password:string):void {
		let self = this;
		this.clients.regular.client.login(
			this.config.defaultLoginOptions({username:username, password:password}),
/*				realm: $('#client-login-realm').val(),*/
			(err, data) => {
				this.defaultCallback({err: err, data: data});
				self.clients.regular.client.userInfo(data.accessToken,self.defaultCallback);
			}
		);
	}





	public loginWithHosted():void {
		this.clients.regular.authorize({ connection: 'facebook' });
	}



	public loginWithTwitter():void {
		this.clients.regular.authorize({ connection: 'twitter' });
	}

	public loginWithGithub():void {
		this.clients.regular.authorize({ connection: 'github' });
	}

/*	public loginWithFacebookPopup():void {
		this.clients.regular.popup.authorize({ connection: 'facebook' }, this.defaultCallback);
	}

	public loginWithTwitterPopup():void {
		this.clients.regular.popup.authorize({ connection: 'twitter' }, this.defaultCallback);
	}*/

/*	public loginWithHostedPopup():void {
		this.clients.regular.popup.authorize({
/!*
			redirectURI: REDIRECT_URL + '/callback_popup.html'
*!/
		}, this.defaultCallback);
	}*/



	public checkSession():void {
		this.clients.regular.checkSession({}, this.defaultCallback);
	}

	public getSSOData():void {
		this.clients.regular.client.getSSOData(this.defaultCallback);
	}

/*	public getToken():void {
		this.clients.regular.renewAuth({})
	}
	webAuth.checkSession({}, function (err, authResult) {
		// err if automatic parseHash fails
	...
	});*/
}
