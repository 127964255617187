import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {WordBankGroupedCaptionSet} from "../../domain/bank/WordBankGroupedCaptionSet";
import {IModal} from "../IModal";
import {SelectableCaption} from "../../domain/SelectableCaption";
import {MovieWordBankRepository} from "../../repository/MovieWordBankRepository";

export interface IWordBankEditModalComponentInputProperties {
	wordBank:WordBankGroupedCaptionSet,
	mode?:WordBankEditModalComponentMode
}

@Component({
  selector: 'app-word-bank-edit-modal',
  templateUrl: './word-bank-edit-modal.component.html',
  styleUrls: ['./word-bank-edit-modal.component.scss']
})
export class WordBankEditModalComponent implements OnInit, IModal {

	public component:any;
	public content:IWordBankEditModalComponentInputProperties;

	public wordBank:WordBankGroupedCaptionSet;
	public mode:WordBankEditModalComponentMode = WordBankEditModalComponentMode.View;
	public createWord:string = "";
	public newWordSuggestion:string = "";

	public isValidInput:boolean = true;
	public wantsToDelete:boolean = false;
	public isSure:boolean = false;


	constructor(public bsModalRef:BsModalRef, public router:Router, public movieWordBankRepository:MovieWordBankRepository) {

	}

	ngOnInit() {

	}
	public onProperties(properties:IWordBankEditModalComponentInputProperties):void {
		this.content = properties;
		this.wordBank = properties.wordBank;
		if(this.content.mode) {
			this.mode = this.content.mode;
		}
	}
	public get WordBankEditModalComponentMode() {
		return WordBankEditModalComponentMode;
	}
	public dismiss() {
		this.bsModalRef.hide();
	}

    public addSuggestion():void {
		if(this.newWordSuggestion != "") {
			this.wordBank.suggestions.push(this.newWordSuggestion);
		}
		console.log(`This would add a suggestion`);
		this.newWordSuggestion = ""; //reset

		//Would use but doesnʻt work.
		/*this.movieWordBankRepository.updatePartial$({
			guid: this.wordBank.guid,
			suggestions: this.wordBank.suggestions
		});*/

		this.movieWordBankRepository.save$(this.wordBank);

		console.log(JSON.stringify(this.wordBank));
    }

    public chooseSuggestion(suggestion:string) {
        console.log(`Setting suggestion as ${suggestion}`);
        //let i:number = this.wordBank.suggestions.indexOf(suggestion)
		this.wordBank.chosenSuggestion = suggestion;


/*        if(i != -1) {
        	console.log("i is " + i);
        	let color:string = "#000000";

			while(this.wordBank.set2.length > 0) {
				let set2Item = this.wordBank.set2.pop();
				if(set2Item instanceof SelectableCaption) {
					color = set2Item.color;
				}

			}
			console.log("color is " + color);
			this.wordBank.set2.push(new SelectableCaption(suggestion, color));
        	//this.wordBank.suggestions.splice(i, 1, this.wordBank.set2Content);
        	this.wordBank.rerender();
        	this.movieWordBankRepository.saveOrUpdate$(this.wordBank);
        	this.mode = WordBankEditModalComponentMode.View;
		}*/

		this.wordBank.rerender();
		this.movieWordBankRepository.saveOrUpdate$(this.wordBank);
		this.mode = WordBankEditModalComponentMode.View;
    }

    public delete():void {
		console.log("Deleting");
		this.movieWordBankRepository.delete$(this.wordBank.guid);
	}
    createSuggestion() {
		if(this.createWord.trim() == "") {
			return;
		}
		while(this.wordBank.set1.length > 0) {
			this.wordBank.set1.pop();
		}
		this.wordBank.set1.push(new SelectableCaption(this.createWord, "#000000"));
		this.wordBank.word = this.createWord;
		this.wordBank.guid = null;
		this.wordBank.rerender();
		this.wordBank.generateGuid();


		this.mode = WordBankEditModalComponentMode.View;
		this.movieWordBankRepository.saveOrUpdate$(this.wordBank);
		this.mode = WordBankEditModalComponentMode.AddSuggestion;
    }

	removeSuggestion(suggestion:string) {
		console.log(`Removing suggestion as ${suggestion}`);
		let i:number = this.wordBank.suggestions.indexOf(suggestion);
		if(i != -1) {
			this.wordBank.suggestions.splice(i, 1);
		}
	}
}
export enum WordBankEditModalComponentMode {
	Create="Create",
	AddSuggestion="AddSuggestion",
	View="View"
}
