<div *ngIf="dataProvider.list" class="form-group has-feedback">
    <label>Choose a collection: </label>
    <ng-select id="collectionSelector"
               [(ngModel)]="selected"
               [items]="dataProvider.list"
               [multiple]="false"
               (ngModelChange)="select.emit(selected)"
               bindLabel="title"
               class="bootstrap custom"
               placeholder="Choose a Flashcard Collection">
    </ng-select>
    <small class="form-text text-muted font-italic">Choose a selection</small>
    <a (click)="addClick.emit()" href="javascript:void(0);"><small>Create a new collection</small></a>

</div>

