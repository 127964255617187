import {TimedTextCaption} from '../timedtext/TimedText';

export class NetflixMovieLocalization {
  public from:string = "";
  public to:string = "";
  public captions$:Array<string> = [];

  public data:Array<TimedTextCaption> = [];

  constructor() {

  }

}
