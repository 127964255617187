import {Type} from "class-transformer";
/*
import * as animals from 'animals';
import * as AnimalAvatar from "animal-avatars.js";
*/
import {animals, colors} from "angular-animals";


export class UserAvatar {
  public static MIN_AVATAR_SIZE:number = 50;
  public static MAX_AVATAR_SIZE:number = 285;
  public static DEFAULT_AVATAR_SIZE:number = 250;
  public name:string;
  public animal:string;
  public color:string;

  constructor() {

  }

  public getAvatarUrl(size:number) {
    let avatarSize:number = (!size || size < UserAvatar.MIN_AVATAR_SIZE || size > UserAvatar.MAX_AVATAR_SIZE) ? UserAvatar.DEFAULT_AVATAR_SIZE : size;
    return ("https://api.adorable.io/avatars/:size/".replace(":size", "" + avatarSize) + this.name.replace(" ", "-"));
  }

}

export enum OnlineStatus {
  "away"="away",
  "online"="online"
}
export enum SpecificStatus {
  "signed-in-and-using-app-online"="signed-in-and-using-app-online", //Signed-in and using app (online 💚)
  "signed-in-but-app-is-closed-offline"="signed-in-and-using-app-offline", //Signed-in but app is closed (offline 🔴)
  "signed-in-but-different-browser-tab-away"="signed-in-but-different-browser-tab-away", //Signed-in but on a different browser tab (away 💤)
  "signed-out-but-app-is-still-opened-offline"="signed-out-but-app-is-still-opened-offline", //Signed-out but app is still opened (offline 🔴) //
  "signed-out-and-app-closed-offline"="signed-out-and-app-closed-offline" // //Signed-out and app closed (offline 🔴)

}

export class User {
  public guid:string = "anonymous-giraffe";
  @Type(()=>UserAvatar)
  public avatar:UserAvatar;
  public status:OnlineStatus = OnlineStatus.online;
  public timestamp:Object;
  public movieId:string;
  public nickname:string = ""; //rather than the guid
  public caption:number = 0;
  public email:string = null;

  constructor() {
    this.avatar = new UserAvatar();
    let animal:string = animals[Math.floor(Math.random() * animals.length)];
    this.avatar.animal = animal;
    let possibleColors:Array<string> = Object.keys(colors);
    let randomColor:string = possibleColors[Math.floor(Math.random() * possibleColors.length)];

    this.avatar.color = randomColor;
    this.avatar.name = this.firstLetterUpper(this.avatar.color) + " " + this.firstLetterUpper(this.avatar.animal);

    //this.caption = Math.floor(Math.random()*1572);
    this.guid = this.avatar.name.split(" ").join("-").toLowerCase();
  }

  protected firstLetterUpper(word:string):string {
    return word[0].toUpperCase() + word.slice(1);
  }
  public get easyName():string {

  		let easy:string = (this.nickname != '') ? this.nickname : this.avatar.name;
  		if(easy.indexOf(" ") != -1) {
  			easy = easy.split(" ").map(s => s[0].toUpperCase() + s.slice(1)).join(" ");
	    }
  		return easy;

  }


}
