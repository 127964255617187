<div bsModal class="modal-content custom-modal">
	<div class="modal-header bg-info w-100">
		<h4 class="modal-title">ʻŌleloflix Chrome Extension required</h4>
	</div>
	<div class="modal-body">
		<div class="chrome-exension-required-frame">
			   <p>ʻŌleloflix Chrome Extension
			    and the Chrome Web Browser
			are required to watch Netflix Movies
				   in ʻŌlelo Hawaiʻi.</p>
		</div>
		<h6>ʻŌleloflix Chrome Extension will allow you to:</h6>
		<ul>
			<li>Watch Netflix Movies in ʻŌlelo Hawaiʻi</li>
			<li>On/After May 1st you can choose movies to re-caption in ʻŌlelo Hawaiʻi</li>
			<li>Get grammar and language help</li>
		</ul>
		<p class="text-center">After Installing please <a href="javascript:void(0);"(click)="reload()">reload</a> this page.</p>
	</div>
	<div class="modal-footer border-0">
		<button type="button" class="btn btn-success" (click)="install()">Install</button>
		<button type="button" class="btn btn-dark" (click)="dismiss()">Close</button>
	</div>
</div>

