import {TypedEvent} from "./events/TypedEvent";

export class Mode implements ModeType {

  public onModeChange:TypedEvent<ModeChangeEvent> = new TypedEvent<ModeChangeEvent>();

  private _1:MODE = MODE.COLOR;
  private _2:MODE = MODE.COLOR;

  public get 1():MODE {
    return this._1;
  }
  public set 1(value:MODE) {
    let from:MODE = this._1;
    if(from != value) {
      this._1 = value;
      this.onModeChange.emit(new ModeChangeEvent(1, from, value));
    }

  }

  public get 2():MODE {
    return this._2;

  }
  public set 2(value:MODE) {
    let from:MODE = this._2;
    if(from != value) {
      this._2 = value;
      this.onModeChange.emit(new ModeChangeEvent(2, from, value));
    }
  }

}
export interface ModeType {
  1:MODE,
  2:MODE
}

export enum MODE {
  COLOR = "COLOR",
  EDIT = "EDIT",
  PICK = "PICK"
}

export class ModeChangeEvent {
  public modeType:number;
  public from:MODE;
  public to:MODE;

  constructor(modeType:number, from:MODE, to:MODE) {
    this.modeType = modeType;
    this.from = from;
    this.to = to;
  }
}
