<!-- Site Footer -->
<div class="site-footer2 d-block" *ngIf="footer"><!--  [style.background-image]="sanitized_background_image" -->

		<div class="row pt-5 pb-3">
			<div class="col-3 text-center">
				<a href="https://manastudios.com"><img [attr.src]="footer.logo" alt=""></a>
			</div>
			<div class="col-6">
				<p class="text-white">{{footer.firstColumnShortText}} &copy; 2020 Mana Studios. E mālama kāu kūleana.
				<ul class="social-icons-rounded social-icons-colored w-100 text-center">
					<li class="facebook2"><a href="https://www.facebook.com/manastudioshawaii/"><fa-icon [icon]="icons.faFacebook" transform="grow-5" [inverse]="false"></fa-icon></a></li>
					<li class="instagram2"><a href="https://www.instagram.com/manastudioshi/"><fa-icon [icon]="icons.faInstagram" transform="grow-5" [inverse]="false"></fa-icon></a></li>
				</ul>
			</div>
			<div class="col-2">
				<a class="sc-eNQAEJ SRoDO" color="primary" tabindex="0" type="button" href="https://www.patreon.com/bePatron?u=30090892" role="button" target="_self"><div tabindex="-1" class="sc-ckVGcZ cTkTAz"><svg viewBox="0 0 64 64" aria-label="Loading" class="sc-cSHVUG ceJMdf"><circle cx="32" cy="32" r="32" class="sc-kAzzGY dTxGfi"></circle><circle color="light" cx="32" cy="32" r="32" stroke-linecap="round" class="sc-chPdSV guEFzQ"></circle></svg></div><div class="sc-dxgOiQ hhhktq"><div class="sc-VigVT fAweOF"><span class="sc-gqjmRU iovYiN"><svg viewBox="0 0 569 546" xmlns="http://www.w3.org/2000/svg"><g><circle cx="362.589996" cy="204.589996" data-fill="1" id="Oval" r="204.589996"></circle><rect data-fill="2" height="545.799988" id="Rectangle" width="100" x="0" y="0"></rect></g></svg></span></div><div class="sc-jKJlTe hRyriC"></div>Become a patron.<br/>E kōkua mai.</div></a>
			</div>
			<div class="col-1">

			</div>
		</div>
	</div>

