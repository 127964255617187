import {GroupedCaptionSet} from "../GroupedCaptionSet";
import {Exclude, Type} from "class-transformer";
import {Flashcard} from "./Flashcard";
import {IndexProperty} from "../IndexProperty";
import {NetflixMovieRef} from "../database/NetflixMovie";
import {MinLength} from "class-validator";


export class FlashcardCollectionConfiguration {
    public visibility:CollectionVisibilityType;

    @IndexProperty({isArray: true, property: "guid"})
    protected movies:Array<NetflixMovieRef> = [];


}
export class FlashcardOwner {
    public guid:string = null;
    public emailAddress:string = null;
}

export class FlashcardCollection {

    @Exclude({toPlainOnly: true})
    public guid:string = null

    @Type(()=> FlashcardOwner)
    public owner:string = null;

    @MinLength(5)
    public title:string = "";

    public skill:SkillLevel;

    @Type(()=> FlashcardCollectionConfiguration)
    public configuration:FlashcardCollectionConfiguration;

    @Type(()=>Flashcard)
    public flashcards:Array<Flashcard> = [];

    constructor() {
        this.configuration = new FlashcardCollectionConfiguration();
        this.configuration = new FlashcardCollectionConfiguration();
    }

}

export type SkillLevel = "unrated"|0|1|2|3|4|5|6|7|8|9|10;


export enum CollectionVisibilityType {
    privateToUser = "privateToUser",
    privateToMovie = "privateToMovie",
    public = "public"
}
