<div class="area">
	<div class="oleloflix-logo">
		<div class="oleloflix">
			<span class="donut">ʻŌ</span>
<!--			<span></span>
			<span></span>-->
		</div>
		<h3>ʻŌLELOFLIX</h3>
	</div>
</div>

