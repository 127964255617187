import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SelectableCaption} from '../domain/SelectableCaption';

@Component({
  selector: 'app-selectable-caption-word',
  templateUrl: './selectable-caption-word.component.html',
  styleUrls: ['./selectable-caption-word.component.scss']
})
export class SelectableCaptionWordComponent implements OnInit {

  @Input()
  public selectableCaption:SelectableCaption | string;

  @Input()
  public cursor:string = "pointer";


  constructor() { }

  ngOnInit() {
  }

/*  ngOnChanges(changes: SimpleChanges): void {
    this.cursor = changes["cursor"].currentValue;
  }*/

  protected onClick(mouseEvent:MouseEvent) {

  }

  public get color():string {
    if(this.selectableCaption instanceof SelectableCaption) {
      return this.selectableCaption.color;
    }
    return "#000000";
  }

}
