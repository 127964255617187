import * as decode from 'unescape';
import {UserAvatar} from '../domain/User';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Provider} from "../domain/providers/Provider";

@Injectable({
	providedIn: 'root'
})
export class UrlService {

	public avatar = {
		url: (guid:string, size:number = 80) => {
			let avatarSize:number = (!size || size < UserAvatar.MIN_AVATAR_SIZE || size > UserAvatar.MAX_AVATAR_SIZE) ? UserAvatar.DEFAULT_AVATAR_SIZE : size;
			return ("https://api.adorable.io/avatars/:size/".replace(":size", "" + avatarSize) + guid);
		}
	}
	public poster = {
		url: (provider:Provider, movieId:string) => {
			return `https://storage.googleapis.com/${environment.firebase.storageBucket}/${provider}/Posters/${movieId}.jpg`;
		}
	};
	public series = {
		poster: {
			url: (seriesId:string) => {
				return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Netflix/Posters/Series/${seriesId}/${seriesId}.jpg`;
			}
		},
		episode: {
			thumbnail: {
				url: (seriesGuid:string, movieId:string, seasonId?:string) => {

					return seasonId ?
						`https://storage.googleapis.com/${environment.firebase.storageBucket}/Netflix/Posters/Series/${seriesGuid}/${seasonId}/${movieId}.jpg` :
						`https://storage.googleapis.com/${environment.firebase.storageBucket}/Netflix/Posters/Series/${seriesGuid}/${movieId}.jpg`;
				}
			}
		}
	}
	public site = {
		oleloJam: {
			url: (movieId:string) => {
				return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Site/olelo-jams/${movieId}.jpg`;
			}
		}
	}

}
