import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-olelo-entry-button',
	templateUrl: './olelo-entry-button.component.html',
	styleUrls: ['./olelo-entry-button.component.scss']
})
export class OleloEntryButtonComponent implements OnInit {

	@Input()
	public label:string = '';

	@Output()
	public letter:EventEmitter<OleloEntryButtonComponent> = new EventEmitter<OleloEntryButtonComponent>();

	constructor() {
	}

	ngOnInit() {
	}

	public onClick($event:MouseEvent) {
		this.letter.emit(this);
	}
}
