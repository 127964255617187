import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionCleaner} from '../../../app/util/SubscriptionCleaner';
import {Observable, Subscription} from 'rxjs';
import {NetflixSeriesService} from "../../../app/services/NetflixSeriesService";
import {NetflixSeries} from "../../../app/domain/database/NetflixSeries";
import {
	faArrowLeft,
	faEye,
	faPlay,
	IconDefinition
} from "@fortawesome/free-solid-svg-icons";


@Component({
	selector: 'app-list-episodes',
	templateUrl: './list-episodes.component.html',
	styleUrls: ['./list-episodes.component.scss']
})
export class ListEpisodesComponent extends SubscriptionCleaner implements OnInit {

	public icons = {
		faArrowLeft: faArrowLeft,
		faEye: faEye,
		faPlay: faPlay,
	};
	public series:NetflixSeries;

	constructor(private netflixSeriesService:NetflixSeriesService, private route:ActivatedRoute) {
		super();
		const seriesGuid:string = this.route.snapshot.params['seriesGuid'] != "" ? this.route.snapshot.params['seriesGuid'] : undefined;
		if(seriesGuid) {
			this.trackSubscription(this.netflixSeriesService.watch$(seriesGuid).subscribe(series => {
				this.series = series;
			}));
		}
	}

	private helpKaliko():void {
		window.open("https://docs.google.com/forms/d/e/1FAIpQLSdCI83yHuLuYRW8UoCtUqDl9mJq5VXvJwDZ1WBuFuIW5PK6Mg/viewform", "_blank")
	}

	ngOnInit() {

	}
}
