import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {NetflixMovie} from '../domain/database/NetflixMovie';
import {AngularFirestore} from '@angular/fire/firestore';
import {OleloJamRegistration} from '../domain/olelojam/OleloJamRegistration';

@Injectable({
	providedIn: 'root',
} as any)
export class OleloJamRegistrationRepository extends AbstractFirestoreRepository<OleloJamRegistration> {
	constructor(protected db:AngularFirestore) {
		super(OleloJamRegistration, db, db.collection('OleloJamRegistrations'));
	}
}
