import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
	selector: '[toggle]'
})
export class ToggleDirective implements OnInit {

	private _shown = false;
	@Input("toggle")
	public toggleTarget:string;



	constructor(private el: ElementRef) {


/*		const self:HTMLElement = this.el.nativeElement;
		const parent:HTMLElement = this.el.nativeElement.parentNode;


		this.el.nativeElement.on('click', () => {
			
			
			
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});


		const span = document.createElement('span');
			span.innerHTML = 'show';
			span.addEventListener('click', () => {
				this.toggle(span);
			});
		parent.appendChild(span);*/
	}
	ngOnInit():void {
		console.log("toggle directive " + this.toggleTarget);
		this.render();
	}

	public render():void {
		let self:HTMLElement  = this.el.nativeElement as HTMLElement;
		let parent:HTMLElement  = self.parentNode as HTMLElement;
		let s:string = this.toggleTarget.substring(1); //without the #
		let input:HTMLInputElement = parent.querySelector(`input[name='${s}']`);

		if (this._shown) {
			input.type = 'text';
			self.classList.remove('fa-eye-slash');
			self.classList.add('fa-eye');

			input.setAttribute('type', 'text');

		} else {
			input.type = 'password';
			self.classList.remove('fa-eye');
			self.classList.add('fa-eye-slash');
			input.setAttribute('type', 'password');
		}

	}

	@HostListener('click', ['$event'])
	public toggle(event:MouseEvent) {
		this._shown = !this._shown;
		this.render();
	}


}
