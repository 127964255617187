<div bsModal class="modal-content custom-modal">
	<div class="modal-header bg-info w-100">
		<h4 class="modal-title">Netflix Party Extension Required</h4>
	</div>
	<div class="modal-body">
		<div class="chrome-exension-required-frame">
			   <p>Netflix Party (unrelated to the 'Ōleloflix extension) and a
			    Desktop Chrome Web Browser
			are required to watch Netflix Movies
				     as a group.</p>
		</div>
		<h6>What to do:</h6>
		<ul>
			<li>Read all the bullet points before proceeding</li>
			<li>Install the Extension</li>
			<li>Come back here</li>
			<li>Click launch <strong>(WAIT!, NOT YET!)</strong></li>
			<li>After clicking launch below, click the red NP to join the party.</li>
		</ul>
		<p class="text-center">
			<strong>IMPORTANT</strong><br/>After Installing and Launching, please click the red NP to join the party.
			<img src="/assets/party/watch-party-instruction.jpg"></p>
	</div>
	<div class="modal-footer border-0">
		<button type="button" class="btn btn-success" (click)="install()">Install</button>
		<button type="button" class="btn btn-primary" (click)="launchParty()">Launch</button>
		<button type="button" class="btn btn-dark" (click)="dismiss()">Close</button>
	</div>
</div>

