import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import * as decode from 'unescape';
import {SelectableCaption} from '../domain/SelectableCaption';
import {ToolMode} from '../../areas/captioning/captioning.component';
import {TimedNetflixCaptionRepository} from '../repository/TimedNetflixCaptionRepository';
import {TimedNetflixCaption} from '../domain/database/TimedNetflixCaption';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  // List of output languages.
  public languages:Array<LanguageOptions> = [LanguageOptions.en, LanguageOptions.haw];

  public constructor(private httpClient:HttpClient, private timedNetflixCaptionRepository:TimedNetflixCaptionRepository) {

  }

  // URL to the Google Translate API.
  public createTranslateUrl(lang:LanguageOptions, text:string) {
    let apiKey:string = environment.firebase.apiKey;
    text = unescape(text);
    return `https://www.googleapis.com/language/translate/v2?key=${apiKey}&source=en&target=${lang}&q=${text}`;
  }

  public translate(text:string):Observable<string> {
    let tranlateJob:Subject<string> = new Subject();
    const url:string = this.createTranslateUrl(LanguageOptions.haw, text);

    this.httpClient.get(
      url,
      {/*headers, */})
      .subscribe(
        (data:{data:{translations:Array<{translatedText:string}>}}) => {
          console.log('GET Request is successful ', data);

          if(data && data.data && data.data.translations && data.data.translations.length > 0) {
            tranlateJob.next(decode(data.data.translations[0].translatedText));
          }
        },
        error => {
          console.log('Error', error);
          tranlateJob.error(error);
        }
      );


    return tranlateJob;
  }

	public prefillCaptionsForCurrentMovie() {
		if(this.timedNetflixCaptionRepository.currentMovie == null) {
			console.error("no current movie. exiting");
			return;
		}
		let s2:Subscription = this.timedNetflixCaptionRepository.watchList$().subscribe(timedNetflixCaptions => {
			if(timedNetflixCaptions.length == 1) return;
			s2.unsubscribe();
			for(let timedNetflixCaption of timedNetflixCaptions) {
				console.log("looking at " + timedNetflixCaption.groupedCaptionSet.set1Content);
				//Only translate if it hasnʻt been done before since we're client side..this needs to be moved to server and have some sort of 'dirty' state
				if(!timedNetflixCaption.groupedCaptionSet._set2GoogleTranslatedContent) {
					let textToTranslate:string = timedNetflixCaption.groupedCaptionSet.set1Content;
					let translateJob:Observable<string> = this.translate(textToTranslate);
					let s:Subscription = translateJob.subscribe(translation => {
						s.unsubscribe();
						console.log(`translation for ${textToTranslate} was ${translation}`);
						timedNetflixCaption.groupedCaptionSet._set2GoogleTranslatedContent = translation as string;
						this.timedNetflixCaptionRepository.save$(timedNetflixCaption);
					});
				}
			}
		});
	}




}

export enum LanguageOptions {
  en = 'en',
  haw = 'haw'
}
