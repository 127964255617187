import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {CaptionDiaryRepository} from '../repository/CaptionDiaryRepository';
import {CaptionDiary, CaptionDiaryEntry} from '../domain/CaptionDiary';
import {TimedNetflixCaption} from '../domain/database/TimedNetflixCaption';
import {take} from 'rxjs/operators';
import {classToPlain} from 'class-transformer';
import {TimedNetflixCaptionRepository} from '../repository/TimedNetflixCaptionRepository';
import {NetflixMovieService} from './NetflixMovieService';
import {TimedTextCaption} from '../domain/timedtext/TimedText';


@Injectable({
	providedIn: 'root'
})
export class CaptionDiaryService {

	public activeDiary:CaptionDiary;
	public results:Array<TimedNetflixCaption> = [];

	constructor(private repo:CaptionDiaryRepository, private netflixMovieService:NetflixMovieService, private timedNetflixCaptionRepository:TimedNetflixCaptionRepository) {

	}

	public get currentMovie():string {
		return this.repo.currentMovie;
	}

	public set currentMovie(value:string) {
		this.repo.currentMovie = value;

	}

	public createDiary():void {
		let s2:Subscription = this.timedNetflixCaptionRepository.list$(false).subscribe(results => {
			s2.unsubscribe();
			this.results = results;
			if (this.results.length > 10) { // was 0
				console.log('creating new caption diary');
				this.activeDiary = new CaptionDiary();
				for (let i: number = 0; i < this.results.length; i++) {
					let timedNetflixCaption: TimedNetflixCaption = this.results[i];
					let index: number = parseInt(timedNetflixCaption.guid.split('subtitle').join(""));

					let captionDiaryEntry: CaptionDiaryEntry = this.activeDiary.captions[index];
					if (captionDiaryEntry == null) {
						this.activeDiary.captions[index] = new CaptionDiaryEntry();
						captionDiaryEntry = this.activeDiary.captions[index];
					}

					let hasContent: Function = (value: string): boolean => {
						return value != null && value != '';
					};
					let englishMissing: boolean = !hasContent(timedNetflixCaption.groupedCaptionSet.set1Content);
					let hawaiianMissing: boolean = !hasContent(timedNetflixCaption.groupedCaptionSet.set2Content);

					captionDiaryEntry.index = index;
					captionDiaryEntry.complete = hasContent(timedNetflixCaption.groupedCaptionSet.set1Content) && hasContent(timedNetflixCaption.groupedCaptionSet.set2Content);
					//captionDiaryEntry.partial = !captionDiaryEntry.complete && (englishMissing || hawaiianMissing);
					captionDiaryEntry.flagged = timedNetflixCaption.flagged;
					captionDiaryEntry.subtitleId = timedNetflixCaption.guid;
				}
				this.repo.save$(this.activeDiary);
			}
		});
	}

	public watchDiary$():Observable<CaptionDiary> {
		let diary:Observable<CaptionDiary> = this.repo.watchDiary$();


		let s:Subscription = diary.pipe(take(1)).subscribe(captionDiary => {
			s.unsubscribe();
			this.activeDiary = captionDiary;
			return;
			if(captionDiary == null ) {
				let s2:Subscription = this.timedNetflixCaptionRepository.list$(false).subscribe(results => {
					s2.unsubscribe();
					this.results = results;
					if (this.results.length > 10) { // was 0
						console.log('creating new caption diary');
						this.activeDiary = new CaptionDiary();
						for(let i:number = 0; i < this.results.length ; i++) {
							let timedNetflixCaption:TimedNetflixCaption = this.results[i];
							let index:number = parseInt(timedNetflixCaption.guid.split('subtitle').join(""));

							let captionDiaryEntry:CaptionDiaryEntry = this.activeDiary.captions[index];
							if (captionDiaryEntry == null) {
								this.activeDiary.captions[index] = new CaptionDiaryEntry();
								captionDiaryEntry = this.activeDiary.captions[index];
							}
							let hasContent:Function = (value:string):boolean => {
								return value != null && value != '';
							};
							let englishMissing:boolean = !hasContent(timedNetflixCaption.groupedCaptionSet.set1Content);
							let hawaiianMissing:boolean = !hasContent(timedNetflixCaption.groupedCaptionSet.set2Content);

							captionDiaryEntry.index = index;
							captionDiaryEntry.complete = hasContent(timedNetflixCaption.groupedCaptionSet.set1Content) && hasContent(timedNetflixCaption.groupedCaptionSet.set2Content);
							//captionDiaryEntry.partial = !captionDiaryEntry.complete && (englishMissing || hawaiianMissing);
							captionDiaryEntry.flagged = timedNetflixCaption.flagged;
							captionDiaryEntry.subtitleId = timedNetflixCaption.guid;
						}
						this.repo.save$(this.activeDiary);

					}

				});
			}
		});

		return diary;
	}

	public saveCaption(timedNetflixCaption:TimedNetflixCaption):void {

		let index:number = parseInt(timedNetflixCaption.guid.split('subtitle').join(''));

		let captionDiaryEntry:CaptionDiaryEntry = this.activeDiary.captions[index];
		if (captionDiaryEntry == null) {
			this.activeDiary.captions[index] = new CaptionDiaryEntry();
			captionDiaryEntry = this.activeDiary.captions[index];
		}
		let hasContent:Function = (value:string):boolean => {
			return value != null && value != '';
		};
		let englishMissing:boolean = !hasContent(timedNetflixCaption.groupedCaptionSet.set1Content);
		let hawaiianMissing:boolean = !hasContent(timedNetflixCaption.groupedCaptionSet.set2Content);

		captionDiaryEntry.index = index;
		captionDiaryEntry.complete = hasContent(timedNetflixCaption.groupedCaptionSet.set1Content) && hasContent(timedNetflixCaption.groupedCaptionSet.set2Content);
		//captionDiaryEntry.partial = !captionDiaryEntry.complete && (englishMissing || hawaiianMissing);
		captionDiaryEntry.flagged = timedNetflixCaption.flagged;
		captionDiaryEntry.subtitleId = timedNetflixCaption.guid;

		this.repo.updatePartial$(
			{
				'guid': 'en-haw',
				'captions': {
					[index]: classToPlain(captionDiaryEntry)
				}
			}
		);
	}


}
