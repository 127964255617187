export class CaptionDiaryEntry {
	index:number;
	subtitleId:string;
	flagged:boolean;
	complete:boolean;
}
export class CaptionDiaryEntryRangeSummary {
	index:number;
	subtitleId:string;
	flagged:boolean;
	complete:boolean;
	partial:boolean;
}

export class CaptionDiary {
	public guid:string = 'en-haw';
	public captions:{[key:string]:CaptionDiaryEntry} = {};

	public push(captionDiaryEntry:CaptionDiaryEntry) {
		if(captionDiaryEntry && captionDiaryEntry.index) {
			this.captions[captionDiaryEntry.index] = captionDiaryEntry;
		}
	}
	public get length():number {
		return Object.keys(this.captions).length;
	}
	public toArray():Array<CaptionDiaryEntry> {
		return Object.values(this.captions);
	}
	public get done():number {
		return this.toArray().filter(captionDiaryEntry => {
			return captionDiaryEntry.complete;
		}).length;
	}
	public get percentage():number {
		return this.done / this.length;
	}
	public get prettyPercentage():string {
		return `${Math.floor(this.percentage*100)}%`;
	}

};


