import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {AuthOptions, CrossOriginLoginOptions, DefaultLoginOptions} from 'auth0-js';

@Injectable({
	providedIn: 'root'
})
export class Auth0ConfigurationFactory {

	public config:IAuth0Configuration = {
		clientID: environment.auth.clientId,
		domain: environment.auth.clientDomain,
		responseType: 'token',
		redirectUri: `${window.location.origin}/callback`, //environment.auth.redirect,
		audience: environment.auth.audience,
		scope: environment.auth.scope
	};

	constructor() {
		this.config = {
			clientID: environment.auth.clientId,
			domain: environment.auth.clientDomain,
			responseType: 'token',
			redirectUri: `${window.location.origin}/callback`, //environment.auth.redirect,
			audience: environment.auth.audience,
			scope: environment.auth.scope
		};


	}


	public get webAuthOptions():AuthOptions {

/*		webAuth = new auth0.WebAuth({
			domain: domain,
			redirectUri: REDIRECT_URL,
			clientID: clientId,
			responseType: 'token id_token',
			plugins: [new CordovaAuth0Plugin()]
		});*/

		return {
			domain: environment.auth.clientDomain,
			redirectUri: `${window.location.origin}/#/callback`, //environment.auth.redirect,
			clientID: environment.auth.clientId,
			responseType: 'token id_token',
			scope: 'openid profile'

			/*audience: environment.auth.audience,
			scope: environment.auth.scope*/

			//Unused options ---------------------
			/*
				responseMode?: string;
				leeway?: number;
				plugins?: any[];
				_disableDeprecationWarnings?: boolean;
				_sendTelemetry?: boolean;
				_telemetryInfo?: any;
			*/
		}
	}

	public defaultLoginOptions(params:{username:string, password:string}):DefaultLoginOptions {
		let c:DefaultLoginOptions = {
			audience: "", password: params.password, realm: "", scope: "", username:params.username

		}
		return c;
	}

	public loginOptions(params:{username?:string, password:string}):CrossOriginLoginOptions {
		/*let c:CrossOriginLoginOptions = {
			username?: string;
			email?: string;
			password: string;
			realm?: string;
			domain?: string;
			clientID?: string;
			redirectUri?: string;
			responseType?: string;
			responseMode?: string;
			state?: string;
			nonce?: string;
			scope?: string;
			audience?: string;
		}*/

		return {
			username: params.username ? params.username : undefined,
			password: params.password,
			audience: environment.auth.audience,
			clientID: environment.auth.clientId,
			responseType: 'token',
			redirectUri: `${window.location.origin}/#/callback}`
		};
	}
}

export interface IAuth0Configuration {
	clientID:string,
	domain:string,
	responseType:string
	redirectUri:string,
	audience:string,
	scope:string
	
}
export enum Scope {
	openid='openid',
	profile='profile'
}
