<div bsModal class="modal-content custom-modal">
	<div class="modal-header bg-info w-100">
		<h4 class="modal-title">ʻŌlelo Jam Registration</h4>
	</div>
	<div class="modal-body">
		<div class="chrome-exension-required-frame">
			   <p>Please register for the Jam with the following information. <strong>(all fields are required)</strong></p>
		</div>
		<ul class="list-group">
			<li class="list-group-item p-2"><input [(ngModel)]="registration.firstName" placeholder="First Name" class="w-50" autofocus></li>
			<li class="list-group-item p-2"><input [(ngModel)]="registration.lastName" placeholder="Last Name" class="w-50" ></li>
			<li class="list-group-item p-2"><input [(ngModel)]="registration.email" placeholder="Email"  class="w-100"></li>
			<li class="list-group-item p-2"><input [(ngModel)]="registration.reasonForAttending" placeholder="Reason for attending."  class="w-100  p-2"></li>
		</ul>
		<p class="text-center color-red" *ngIf="!isValidInput">Please make sure you enter all fields</p>
	</div>
	<div class="modal-footer border-0">
		<button type="button" class="btn btn-danger" (click)="dismiss()">Cancel</button>
		<button type="button" class="btn btn-success" (click)="submit()">Register</button>
	</div>
</div>

