import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {

	public transform(list:any[], sortType:SortType):any {

		return list.sort((a,b) => {
			if(sortType == SortType.Alphabetical) {
				let aString:string = (a['title'] as string).toLowerCase();
				let bString:string = (b['title'] as string).toLowerCase();

				if(aString < bString) {
					return -1;
				} else if(aString > bString) {
					return 1;
				}
				return 0;
			}
			if(sortType == SortType.Date) {
				let aDate:number = (a['date'] as Date).getTime();
				let bDate:number = (b['date'] as Date).getTime();
				if(aDate < bDate) {
					return -1;
				} else if(aDate > bDate) {
					return 1;
				}
				return 0;
			}
		});

	}

}

export enum SortType {
	Alphabetical='Alphabetical',
	Date='Date'
}
