import {Type} from "class-transformer";

export class Address {
    public addressee:string = null;
    public line_1:string = null;
    public line_2:string = null;
    public postal_code:string = null;
    public city:string = null;
    public state:string = null;
    public country:string = null;
}
export class Pledge {

    public amountCents:number = 0;//500
    public avatarUrl:string = "https://c8.patreon.com/2/200/33538940";
    public createdDate:string = "00/00/0000";//createdDate = 2020
    public email:string = "k11kynaka@gmail.com";
    public firstName:string = "Kyle";
    public isPaused:string = "False";
    public lastName:string = "Kolomona Nakatsuka"
    public patronId:number = 33538940;
    public rewardId:number = 4881128; //$5 , $15 == 4881127
    public has_shipping_address = false

    @Type(()=> Address)
    public address:Address;

}

