export class TimedText {

}
export type TTAttributeSet 	= {"@_begin":string,"@_end":string,"@_region":string,"@_style":string,"@_xml:id":string};
export type TTAttributeGroup = {  "#text"?:string, br?:string, attr:TTAttributeSet };

export interface ITimedTextFile {
  "tt":{
    "head":{
      "ttp:profile":string,
      "styling":{
        "style":[string]
      },
      "layout":{
        "region":string
      }
    },
    "body":{
      "div":{
        p: [{  "#text"?:string,
          br?:string,
          attr: TTAttributeSet
        }]
      }
    }
  };
}

export class TimedTextCaption {
  public begin:string;
  public end:string;
  public beginSeconds:number;
  public endSeconds:number;
  public region:string;
  public style:string;
  public id:string;
  public content:string;
}

/*

      "<p begin=\"537203332t\" end=\"554303750t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle0\">Now, I know what you're thinking.</p>\n" +
      "      <p begin=\"555137916t\" end=\"588921666t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle1\">\"Oh, no! Thor's in a cage.<br/>\n" +
      "        How did this happen?\"</p>\n" +
      "      <p begin=\"593092500t\" end=\"611027082t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle2\">Well, sometimes you have to get captured</p>\n" +
      "      <p begin=\"611861250t\" end=\"628127500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle3\">just to get a straight answer<br/>\n" +
      "        out of somebody.</p>\n" +
      "      <p begin=\"642308332t\" end=\"673589582t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle4\">It's a long story,<br/>\n" +
      "        but basically, I'm a bit of a hero.</p>\n" +
      "      <p begin=\"689021666t\" end=\"711961250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle5\">See, I spent some time on Earth...</p>\n" +
      "      <p begin=\"714880832t\" end=\"745745000t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle6\">Fought some robots,<br/>\n" +
      "        saved the planet a couple of times.</p>\n" +
      "      <p begin=\"749915832t\" end=\"764930832t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle7\">Then I went searching through the cosmos</p>\n" +
      "      <p begin=\"765765000t\" end=\"796629166t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle8\">for some magic,<br/>\n" +
      "        colorful Infinity Stone things.</p>\n" +
      "      <p begin=\"803719582t\" end=\"817066250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle9\">Didn't find any.</p>\n" +
      "      <p begin=\"829161666t\" end=\"868367499t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle10\">That's when I came across<br/>\n" +
      "        a path of death and destruction,</p>\n" +
      "      <p begin=\"869201666t\" end=\"899648749t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle11\">which led me all the way here<br/>\n" +
      "        into this cage,</p>\n" +
      "      <p begin=\"916332082t\" end=\"929678749t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle12\">where I met you.</p>\n" +
      "      <p begin=\"989738749t\" end=\"1007256249t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle13\">How much longer do you think<br/>\n" +
      "        we'll be here?</p>\n" +
      "      <p begin=\"1148230416t\" end=\"1181597082t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle14\">Thor, son of Odin.</p>\n" +
      "      <p begin=\"1191190000t\" end=\"1224139582t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle15\">Surtur, son of a bitch.</p>\n" +
      "      <p begin=\"1224973750t\" end=\"1235817916t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle16\">You're still alive.</p>\n" +
      "      <p begin=\"1242908332t\" end=\"1280028750t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle17\">I thought my father killed you,<br/>\n" +
      "        like, half a million years ago.</p>\n" +
      "      <p begin=\"1280862916t\" end=\"1298797500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle18\">I cannot die.</p>\n" +
      "      <p begin=\"1299631666t\" end=\"1317983332t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle19\">Not until I fulfill my destiny</p>\n" +
      "      <p begin=\"1318817500t\" end=\"1341340000t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle20\">and lay waste to your home.</p>\n" +
      "      <p begin=\"1342174166t\" end=\"1358857500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle21\">It's funny you should mention that.</p>\n" +
      "      <p begin=\"1359691666t\" end=\"1382631250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle22\">Because I've been having these<br/>\n" +
      "        terrible dreams of late.</p>\n" +
      "      <p begin=\"1384299582t\" end=\"1407656250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle23\">Asgard up in flames, falling to ruins.</p>\n" +
      "      <p begin=\"1408490416t\" end=\"1436435000t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle24\">And you, Surtur,<br/>\n" +
      "        are at the center of all of them.</p>\n" +
      "      <p begin=\"1437269166t\" end=\"1477309166t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle25\">Then you have seen Ragnarok,<br/>\n" +
      "        the fall of Asgard.</p>\n" +
      "      <p begin=\"1478143332t\" end=\"1495243750t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle26\">-The great prophecy...<br/>\n" +
      "        -Hang on.</p>\n" +
      "      <p begin=\"1501082916t\" end=\"1511092916t\" region=\"region.after\" style=\"defaultStyle\"\n" +
      "         xml:id=\"subtitle27\">Hang on.</p>\n" +
      "      <p begin=\"1525273750t\" end=\"1553635416t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle28\">I'll be back around shortly.</p>\n" +
      "      <p begin=\"1554469582t\" end=\"1582831250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle29\">I really feel like<br/>\n" +
      "        we were connecting there.</p>\n" +
      "      <p begin=\"1611192916t\" end=\"1643308332t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle30\">OK, so Ragnarok.<br/>\n" +
      "        Tell me about that. Walk me through it.</p>\n" +
      "      <p begin=\"1644142500t\" end=\"1664996666t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle31\">My time has come.</p>\n" +
      "      <p begin=\"1665830832t\" end=\"1698780416t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle32\">When my crown is reunited<br/>\n" +
      "        with the Eternal Flame,</p>\n" +
      "      <p begin=\"1699614582t\" end=\"1727142082t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle33\">I shall be restored to my full might.</p>\n" +
      "      <p begin=\"1727976249t\" end=\"1751332916t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle34\">I will tower over the mountains,</p>\n" +
      "      <p begin=\"1752167082t\" end=\"1778026249t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle35\">and bury my sword deep in Asgard's...</p>\n" +
      "      <p begin=\"1778860416t\" end=\"1804302499t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle36\">Hang on. Give it a second.</p>\n" +
      "      <p begin=\"1806804999t\" end=\"1836000832t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle37\">I swear, I'm not even moving.<br/>\n" +
      "        It's just doing this on its own.</p>\n" +
      "      <p begin=\"1837669166t\" end=\"1853518332t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle38\">I'm really sorry.</p>\n" +
      "      <p begin=\"1858523332t\" end=\"1879794582t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle39\">OK, so let me get this straight.</p>\n" +
      "      <p begin=\"1880628749t\" end=\"1918583332t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle40\">You're going to put your crown<br/>\n" +
      "        into the Eternal Flame</p>\n" +
      "      <p begin=\"1919417499t\" end=\"1943191249t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle41\">and then you'll suddenly grow as big<br/>\n" +
      "        as a house?</p>\n" +
      "      <p begin=\"1944025416t\" end=\"1956537916t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle42\">A mountain!</p>\n" +
      "      <p begin=\"1957372082t\" end=\"1988653332t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle43\">The Eternal Flame that Odin keeps<br/>\n" +
      "        locked away on Asgard?</p>\n" +
      "      <p begin=\"1989487499t\" end=\"2014095416t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle44\">Odin is not on Asgard.</p>\n" +
      "      <p begin=\"2017432082t\" end=\"2054135416t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle45\">And your absence has left the throne<br/>\n" +
      "        defenseless.</p>\n" +
      "      <p begin=\"2056637916t\" end=\"2077074999t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle46\">OK, so where is it? This crown?</p>\n" +
      "      <p begin=\"2078326249t\" end=\"2106687916t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle47\">This is my crown, the source of my power.</p>\n" +
      "      <p begin=\"2112527082t\" end=\"2146310832t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle48\">Oh, that's a crown.<br/>\n" +
      "        I thought it was a big eyebrow.</p>\n" +
      "      <p begin=\"2147979166t\" end=\"2159657500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle49\">It's a crown.</p>\n" +
      "      <p begin=\"2161325832t\" end=\"2197195000t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle50\">Anyway, it sounds like<br/>\n" +
      "        all I have to do to stop Ragnarok</p>\n" +
      "      <p begin=\"2198029166t\" end=\"2215963750t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle51\">is rip that thing off your head.</p>\n" +
      "      <p begin=\"2227642082t\" end=\"2254752500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle52\">But Ragnarok has already begun.</p>\n" +
      "      <p begin=\"2255586666t\" end=\"2273104166t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle53\">You cannot stop it.</p>\n" +
      "      <p begin=\"2370701666t\" end=\"2402400000t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle54\">I am Asgard's doom, and so are you.</p>\n" +
      "      <p begin=\"2403234166t\" end=\"2439103332t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle55\">All will suffer, all will burn.</p>\n" +
      "      <p begin=\"2440771666t\" end=\"2458706250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle56\">That's intense.</p>\n" +
      "      <p begin=\"2459540416t\" end=\"2478726250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle57\">To be honest, seeing you grow really big</p>\n" +
      "      <p begin=\"2479560416t\" end=\"2504585416t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle58\">and set fire to a planet<br/>\n" +
      "        would be quite the spectacle.</p>\n" +
      "      <p begin=\"2510424582t\" end=\"2535032500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle59\">But it looks like I'm gonna have to<br/>\n" +
      "        choose option B,</p>\n" +
      "      <p begin=\"2535866666t\" end=\"2552967082t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle60\">where I bust out of these chains,</p>\n" +
      "      <p begin=\"2553801250t\" end=\"2592590000t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle61\">knock that tiara off your head,<br/>\n" +
      "        and stash it away in Asgard's vault.</p>\n" +
      "      <p begin=\"2593424166t\" end=\"2623871250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle62\">You cannot stop Ragnarok.</p>\n" +
      "      <p begin=\"2625539582t\" end=\"2636800832t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle63\">Why fight it?</p>\n" +
      "      <p begin=\"2639303332t\" end=\"2673921250t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle64\">Because that's what heroes do.</p>\n" +
      "      <p begin=\"2724388332t\" end=\"2745242500t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle65\">Wait, I'm sorry.<br/>\n" +
      "        I didn't time that right.</p>\n" +
      "      <p begin=\"2763177082t\" end=\"2776523750t\" region=\"region.after\" style=\"defaultStyle\"\n" +
      "         xml:id=\"subtitle66\">And, now!</p>\n" +
      "      <p begin=\"2824071250t\" end=\"2858272082t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle67\">You have made a grave mistake, Odin's son.</p>\n" +
      "      <p begin=\"2860357500t\" end=\"2886633750t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle68\">I make grave mistakes all the time.</p>\n" +
      "      <p begin=\"2904985416t\" end=\"2931678750t\" region=\"region.after\" style=\"defaultStyle\" xml:id=\"subtitle69\">Everything seems to work out.</p>\n" +
      "</div>\n" +
      "  </body>\n" +
      "</tt>\n";

 */
