import {Injectable} from '@angular/core';
import {AbstractOldFirebaseFunctions} from './AbstractOldFirebaseFunctions';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable} from 'rxjs';
import {classToPlain} from 'class-transformer';
import {CaptionRequest} from '../messages/CaptionRequest';


@Injectable({
	providedIn: 'root',
} as any)
export class CaptionFunctions extends AbstractOldFirebaseFunctions<CaptionRequest> {

	public readonly uris = {
		'addCaption': null,
		'updateNetflixMovie': null,
	};

	constructor(protected functions:AngularFireFunctions) {
		super(CaptionRequest, functions, 'titleId');
		this.initUris(this.uris);
	}

	public addCaption(titleId:string, title:string):Observable<CaptionRequest> {
		console.info('using addCaption in CaptionFunctions (client-side) to call the server');
		let message:CaptionRequest = new CaptionRequest();
		message.title = title;
		message.titleId = titleId;
		return this.callDirect(this.ref(this.uris.addCaption), classToPlain<CaptionRequest>(message));
	}

	/*  public updateNetflixMovie(titleId:string):Observable<CaptionRequest> {
		console.info("using updateNetflixMovie in CaptionFunctions (client-side) to call the server");

		  let data:any = {};

		  //currentUser is set via auth
		  let response:Subject<string> = new Subject<string>();

		  (data).subscribe(function(result) {
			  response.next(result);
		  });//TODO: handle failure case



		return this.callDirect(this.ref("updateNetflixMovie"), {titleId: titleId});
	  }*/


}
