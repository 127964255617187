export class DisneyPlusRequestData {
	public detailId:string = "raya-and-the-last-dragon/6dyengbx3iYK";
	public videoId:string = "13edb5c7-6347-48e4-84db-53477c34da62";
	public captionLanguageLabel:string = "English [CC]";

	public title:string = "Raya and the Last Dragon";
	public year:string = "2021";
	public description:string = "Long ago, humans and dragons lived together harmoniously in the world of Kumandra. But when evil threatened the land, the dragons sacrificed themselves to save humanity. Now, 500 years later, lone warrior Raya must track down the legendary last dragon to stop the evil force that has returned…and once again threatens her home world.";
	public premierAccessOnly:boolean = true;
	public srtData:string = "";
}
