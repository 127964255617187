import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import {classToPlain, plainToClass} from "class-transformer";

@Injectable()
export abstract class AbstractCookieRepository<T> {

  protected Type:new () => T;
  protected cookieService:CookieService;

  constructor(Type:new () => T, cookieService:CookieService, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'Strict') {
    this.Type = Type;
    this.cookieService = cookieService;
    //TODO: Path not used
  }

  public set(value:T):void {
    if(value == null) {
      return;
    }
    let jsonObject:object = classToPlain<T>(value);
    let jsonString:string = JSON.stringify(jsonObject);
    this.cookieService.set( this.Type.name, jsonString);
  }

  public get():T {
    let plainJSON:string = this.cookieService.get(this.Type.name);
    if(plainJSON == null || plainJSON == '') {
      return null;
    }
//    console.log(`plainJSON is ${plainJSON} null? ${plainJSON == null}, empty string? ${plainJSON == ''}` );
    let jsonObject:object = JSON.parse(plainJSON);
    let typedClass:T = plainToClass(this.Type, jsonObject);
    return typedClass;
  }

  public delete():void {
    this.cookieService.delete(this.Type.name)
  }
}
