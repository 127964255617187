<app-olelo-jam></app-olelo-jam>
<div class="gap2 gray-bg">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="central-meta banner-header-bg-now">
					<div class="title-block">
						<div class="row">
							<div class="col-2 text-left" >
								<div class="align-left">
									<h5>Now Captioning <span>{{movies.length}}</span></h5>
								</div>
							</div>
							<div class="col-10 m-0 p-0 d-inline-block text-left pl-2">
								<a class="playButton d-block " href="javascript:void(0);" (click)="showIntroModal()">
									<img src="/assets/images/what/mq2.jpg" height="89" width="159"/>
									<fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
									<span class="playButtonText"> What's ʻŌleloflix?</span>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="central-meta">
					<div class="title-block">
						<div class="row">
							<div class="col-6 text-left" >
								<div class="align-content-center">
									<h5>We welcome kumu to contact us for using our platform for their haumāna.</h5>
									<br/><button class="btn btn-dark" (click)="kumuContact()">Get in Touch</button>
								</div>
							</div>
							<div class="col-6 text-left" >
								<div class="align-content-center">
									<h5>If you like what we do; write a statement of support (we're unfunded).</h5>
									<br/><button class="btn btn-dark" (click)="this.statementSupport()">Write a paragraph</button>
								</div>
							</div>
						</div>
					</div>
				</div><!-- title block -->
				<div class="central-meta padding30">

					<div class="row">
						<loading-indicator *ngIf="moviesOrSeries.length === 0 && isLoading" [itemsLoading]="isLoading" listType="resources"></loading-indicator>

						<ng-container *ngFor="let movie of moviesOrSeries | filter:'title':filter | sort:sortType" >
							<div class="movie-undergoing-captioning col-lg-3 col-md-6 col-sm-6" *ngIf="isMovie(movie)" [user]="user" [movie]="movie" [diary]="getDiary(movie.titleId)"></div>
							<div class="series-undergoing-captioning col-lg-3 col-md-6 col-sm-6" *ngIf="isSeries(movie) && (asNetflixSeries(movie).visible)" [series]="movie"></div>
						</ng-container>
						<div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let link of this.dataProvider.movieLinks.data"><app-movie-link [movieLink]="link"></app-movie-link></div>
<!--
						<div class=" col-lg-3 col-md-6 col-sm-6 ng-star-inserted app-add-new-movie-button">
					</div>-->
				</div>
<!--				<div class="central-meta padding30 text-center">
					<div class="row">
						<button class="glow-on-hover" type="button" (click)="this.notificationService.displayModal(AddSlipperModalComponent)">ADD A MOVIE!</button>
						<img src="/assets/olelo-jam/slippers.png">
						<h2>Caption a movie today and get your ʻŌleloflix slippers!</h2>
					</div>
				</div>-->
			</div>
		</div>
	</div>
</div>
</div>
