import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {AngularFirestore, CollectionReference} from '@angular/fire/firestore';
import {CaptionDiary} from '../domain/CaptionDiary';
import {Observable} from 'rxjs';
import {AbstractCompoundFirestoreRepository} from './AbstractCompoundFirestoreRepository';
import {TimedNetflixCaption} from '../domain/database/TimedNetflixCaption';

@Injectable({
	providedIn: 'root',
} as any)
export class CaptionDiaryRepository extends AbstractCompoundFirestoreRepository<CaptionDiary> {

	public by = {

	};
	private _currentMovie:string = null;

	public static get PATH():string {
		return 'Netflix/%1$s/Diaries';
	};


	public watchDiary$():Observable<CaptionDiary> {
		return super.watch$('en-haw');
	}
/*	public updatePartial$(value:{guid:string,[key:string]:any}):Observable<CaptionDiary> {
		return super.updatePartial$(value, this._currentMovie.toString());
	}*/

	constructor(protected db:AngularFirestore) {
		super(CaptionDiary, db, CaptionDiaryRepository.PATH);
	}

	public get currentMovie():string {
		return this._currentMovie;
	}

	public set currentMovie(value:string) {
		this._currentMovie = value;
		while (this.defaultParams.length > 0) {
			this.defaultParams.pop();
		}
		this.defaultParams.push(value);
	}


}
