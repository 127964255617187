import {IsEmail, MinLength} from 'class-validator';

import {DeviceInfo} from 'ngx-device-detector';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {DeviceInformation} from './DeviceInformation';
import {HasThisManyUpperCaseLetters} from '../../../domain/validators/HasThisManyUpperCaseLetters';
import {HasThisManyLowerCaseLetters} from '../../../domain/validators/HasThisManyLowerCaseLetters';
import {HasThisManyNumbers} from '../../../domain/validators/HasThisManyNumbers';

export class LoginAttempt {

	@IsEmail({require_tld: false, allow_ip_domain:true})
	public username:string = "";

	@HasThisManyUpperCaseLetters(1, {message: 'Must contain at least one uppercase letter'})
	@HasThisManyLowerCaseLetters(1, {message: 'Must contain at least one lowercase letter'})
	@HasThisManyNumbers(1, {message: 'Must contain at least one number'})
	@MinLength(8, {message: 'Minimum 8 characters'})
	public password:string = "";

	public timestamp?:Timestamp = Timestamp.now();

	public deviceInformation?:DeviceInformation;

	constructor() {

	}
}
