import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable, Subject} from 'rxjs';
import {CaptionRequest} from '../messages/CaptionRequest';
import {AbstractBasicFirebaseFunctions} from './AbstractBasicFirebaseFunctions';


@Injectable({
	providedIn: 'root',
} as any)
export class BasicCaptionFunctions extends AbstractBasicFirebaseFunctions {

	public readonly uris = {
		'addCaption': null,
		'updateNetflixMovie': null,
	};

	constructor(protected functions:AngularFireFunctions) {
		super(functions);
		this.initUris(this.uris);
	}


	public updateNetflixMovie(titleId:string):void {
		console.info('using updateNetflixMovie in CaptionFunctions (client-side) to call the server');
		this.call('updateNetflixMovie', {titleId: titleId}).subscribe(responseText => {
			console.log('response is ' + responseText);
		});
	}


}
