<div class="tube-post" [attr.enabled]="episode.enabled">
    <figure>
        <figcaption>
            <div class="row p-0 m-0 d-flex movie-buttons">
                <div class="col-6 m-0 p-0">
                    <a class="playButton d-block w-100" href="javascript:void(0);" (click)="watch(episode.movieGuid)">
                        <fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
                        <span class="playButtonText"> Play</span>
                    </a>
                </div>
                <div class="col-6  m-0 p-0">
                    <a class="playButton grey d-block w-100" [routerLink]="['/series',series.guid, 'episodes',episode.movieGuid,0]">
                        <fa-icon [icon]="icons.faClosedCaptioning" transform="shrink-2" [inverse]="false"></fa-icon>
                        <span class="playButtonText"> Caption</span>
                    </a>
                </div>
            </div>
        </figcaption>
        <img [src]="episodeThumbnail" alt="">
<!--
        <div class="save-post" title="Watch Later"><i class="fa fa-clock-o"></i></div>
-->
<!--        <span class="vid-time">4:32</span>
        <em>views: 2.5k</em>-->
<!--        <div class="more">
            <div class="more-post-optns"><i class="ti-more-alt"></i>
                <ul>
                    <li class="send-mesg"><i class="fa fa-comment"></i>Send Message</li>
                    <li class="bad-report"><i class="fa fa-flag"></i>Report Post</li>
                    <li class="share-pst"><i class="fa fa-share-alt"></i>Share</li>
                    <li class="get-link"><i class="fa fa-link"></i>Copy Link</li>
                </ul>
            </div>
        </div>-->
    </figure>
    <div class="tube-title" >
        <h6><a [routerLink]="['/series',series.guid, 'episodes',episode.movieGuid,0]"  title="{{episode.label}}">{{episode.label}}</a></h6>
        <div class="user-fig" *ngIf="episode.creditPlain">
            <!--<img alt="" src="/assets/series/episodes/episode-thumbnail.jpg">-->
            <fa-icon [icon]="icons.faUser" transform="shrink-2" [inverse]="false" class="faUser"></fa-icon>
            <a title="" href="javascript:void(0)">{{episode.creditPlain}}</a>
        </div>
    </div>
</div>
