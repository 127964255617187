import {Observable, Subject, Subscription} from "rxjs";

import {classToPlain, plainToClass} from "class-transformer";
import { map, take} from 'rxjs/operators';
import {AngularFireFunctions} from "@angular/fire/functions";

export abstract class AbstractBasicFirebaseFunctions {
	protected _refs:{ [key:string]:(data:{}) => Observable<any> } = {};
	protected uris = {};

	constructor(protected functions:AngularFireFunctions) {

	}

	protected initUris(uris:{}) {
		this.uris = uris;
		//Instantiate references
		for (let key in this.uris) {
			this.uris[key] = key;
			this._refs[key] = this.functions.httpsCallable(key);
		}
	}

	public httpsCallableRef(functionName:string):(data) => Observable<any> {
		return this.functions.httpsCallable(functionName);
	}

	public ref(uriKey:string):(data) => Observable<any> {
		return this._refs[uriKey];
	}

	public call(refKey:string, message:object, ResponseClass?:new()=>{}):Observable<any> {
		let item$:Subject<any> = new Subject();
		let ref = this.ref(refKey)

		let plain:any = message;
		ref(plain)
			.pipe(
				take(1),
				map(doc => {
					if (ResponseClass != null) {
						//Get document data
						const data = doc;
						const dataAsClass = plainToClass(ResponseClass, data);
						return dataAsClass;
					}
					return doc;
				}))
			.subscribe(item => {
				item$.next(item);
				item$.complete();
			});

		return item$;
	}
}
