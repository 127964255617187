import {Exclude, Transform} from 'class-transformer';
import {Provider} from "../providers/Provider";

export class NetflixMovieRef {
	public guid:string; //titleId
	public title:string = ''; //Thor: Ragnarok
}

/** This is going to replace NetflixMovie when the current NetflixMovie is called something accurate like NetflixMovieLocalization */
export class NetflixMovie {

	public enabled:boolean = true;
	public hasCustomPoster:boolean = false;
	public titleId:string;// = "80186608"  used as a guid
	public title:string = ''; //Thor: Ragnarok
	public processed:boolean = false;
	public provider:Provider = Provider.Netflix;
	public content:string = ''; //timed text xml
	public watchPartyUrl:string = "";
	public isWatchPartyActive:boolean = false;
	public buttons:{
		play:boolean,
		caption:boolean
	}|null = null;

	@Exclude()
	public image:string;

	//** Info Aggregated from Server. Exclude.  Do not overwrite the server value */
	@Exclude({toPlainOnly: true})
	public translatedCaptions:number = 1;

	@Exclude({toPlainOnly: true})
	public totalCaptions:number = 1;

	@Transform((value:number, obj, type) => value == null ? null : new Date(value), {toClassOnly: true})
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, {toPlainOnly: true})
	public date:Date = new Date();

	// STATIC METHODS ---------------------------------------------------------------------
	public static create(processed:boolean, title:string, titleId:string, content:string):NetflixMovie {
		let c:NetflixMovie = new NetflixMovie();
		c.processed = processed;
		c.title = title;
		c.titleId = titleId;
		c.content = content;

		return c;
	}
	// ---------------------------------------------------------------------

	constructor() {

	}

	public percentageAsString():string {
		return Math.floor(this.translatedCaptions / this.totalCaptions) *100 + "%";
	}
}
