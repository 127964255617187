import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AbstractCompoundFirestoreRepository} from './AbstractCompoundFirestoreRepository';
import {GroupedCaptionSet} from "../domain/GroupedCaptionSet";
import {WordBankGroupedCaptionSet} from "../domain/bank/WordBankGroupedCaptionSet";
import {Observable, Subject} from "rxjs";
import {ConversionService} from "../services/ConversionService";

@Injectable({
	providedIn: 'root',
} as any)
export class MovieWordBankRepository extends AbstractCompoundFirestoreRepository<WordBankGroupedCaptionSet> {

	private _currentMovie:string = null;

	public static get PATH():string {
		return 'Netflix/%1$s/WordBanks';
	};

	constructor(protected db:AngularFirestore, protected conversionService:ConversionService) {
		super(WordBankGroupedCaptionSet, db, MovieWordBankRepository.PATH);
	}
	public get currentMovie():string {
		return this._currentMovie;
	}

	public set currentMovie(value:string) {
		this._currentMovie = value;
		while (this.defaultParams.length > 0) {
			this.defaultParams.pop();
		}
		this.defaultParams.push(value.toString());
	}
	public convertAndSave$(wordBank:GroupedCaptionSet):Observable<WordBankGroupedCaptionSet> {
		return this.saveOrUpdate$(this.conversionService.convert(wordBank, WordBankGroupedCaptionSet));
	}
	public saveOrUpdate$(wordBank:WordBankGroupedCaptionSet):Observable<WordBankGroupedCaptionSet> {
		let subject: Subject<WordBankGroupedCaptionSet> = new Subject<WordBankGroupedCaptionSet>();
		if (wordBank.guid == null) {
			console.error("Must generate guid before calling MovieWordBankRepository.saveOrUpdate");
			subject.error("Must generate guid before calling MovieWordBankRepository.saveOrUpdate");
			return subject;
		};

		this.get$(wordBank.guid).subscribe(wordBankResult => {
			if (wordBankResult == null) {
				//Didnʻt already exist.
				console.log(`Didn't already exist.  Saving.`)
				let j = this.save$(wordBank).subscribe(nestedWordBankResult => {
					j.unsubscribe();
					subject.next(nestedWordBankResult);
					subject.complete();
				});
			} else {
				//It existed already...
				console.log("it already existed")
				if (wordBankResult.suggestions.indexOf(wordBank.set2Content) == -1) {
					//Suggestion did not already exist
					console.log("it existed but the specific suggestion did not.")
					wordBankResult.suggestions.push(wordBank.set2Content);
					let k = this.save$(wordBankResult).subscribe(nestedWordBankResult => {
						console.log("suggestion saved.");
						k.unsubscribe();
						subject.next(nestedWordBankResult);
						subject.complete();
					});
				} else {
					//Suggestion already existed
					subject.next(wordBankResult);
				}

			}
		});
		return subject;
	}
}
