import {Component, OnDestroy, OnInit} from '@angular/core';
import {AudioRecordingService} from '../../../services/AudioRecordingService';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit, OnDestroy {


	public isRecording = false;
	public recordedTime;
	public blobUrl;


	constructor(private audioRecordingService:AudioRecordingService, private sanitizer: DomSanitizer) {

		this.audioRecordingService.recordingFailed().subscribe(() => {
			this.isRecording = false;
		});

		this.audioRecordingService.getRecordedTime().subscribe((time) => {
			this.recordedTime = time;
		});

		this.audioRecordingService.getRecordedBlob().subscribe((data) => {
			this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
		});
	}

	ngOnDestroy(): void {
		this.abortRecording();
	}

	ngOnInit():void {
	}

	startRecording() {
		if (!this.isRecording) {
			this.isRecording = true;
			this.audioRecordingService.startRecording();
		}
	}

	abortRecording() {
		if (this.isRecording) {
			this.isRecording = false;
			this.audioRecordingService.abortRecording();
		}
	}

	stopRecording() {
		if (this.isRecording) {
			this.audioRecordingService.stopRecording();
			this.isRecording = false;
		}
	}

	clearRecordedData() {
		this.blobUrl = null;
	}


}
