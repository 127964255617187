<div id="flashcard-collection" class="nav-container mx-auto">
    <h4><fa-icon [icon]="icons.faHeart" [inverse]="false"></fa-icon> Flashcards</h4>
    <span *ngIf="showEmailInput == false">{{dataProvider.userEmail}} <a (click)="showEmailInput = true"> (change email)</a></span>
    <input [(ngModel)]="this.emailInput" *ngIf="showEmailInput"/><button *ngIf="showEmailInput" (click)="saveEmail()">Change Email</button>

    <div class="container-fluid" *ngIf="dataProvider.userEmail">
        <app-list-flashcard-collections (addClick)="showCreateModal()" [filter]="dataProvider.userEmail"></app-list-flashcard-collections>
    </div>
    <ng-container>
        <h1>Add Flashcard</h1>
        <p>Please colorize the line before adding a fLashcard</p>
    </ng-container>

</div>

