import {Type} from "class-transformer";
import {Token} from "./Token";
import {OleloflixUser} from '../users/OleloflixUser';


export class CompleteUserRegistration {

	@Type(()=>Token)
	public token:Token;

	@Type(()=>OleloflixUser)
	public user:OleloflixUser;

}
