import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IframeEvent} from '@sebgroup/ng-magic-iframe/lib/interfaces/iframe-event';
import {RemoteConfigurationRepository} from '../repository/RemoteConfigurationRepository';
import {SubscriptionCleaner} from '../util/SubscriptionCleaner';
import {ZoomSettings} from '../domain/config/ZoomSettings';
import {Subscription} from 'rxjs';
import {UrlService} from '../services/UrlService';
import {
	faDesktop,
	faGripVertical,
	faGripLinesVertical,
	faGripHorizontal,
	faGripLines,
	faWindowRestore,
	faWindowMinimize,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {PlayerOptions} from 'yt-player-angular';
import {YoutubeSettings} from '../domain/config/YoutubeSettings';
import {WatchPartyExtensionRequiredModalComponent} from '../modals/watch-party-extension-required-modal/watch-party-extension-required-modal.component';
import {OleloJamRegistrationModalComponent} from '../modals/olelo-jam-registration-modal/olelo-jam-registration-modal.component';
import {NotificationService} from '../services/NotificationService';
import {RemoteConfiguration} from "../domain/config/RemoteConfiguration";
import {UpcomingEvent} from "../domain/database/UpcomingEvent";
import {UpcomingEventRepository} from "../repository/UpcomingEventRepository";
@Component({
	selector: 'app-olelo-jam',
	templateUrl: './olelo-jam.component.html',
	styleUrls: ['./olelo-jam.component.scss'],

	encapsulation: ViewEncapsulation.None,
/*	changeDetection: ChangeDetectionStrategy.OnPush*/
})
export class OleloJamComponent extends SubscriptionCleaner implements OnInit {

	public settings:ZoomSettings;
	public windowFocus;
	public event:UpcomingEvent = new UpcomingEvent();
	public frame = {
		translate: [0, 0],
	};

	private _size:Size = Size.large;

	private participationOption:ParticipationOption = ParticipationOption.participateActively;
	public icons = {
		faDesktop: faDesktop,
		faGrip: faGripHorizontal,
		faGripLines: faGripLines,
		faGripLinesVertical: faGripLinesVertical,
		faGripVertical: faGripVertical,
		faWindowMinimize: faWindowMinimize,
		faWindowRestore: faWindowRestore
	};

	public playerOptions:PlayerOptions = {
		width: 300,
		height: 200,
		autoplay: true,
		controls: false,
		keyboard: false,
		fullscreen: true,
		annotations: false,
		modestBranding: true,
		related: false,
		info: false,
		timeupdateFrequency: 500,
		playsInline: true
	};
	public youtube:YoutubeSettings;
	public videoId:string = '';

	public remoteConfig:RemoteConfiguration;

	private hasAlreadyShowedRegistrationOnce:boolean = false;

	constructor(protected remoteConfigurationRepository:RemoteConfigurationRepository,
				protected urlService:UrlService,
				protected upcomingEventRepository:UpcomingEventRepository,
				protected notificationService:NotificationService) {
		super();
		let s:Subscription = super.trackSubscription(remoteConfigurationRepository.get$().subscribe(remoteConfig => {
			//remoteConfig.isOleloJamActive = true; //TODO: Remove before deploying...
			/*remoteConfig.showOleloJamRegistration = true; //TODO: Remove before deploying...*/
			//remoteConfig.zoom.isActive = true;  //TODO: Remove before deploying...
			//remoteConfig.showOleloJamCountdown = true;
			this.remoteConfig = remoteConfig;
			this.settings = remoteConfig.zoom;
			this.youtube = remoteConfig.youtube;
			this.playerOptions = remoteConfig.youtube.playerOptions;
			this.videoId = remoteConfig.youtube.videoId;

			if(!this.hasAlreadyShowedRegistrationOnce && remoteConfig.showOleloJamRegistration == true) {
				this.hasAlreadyShowedRegistrationOnce = true;
				this.notificationService.displayModal(OleloJamRegistrationModalComponent, this);
			}
		}));

/*		let s2:Subscription = this.trackSubscription(this.upcomingEventRepository.nextEvent$().subscribe(upcomingEvent => {
				//TODO: Determine which on to show (check duration for back to back events...)..or somehow keep a property..
				s2.unsubscribe();
			//this belongs in a sevice
				this.event = upcomingEvent;
		}));*/

		//this.event.thumbnail = this.urlService.site.oleloJam.url("70116061");
	}

	ngOnInit() {
/*		let s2:Subscription = this.trackSubscription(this.upcomingEventRepository.nextEvent$().subscribe(upcomingEvent => {
			//TODO: Determine which on to show (check duration for back to back events...)..or somehow keep a property..
			s2.unsubscribe();
			//this belongs in a sevice
			this.event = upcomingEvent;
		}));*/

		//this.event.thumbnail = this.urlService.site.oleloJam.url("70116061");
	}

	onIframeEvent($event:IframeEvent) {
		console.log($event);
	}
	private newWindow():void {
		if(this.hasOleloJamWindow) {
			this.focus();
		} else {
			if(this.participationOption == ParticipationOption.watchAndListen) {
				this.windowFocus = window.open(this.youtubeSource, 'jam', 'width=600,height=350');
				focus();
			} else if(this.participationOption == ParticipationOption.participateActively) {
				this.windowFocus = window.open(this.source, 'jam', 'width=800,height=550');
				focus();
			}
		}

			//some other code

	}

	public get hasOleloJamWindow():boolean {
		return this.windowFocus && window.focus && !this.windowFocus.closed;
	}
	public focus(): void {
		if (this.windowFocus && window.focus) {
			this.windowFocus.focus();
		}

		if (!this.windowFocus.closed) {
			this.windowFocus.focus();
		}
	}
	public get Size() {
		return Size;
	}
	public get ParticipationOption() {
		return ParticipationOption;
	}


	public get size():Size {
		return this._size;
	}

	public set size(value:Size) {
		this._size = value;

	}
	public get source():string {
		//return `https://us04web.zoom.us/wc/${this.settings.meetingNumber}/start`;
		return this.settings.zoomLink;
	}

	public get youtubeSource():string {
		return `https://www.youtube.com/watch?v=${this.youtube.videoId}`;
	}

	onDragStart({ set }) {
		set(this.frame.translate);
	}
	onDrag({ target, beforeTranslate }) {
		this.frame.translate = beforeTranslate;
		target.style.transform
			= `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
	}
	onDragEnd({ target, isDrag, clientX, clientY }) {
		console.log("onDragEnd", target, isDrag);
	}
	onResizeStart({ target, set, setOrigin, dragStart }) {
		// Set origin if transform-orgin use %.
		setOrigin(["%", "%"]);

		// If cssSize and offsetSize are different, set cssSize. (no box-sizing)
		const style = window.getComputedStyle(target);
		const cssWidth = parseFloat(style.width);
		const cssHeight = parseFloat(style.height);
		set([cssWidth, cssHeight]);

		// If a drag event has already occurred, there is no dragStart.
		// tslint:disable-next-line:no-unused-expression
		dragStart && dragStart.set(this.frame.translate);
	}
	onResize({ target, width, height, drag }) {
		target.style.width = `${width}px`;
		target.style.height = `${height}px`;

		// get drag event
		this.frame.translate = drag.beforeTranslate;
		target.style.transform
			= `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;
	}
	onResizeEnd({ target, isDrag, clientX, clientY }) {
		console.log("onResizeEnd", target, isDrag);
	}

    public launchZoom() {
        //window.location.href = this.settings.zoomLink;
		this.participationOption = ParticipationOption.participateActively;
		this.newWindow();
    }
}
export enum Size {
	minimize='minimize',
	small='small',
	medium='medium',
	large='large'
}
export enum ParticipationOption {
	unset='unset',
	watchAndListen='watchAndListen',
	participateActively='participateActively',
}
