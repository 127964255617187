import {GroupedCaptionSet} from "../GroupedCaptionSet";
import {Exclude} from "class-transformer";
import {Nupepafy} from "../../util/Nupepafy";

export class WordBankGroupedCaptionSet extends GroupedCaptionSet {

    @Exclude({toPlainOnly: true})
    public guid:string = null;
    public locked:boolean = false
    public word:string = null;
    public suggestions:Array<string> = []
    public chosenSuggestion:string = null;

    public get suggestionsLabel():string {
        if(this.suggestions.length > 0) {
            return `${this.set2Content} and ${this.suggestions.length} others.`
        }
        return `${this.set2Content}`;
    }
    public generateGuid():void {
        if(this.guid == null) {
            this.guid = Nupepafy.generateGuidFromSentence(this.set1Content);
        }
    }

}

