import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {CollectionVisibilityType, FlashcardCollection} from "../../../domain/flashcard/FlashcardCollection";
import {FlashcardCollectionRepository} from "../../../repository/FlashcardCollectionRepository";

export interface IFlashCardCollectionModalInputProperties {
  collection?:FlashcardCollection,
  mode?:FlashCardCollectionModalComponentMode
}

@Component({
  selector: 'app-flash-card-collection-add-edit-modal',
  templateUrl: './flash-card-collection-add-edit-modal.component.html',
  styleUrls: ['./flash-card-collection-add-edit-modal.component.scss']
})
export class FlashCardCollectionAddEditModalComponent implements OnInit {

  public component:any;
  public content:IFlashCardCollectionModalInputProperties;

  public flashcardCollection:FlashcardCollection;
  public mode:FlashCardCollectionModalComponentMode = FlashCardCollectionModalComponentMode.View;

  public isValidInput:boolean = true;
  public wantsToDelete:boolean = false;
  public isSure:boolean = false;
  
  constructor(public bsModalRef:BsModalRef, public router:Router, public flashcardCollectionRepository:FlashcardCollectionRepository) {
    
  }

  ngOnInit(): void {
    
  }

  public onProperties(properties:IFlashCardCollectionModalInputProperties):void {
    this.content = properties;
    this.flashcardCollection = properties.collection;
    if(this.flashcardCollection == null && this.content.mode == FlashCardCollectionModalComponentMode.Create) {
      this.flashcardCollection = new FlashcardCollection();
    }
    if(this.content.mode) {
      this.mode = this.content.mode;
    }
  }
  public get FlashCardCollectionModalComponentMode() {
    return FlashCardCollectionModalComponentMode;
  }
  public dismiss() {
    this.bsModalRef.hide();
  }

  public create():void {
    console.log(`This would add a suggestion`);

    this.flashcardCollectionRepository.save$(this.flashcardCollection);

    console.log(JSON.stringify(this.flashcardCollection));
  }

  public delete():void {
    this.flashcardCollectionRepository.delete$(this.flashcardCollection.guid);
  }

  public toOwnerLabel():string {
    return CollectionVisibilityType.privateToUser ? "Visible only to yourself" :
        CollectionVisibilityType.privateToMovie ? "Visible to yourself and others within the movie" :
            CollectionVisibilityType.public ? "Visible to Public" : "";
  }
}


export enum FlashCardCollectionModalComponentMode {
  Create="Create",
  Edit="Edit",
  View="View"
}
