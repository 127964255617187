import {Converter} from "../ConversionService";
import {TimedTextCaption} from '../../domain/timedtext/TimedText';
import {TimedNetflixCaption} from '../../domain/database/TimedNetflixCaption';
import {GroupedCaptionSet} from '../../domain/GroupedCaptionSet';


//TODO: This is probably no longer relevant
export class TimedTextCaptionToTimedNetflixCaptionConverter implements Converter {

  public from:typeof TimedTextCaption;
  public to:typeof TimedNetflixCaption;

  constructor() {
    this.from = TimedTextCaption;
    this.to = TimedNetflixCaption;
  }

  public convert(source:TimedTextCaption):TimedNetflixCaption {

    //Create the target object
    let timedNetflixCaption:TimedNetflixCaption = new TimedNetflixCaption();
    timedNetflixCaption.guid = source.id;//TODO: this MAY be untrustworthy (need to examine first ... see server side script, but for thor it is okay */
    timedNetflixCaption.begin = source.begin;
    timedNetflixCaption.end = source.end;
    timedNetflixCaption.originalContent = source.content;
    timedNetflixCaption.groupedCaptionSet = GroupedCaptionSet.create(timedNetflixCaption.originalContent, "");


    return timedNetflixCaption;
  }
}
