export class Token {
	public accessToken: string;
	public idToken: string;
	public expiresAt: number;

	/**  Check whether the current time is past the access token's expiry time */
	public isAuthenticated():boolean {
		return this.accessToken && (Date.now() < this.expiresAt);
	}
}
