import {Component, Input, OnInit} from '@angular/core';
import {faHeart,} from "@fortawesome/free-solid-svg-icons";
import {
    FlashCardCollectionAddEditModalComponent,
    FlashCardCollectionModalComponentMode,
    IFlashCardCollectionModalInputProperties
} from "../flash-card-collection-add-edit-modal/flash-card-collection-add-edit-modal.component";
import {NotificationService} from "../../../services/NotificationService";
import {User} from "../../../domain/User";
import {UserRepository} from "../../../repository/UserRepository";
import {GroupedCaptionSet} from "../../../domain/GroupedCaptionSet";
import {isEmail} from "class-validator";

@Component({
    selector: 'app-flashcard-collection-section',
    templateUrl: './flashcard-collection-section.component.html',
    styleUrls: ['./flashcard-collection-section.component.scss']
})
export class FlashcardCollectionSectionComponent implements OnInit {

    @Input()
    public groupedCaptionSet:GroupedCaptionSet;

    public icons = {
        faHeart: faHeart,
    };
    public dataProvider = {
        selected: null,
        list: {
            ready: false,
            data: []
        },
        userEmail: null,
    }
    public showEmailInput:boolean = false;
    public emailInput:string = "";


    constructor(protected notificationService:NotificationService, protected userRepository:UserRepository  ) {
        if(this.userRepository.get()) {
            this.dataProvider.userEmail = this.userRepository.get().email;
            this.showEmailInput = this.userRepository.get().email == null;

        } else {
            this.showEmailInput = true;
        }
    }
    public showCreateModal():void {
        console.log("clicked on create modal");
        this.notificationService.displayModal<IFlashCardCollectionModalInputProperties>(FlashCardCollectionAddEditModalComponent, this, {
            collection: null,
            mode: FlashCardCollectionModalComponentMode.Create
        });

    }

    ngOnInit(): void {

    }

    public addFlashcardToCollection() {
        console.log(`Would have added ${this.groupedCaptionSet.set1Content}/${this.groupedCaptionSet.set2Content} to caption set`);
    }

    saveEmail() {
        if(isEmail(this.emailInput, {allow_display_name: true, allow_ip_domain: true, allow_utf8_local_part: true, require_display_name: false, require_tld: false, ignore_max_length: false} )) {
            this.dataProvider.userEmail = this.emailInput;
            let user:User = this.userRepository.get();
            user.email = this.emailInput;
            this.userRepository.set(user);

            this.emailInput = "";
            this.showEmailInput = false;
        } else {
            console.log("invalid email");
        }
    }
}
