import * as _ from "lodash";

export class Nupepafy {
    public static makeGuidExtension():string {
        let text:string = "";
        let possible:string = "abcdefghijklmnopqrstuvwxyz0123456789";

        for (let i:number = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return "_" + text;
    }

    public static nupepafy(input:string):string {
        input = input.replace(new RegExp(_.escapeRegExp("Ā"), "g"), "A");
        input = input.replace(new RegExp(_.escapeRegExp("Ē"), "g"), "E");
        input = input.replace(new RegExp(_.escapeRegExp("Ī"), "g"), "I");
        input = input.replace(new RegExp(_.escapeRegExp("Ō"), "g"), "O");
        input = input.replace(new RegExp(_.escapeRegExp("Ū"), "g"), "U");
        input = input.replace(new RegExp(_.escapeRegExp("ā"), "g"), "a");
        input = input.replace(new RegExp(_.escapeRegExp("ē"), "g"), "e");
        input = input.replace(new RegExp(_.escapeRegExp("ī"), "g"), "i");
        input = input.replace(new RegExp(_.escapeRegExp("ō"), "g"), "o");
        input = input.replace(new RegExp(_.escapeRegExp("ū"), "g"), "u");
        input = input.replace(new RegExp(_.escapeRegExp("ʻ"), "g"), "");
        input = input.replace(new RegExp(_.escapeRegExp("'"), "g"), "");
        input = input.replace(new RegExp(_.escapeRegExp("-"), "g"), "");

        return input;
    }

    /** Get a generated user Id based on a name */
    public static generateGuidFromTwoParts(partOne:string, partTwo:string):string {
        //replace Hawaiian
        let partUno:string = this.nupepafy(partOne);
        let partDos:string =  this.nupepafy(partTwo);
        let firstAndSecond:string = ("" +partUno.trim() + "-" + partDos.trim()).toLowerCase();
        let alpha:RegExp = new RegExp("[^a-z\-]","g");
        let justLowerAlpha = _.replace(firstAndSecond, alpha, "");

        return justLowerAlpha;
    }

    public static generateGuidFromSentence(input:string):string {
        let parts:Array<string> = input.split(" ");
        let resultParts:Array<string> = [];
        for (let part of parts) {
            resultParts.push(Nupepafy.generatePart(part));
        }
        return resultParts.join("-");
    }
    public static generatePart(part:string):string {
        let replaced:string = this.nupepafy(part).toLowerCase();
        let alpha:RegExp = new RegExp("[^a-z\-]","g");
        let justLowerAlpha = _.replace(replaced, alpha, "");
        return justLowerAlpha;
    }
}
