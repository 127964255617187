import {Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent {
  @Input() heading: string = "";
  @Input() usedColors:Array<string> = [];
  @Input() color: string = "#3e6158";
  @Output() event: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public show = false;
  public defaultColors: string[] = [
//    '#ffffff',
    '#000000',
    '#3e6158',
    '#3f7a89',
    '#96c582',
    '#b7d5c4',
    '#bcd6e7',
    '#7c90c1',
    '#9d8594',
    '#dad0d8',
    '#4b4fce',
    '#4e0a77',
    '#a367b5',
    '#ee3e6d',
    '#d63d62',
    '#c6a670',
    '#f46600',
    '#cf0500',
    '#efabbd',
    '#8e0622',
    '#f0b89a',
    '#f0ca68',
    '#62382f',
    '#c97545',
    '#c1800b'
  ];

  constructor() {
  }

  /**
   * Change color from default colors
   * @param {string} color
   */
  public changeColor(color: string): void {
    this.color = color;
    this.event.emit(this.color);
    //this.show = false;
    this.show = true;
  }

  public isAUsedColor(color:string):boolean {
    const beingUsed:boolean = this.usedColors.indexOf(color) != -1;
    //console.log(`${color} beingUsed? ${beingUsed}`);
    return beingUsed;
  }

  /**
   * Change color from input
   * @param {string} color
   */
  public changeColorManual(color: string): void {
    const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

    if (isValid) {
      this.color = color;
      this.event.emit(this.color);
    }
  }

  /**
   * Change status of visibility to color picker
   */
  public toggleColors(): void {
    //this.show = !this.show;
  }

  public nextColor():void {
    const currentColorIndex:number = this.defaultColors.indexOf(this.color);
    let newIndex:number = currentColorIndex + 1;
    if(currentColorIndex + 1 >= this.defaultColors.length) {
      newIndex = 0;
    }
    const colorAtIndex:string = this.defaultColors[newIndex];
    console.log(`nextColor::currentColorIndex: ${currentColorIndex}, newIndex: ${newIndex}, colorAtIndex: ${colorAtIndex}`);
    this.changeColor(colorAtIndex);
  }

  public previousColor():void {
    const currentColorIndex:number = this.defaultColors.indexOf(this.color);

    let newIndex:number = currentColorIndex - 1;
    if(currentColorIndex - 1 < 0) {
      newIndex = this.defaultColors.length-1;
    }
    const colorAtIndex:string = this.defaultColors[newIndex];
    console.log(`previousColor::currentColorIndex: ${currentColorIndex}, newIndex: ${newIndex}, colorAtIndex: ${colorAtIndex}`);
    this.changeColor(colorAtIndex);

  }
  @HostListener('document:keyup', ['$event'])
  public onKeyboardEvent(event:KeyboardEvent): void {
    if (event.key == "[" ) {
      this.previousColor();
    } else if (event.key == "]") {
      this.nextColor();
    }
  }
}
