import {UserRef} from '../UserRef';
import {Transform, Type} from 'class-transformer';

export class CaptionStats {
	public work:CaptionStatsWork;
	public review:CaptionStatsReview;

	constructor() {
		this.work = new CaptionStatsWork();
		this.review = new CaptionStatsReview();
	}
}

export class CaptionStatsWork {
	public captions:Array<UserRef> = [];
	public colors:Array<UserRef> = [];
	public audio:Array<UserRef> = [];
}
export class CaptionStatsReview {
	public caption:CaptionStatsReviewItem = new CaptionStatsReviewItem();
	public color:CaptionStatsReviewItem = new CaptionStatsReviewItem();
	public audio:CaptionStatsReviewItem = new CaptionStatsReviewItem();
}

export class CaptionStatsReviewItem {
	public approved:boolean = false;
	@Type(() => UserRef)
	public reviewer:UserRef;
	@Transform((value:number, obj, type) => value == null ? null : new Date(value), {toClassOnly: true})
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, {toPlainOnly: true})
	public date:Date = new Date();
}
export class CaptionStatsWorkItem {
	@Type(() => UserRef)
	public user:UserRef;
	@Transform((value:number, obj, type) => value == null ? null : new Date(value), {toClassOnly: true})
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, {toPlainOnly: true})
	public date:Date = null;
}
