import {Exclude, Type} from 'class-transformer';
import {GroupedCaptionSet} from '../GroupedCaptionSet';
import {UserRef} from '../UserRef';
import {CaptionStats} from './CaptionStats';

export class TimedNetflixCaption {
	public guid:string = ""; //subtitle1 or other
	public begin:string = "";
	public end:string = "";
	public index:number = -1; //-1 means not yet set

	public originalContent:string;

	@Type(()=> GroupedCaptionSet)
	public groupedCaptionSet:GroupedCaptionSet;

	@Type(() => CaptionStats)
	public stats:CaptionStats = new CaptionStats();

	public flagged:boolean = false;
	public captioners:Array<UserRef> = [];


	public get hasColors():boolean {
	    return this.groupedCaptionSet.usedColors.length > 0;
    }

  public get beginTime():number {
    const tickRate = 10000000;
    if(this.begin && this.begin.length > 0) {
      return Number(this.begin.substring(0, this.begin.length - 1)) / tickRate;

    }

    return -1;
  }
  public get endTime():number {
    const tickRate = 10000000;
    if(this.end && this.end.length > 0) {
      return Number(this.end.substring(0, this.end.length - 1)) / tickRate;
    }
    return -1;
  }

}
