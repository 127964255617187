import {Component, OnInit} from '@angular/core';
import {DisneyPlusRequestData} from "../../../services/functions/messages/disneyplus/DisneyPlusRequestData";
import {validateSync, ValidationError} from "class-validator";
import {OleloflixFunctions} from "../../../services/functions/OleloflixFunctions";
import {ServerError} from "../../../services/functions/standard/AbstractFirebaseFunction";
import {SimpleResponse} from "../../../core/responses/SimpleResponse";
import {BsModalRef} from "ngx-bootstrap/modal";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";


export interface IAddDisneyPlusMovieModalInputProperties {
    dataProvider?:DisneyPlusRequestData,
}

@Component({
    selector: 'app-add-disney-plus-movie-modal',
    templateUrl: './add-disney-plus-movie-modal.component.html',
    styleUrls: ['./add-disney-plus-movie-modal.component.scss']
})
export class AddDisneyPlusMovieModalComponent extends SubscriptionCleaner implements OnInit {

    public dataProvider:DisneyPlusRequestData;
    public successful:boolean = false

    constructor(protected oleloflixFunctions:OleloflixFunctions, public bsModalRef:BsModalRef) {
        super();
    }


    public onProperties(properties:IAddDisneyPlusMovieModalInputProperties):void {
        console.log("onProperties! " + JSON.stringify(properties));
        if(properties) {
            this.dataProvider = properties.dataProvider;
        } else {
            this.dataProvider = new DisneyPlusRequestData();
        }
    }

    ngOnInit(): void {
        console.log("ngOnInit!");
    }

    validateAndSubmit() {
        let errors:Array<ValidationError> = validateSync(this.dataProvider);
        if(errors.length == 0) {
            this.trackSubscription(this.oleloflixFunctions.addCaptionFromSrt$(this.dataProvider).subscribe(response => {
                if (response instanceof ServerError) {
                    this.successful = false;
                } else {
                    response = response as SimpleResponse;
                    if(response.success) {
                        this.successful = true;
                        this.bsModalRef.hide();
                    }
                }
            }));
        }
    }
}
