import 'reflect-metadata';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {environment} from '../environments/environment';
import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {ColorPickerComponent} from '../assets/color-picker/color-picker.component';
import {SelectableCaptionWordComponent} from './selectable-caption-word/selectable-caption-word.component';
import {SelectableCaptionSetComponent} from './selectable-caption-set/selectable-caption-set.component';
import {NetflixStubData} from './data/NetflixStubData';
import {EditCaptionSetComponent} from './edit-caption-set/edit-caption-set.component';
import {PostMessageService} from './services/PostMessageService';
import {AngularFireModule} from '@angular/fire';
import {CaptionFunctions} from './services/functions/old/CaptionFunctions';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {ProvisioningService} from './services/ProvisioningService';
import {ConversionService} from './services/ConversionService';
import {TimedNetflixCaptionRepository} from './repository/TimedNetflixCaptionRepository';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {library} from '@fortawesome/fontawesome-svg-core';
import {faEdit, faStar} from '@fortawesome/free-solid-svg-icons';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {TranslateService} from './services/TranslateService';
import {HttpClientModule} from '@angular/common/http';
import {UserStatusComponent} from '../assets/user-status/user-status.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {CaptioningComponent} from '../areas/captioning/captioning.component';
/*import {AnQrcodeModule} from 'an-qrcode';*/
import {StartCaptioningComponent} from '../areas/start-captioning/start-captioning.component';
import {CookieService} from 'ngx-cookie-service';
import {UserRepository} from './repository/UserRepository';
import {NetflixMovieService} from './services/NetflixMovieService';
import {AudioRecordItemComponent} from './areas/record/audio-record-item/audio-record-item.component';
import {AudioRecordingListComponent} from './areas/record/audio-recording-list/audio-recording-list.component';
import {RecordComponent} from './areas/record/record/record.component';
import {AudioRecordingService} from './services/AudioRecordingService';
import {MovieUndergoingCaptioningComponent} from '../areas/movie-undergoing-captioning/movie-undergoing-captioning.component';
import {ArrayFilterPipe} from './filters/ArrayFilterPipe';
import {SortPipe} from './filters/SortPipe';
import {HeaderComponent} from '../areas/header/header.component';
import {UserSidebarComponent} from '../areas/user-sidebar/user-sidebar.component';

import {ToasterModule, ToasterService} from 'angular2-toaster';
import {NotificationService} from './services/NotificationService';
import {AddSlipperModalComponent} from '../areas/modals/add-slipper-modal/add-slipper-modal.component';
import {CaptionSocialComponent} from '../areas/caption-social/caption-social.component';
import {CaptionCommentComponent} from '../areas/caption-comment/caption-comment.component';
import {CaptionCommentRepository} from './repository/CaptionCommentRepository';
import {OleloflixSplashComponent} from '../areas/oleloflix-splash/oleloflix-splash.component';
import {RouterModule} from '@angular/router';
import {GridTimelineComponent} from '../areas/grid-timeline/grid-timeline.component';
import {GridTimelineBlockComponent} from '../areas/grid-timeline-block/grid-timeline-block.component';
import {SvgrectModule} from 'angular-svg';
import {CaptionDiaryRepository} from './repository/CaptionDiaryRepository';
import {CaptionDiaryService} from './services/CaptionDiaryService';
import {NetflixWatchService} from './services/NetflixWatchService';
import {
	OleloEntryControlsComponent,
	TrackCapsDirective,
	TrackShiftDirective
} from '../areas/common/olelo-entry-controls/olelo-entry-controls.component';
import {OleloEntryButtonComponent} from '../areas/common/olelo-entry-button/olelo-entry-button.component';
import { YtPlayerAngularModule } from 'yt-player-angular';
import {RemoteConfigurationRepository} from './repository/RemoteConfigurationRepository';
import { NgMagicIframeModule } from '@sebgroup/ng-magic-iframe';
import { SiteComponent } from './site/site.component';
import { OleloJamComponent } from './olelo-jam/olelo-jam.component';
import {UpcomingEventComponent} from './upcoming-event/upcoming-event.component';
import {NgxMoveableModule} from 'ngx-moveable';
import {FooterComponent} from './components/footer/footer.component';
import {WatchPartyExtensionRequiredModalComponent} from './modals/watch-party-extension-required-modal/watch-party-extension-required-modal.component';
import {OleloJamRegistrationModalComponent} from './modals/olelo-jam-registration-modal/olelo-jam-registration-modal.component';
import {RegisterComponent} from '../areas/auth/register/register.component';
import {LoginComponent} from '../areas/auth/login/login.component';
import {ForgotPasswordComponent} from '../areas/auth/forgot-password/forgot-password.component';
import {AuthComponent} from '../areas/auth/auth.component';
import {AuthService} from './services/AuthService';
import {AutofocusDirective} from './directives/AutoFocusDirective';
import {ToggleDirective} from './directives/ToggleDirective';
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {ValidationService} from './services/ValidationService';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {CallbackComponent} from './areas/auth/callback/callback.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ListPatronsComponent} from "../areas/patrons/list-patrons/list-patrons.component";
import {PatronRepository} from "./repository/patrons/PatronRepository";
import {PledgeRepository} from "./repository/patrons/PledgeRepository";
import {NetflixSeriesService} from "./services/NetflixSeriesService";
import {NetflixSeriesRepository} from "./repository/NetflixSeriesRepository";
import {SeriesUndergoingCaptioningComponent} from "../areas/series-undergoing-captioning/series-undergoing-captioning.component";
import {ListEpisodesComponent} from "../areas/series/list-episodes/list-episodes.component";
import {EpisodeComponent} from "../areas/series/episode/episode.component";
import {PatronInListComponent} from "../areas/patrons/patron-in-list/patron-in-list.component";
import {AuthenticationModalComponent} from "./modals/authentication-modal/authentication-modal.component";
import {AddNewMovieButtonComponent} from "../areas/catalog/add-new-movie-button/add-new-movie-button.component";
import {OleloflixIntroModalComponent} from "./modals/oleloflix-intro-modal-component/oleloflix-intro-modal.component";
import {ChromeExtensionRequiredModalComponent} from "./modals/chrome-extension-required-modal/chrome-extension-required-modal.component";
import {WordBankEditModalComponent} from "./modals/word-bank-edit-modal/word-bank-edit-modal.component";
import {UpcomingEventRepository} from "./repository/UpcomingEventRepository";
import {AngularAnimalsModule} from "angular-animals";
import {LoadingIndicatorComponent} from "./components/loading-indicator/loading-indicator.component";
import {HelpAssistComponent} from "../areas/help-assist/help-assist.component";
import {MovieLinkComponent} from "../areas/movie-link/movie-link.component";
import {ModalModule} from "ngx-bootstrap/modal";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { ListFlashcardCollectionsComponent } from './areas/flashcard/list-flashcard-collections/list-flashcard-collections.component';
import {FlashcardCollectionRepository} from "./repository/FlashcardCollectionRepository";
import {NgSelectModule} from "@ng-select/ng-select";
import { FlashcardCollectionSectionComponent } from './areas/flashcard/flashcard-collection-section/flashcard-collection-section.component';
import { FlashCardCollectionAddEditModalComponent } from './areas/flashcard/flash-card-collection-add-edit-modal/flash-card-collection-add-edit-modal.component';
import { AddDisneyPlusMovieModalComponent } from './areas/movie/add-disney-plus-movie-modal/add-disney-plus-movie-modal.component';
import {OleloflixFunctions} from "./services/functions/OleloflixFunctions";

let ConfiguredLoggerModule = LoggerModule.forRoot({enableSourceMaps: true, level: NgxLoggerLevel.DEBUG }/*{serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR}*/);

export const MODALS = [
	ChromeExtensionRequiredModalComponent,
	AddSlipperModalComponent,
	OleloflixIntroModalComponent,
	WatchPartyExtensionRequiredModalComponent,
	OleloJamRegistrationModalComponent,
	AuthenticationModalComponent,
	WordBankEditModalComponent,
	FlashCardCollectionAddEditModalComponent,
	AddDisneyPlusMovieModalComponent
]
export const MODULES = [
	AngularFireModule.initializeApp(environment.firebase),
	AngularFirestoreModule,
	AngularFireAuthModule,
	AngularFireStorageModule,
	HttpClientModule,
	AngularFireFunctionsModule,
	FontAwesomeModule
];

export const RouterModuleRef = RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 60],
    relativeLinkResolution: 'legacy'
})



@NgModule({
    imports: [
        ConfiguredLoggerModule,
        NgBootstrapFormValidationModule.forRoot(),
        NgBootstrapFormValidationModule,
        //PRIMARY
        AppRoutingModule,
        RouterModuleRef,

        //ANIMATIONS
        BrowserAnimationsModule,

        //FOR VIDEO
        YtPlayerAngularModule,

        //IFRAME
        NgMagicIframeModule,

        //OTHER
        SvgrectModule,
        TextareaAutosizeModule,
        /*AnQrcodeModule,*/
        BrowserModule,
        AngularFireDatabaseModule,
        ...MODULES,
        FormsModule,
        CommonModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        ToasterModule,
        NgxMoveableModule,
        DeviceDetectorModule.forRoot(),
        HttpClientModule,
        TooltipModule.forRoot(),
        AngularAnimalsModule,

		//For Drop downs
		NgSelectModule,
    ],
	declarations: [
		CallbackComponent,
		AppComponent,
		ColorPickerComponent,
		SelectableCaptionWordComponent,
		SelectableCaptionSetComponent,
		EditCaptionSetComponent,
		UserStatusComponent,
		CaptioningComponent,
		StartCaptioningComponent,
		AudioRecordItemComponent,
		AudioRecordingListComponent,
		RecordComponent,
		MovieUndergoingCaptioningComponent,
		ArrayFilterPipe,
		SortPipe,
		HeaderComponent,
		UserSidebarComponent,
		CaptionSocialComponent,
		CaptionCommentComponent,
		OleloflixSplashComponent,
		GridTimelineComponent,
		GridTimelineBlockComponent,
		OleloEntryControlsComponent,
		OleloEntryButtonComponent,
		TrackCapsDirective,
		TrackShiftDirective,
		AutofocusDirective,
		SiteComponent,
		OleloJamComponent,
		UpcomingEventComponent,
		FooterComponent,
		ToggleDirective,
		ListPatronsComponent,
		AuthComponent,
		RegisterComponent,
		LoginComponent,
		ForgotPasswordComponent,
		SeriesUndergoingCaptioningComponent,
		ListEpisodesComponent,
		EpisodeComponent,
		HelpAssistComponent,
		MovieLinkComponent,
		PatronInListComponent,
		AddNewMovieButtonComponent,
		LoadingIndicatorComponent,
		ListFlashcardCollectionsComponent,
		...MODALS,
		FlashcardCollectionSectionComponent,
		FlashCardCollectionAddEditModalComponent,
		AddDisneyPlusMovieModalComponent,

	],

	providers: [
		...[/* Functions */
			OleloflixFunctions
		],
		...[/** SERVICES */
			PostMessageService,
			NetflixMovieService,
			NetflixStubData,
			ProvisioningService,
			CaptionFunctions,
			ConversionService,
			TranslateService,
			CookieService,
			AudioRecordingService,
			NotificationService,
			ToasterService,
			CaptionDiaryService,
			NetflixWatchService,
			AuthService,
			ValidationService,
			NetflixSeriesService,
		],
		...[/* REPOSITORIES */
			TimedNetflixCaptionRepository,
			CaptionCommentRepository,
			UserRepository,
			CaptionDiaryRepository,
			RemoteConfigurationRepository,
			PatronRepository,
			NetflixSeriesRepository,
			PledgeRepository,
			UpcomingEventRepository,
			FlashcardCollectionRepository
		],
		{provide: LocationStrategy, useClass: HashLocationStrategy},
	],
	entryComponents: [
		...MODALS
	],
	bootstrap: [AppComponent]
})
export class AppModule {

	constructor() {
		library.add(faEdit, faStar);
	}

}
