import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {AngularFirestore} from '@angular/fire/firestore';
import {NetflixSeries} from "../domain/database/NetflixSeries";

@Injectable({
	providedIn: 'root',
} as any)
export class NetflixSeriesRepository extends AbstractFirestoreRepository<NetflixSeries> {
	constructor(protected db:AngularFirestore) {
		super(NetflixSeries, db, db.collection('Series'));
	}
}
