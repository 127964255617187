import {Injectable} from "@angular/core";
import {PatronRepository} from "../repository/patrons/PatronRepository";
import {AbstractService} from "./AbstractService";
import {Patron} from "../domain/patrons/Patron";

@Injectable({
    providedIn: "root"
})
export class PatronService extends AbstractService<Patron> {
    constructor(protected repo:PatronRepository) {
        super(repo);
    }

}