<div class="col-12 bg-secondary text-white p-3 d-inline-block w-100">
	<button [routerLink]="['/']" class="btn btn-dark">
		<fa-layers size="1x">
			<fa-icon [icon]="icons.faArrowLeft" transform="grow-5" [inverse]="false"></fa-icon>
		</fa-layers>
		Return to Catalog</button>
	<span *ngIf="series" class="ml-3">Viewing the Series `{{series.title}}`</span>
</div>
<div class="spinner" *ngIf="series == null">
	<div class="text-muted text-center">Loading...</div>
	<div class="double-bounce1"></div>
	<div class="double-bounce2"></div>
</div>
<div class="gap2 gray-bg" *ngIf="series">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
<!--				<div class="central-meta" *ngIf="series.guid == 'avatar-the-last-airbender'">
					<div class="title-block">
						<div class="row">
							<div class="col-12 text-left" >
								<div class="align-left">
									<h5>Help Kaliko and Hiʻilani Caption Avatar The Last Airbender (the series)</h5>
									<br/><button class="btn btn-dark" (click)="helpKaliko()">Sign up to Help</button>
								</div>
							</div>
						</div>
					</div>
				</div>&lt;!&ndash; title block &ndash;&gt;-->
				<div class="central-meta padding30" *ngFor="let part of series.seasons">
					<div class="central-meta">
						<div class="title-block">
							<div class="row">
								<div class="col-6 text-left">
									<div class="align-left">
										<h5>{{part.label}} <span>{{part.episodes.length}}</span></h5>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row merged20 remove-ext" >
						<div class="col-lg-3 col-md-6 col-sm-6 app-episode-component" [series]="series" [season]="part" [episode]="episode" *ngFor="let episode of part.episodes"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


