import {Component, Input, OnInit} from '@angular/core';
import {Episode, NetflixSeries, SeasonOrPart} from "../../../app/domain/database/NetflixSeries";
import {NetflixMovieService} from "../../../app/services/NetflixMovieService";
import {UrlService} from "../../../app/services/UrlService";
import {faClosedCaptioning, faPlay, faUser} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: '.app-episode-component',
    templateUrl: './episode.component.html',
    styleUrls: ['./episode.component.scss']
})
export class EpisodeComponent implements OnInit {

    @Input()
    public series:NetflixSeries;

    @Input()
    public season:SeasonOrPart;

    @Input()
    public episode:Episode;

    public icons = {
        faPlay: faPlay,
        faUser: faUser,
        faClosedCaptioning: faClosedCaptioning

    };

    constructor(protected urlService:UrlService, protected netflixMovieService:NetflixMovieService) {

    }

    public ngOnInit() {

    }

    public get episodeThumbnail():string {
        return this.urlService.series.episode.thumbnail.url(this.series.guid, this.episode.movieGuid, this.season.seasonId);
    }

    public watch(movieId:string) {
        this.netflixMovieService.playNetflixMovie(movieId);
    }

}
