import {validateSync, ValidationError} from "class-validator";
import {AngularFireAuth} from "@angular/fire/auth";
import {Observable, Subject} from 'rxjs/index';
import {ConversionService} from "./ConversionService";
import {AngularFirestore} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {AuthService} from "./AuthService";
import {RegistrationFunctions} from "./functions/RegistrationFunctions";
import {NGXLogger} from "ngx-logger";
import {ValidationException} from './exceptions/ValidationException';
import {NewUserRegistration} from '../domain/registration/NewUserRegistration';
import {CompleteUserRegistration} from '../domain/registration/CompleteUserRegistration';
import {IServerError, ServerError} from './functions/standard/AbstractFirebaseFunction';

@Injectable({
	providedIn: 'root',
} as any)
export class RegistrationService {

	constructor(private angularFireAuth: AngularFireAuth,
	            private conversionService:ConversionService,
	            private db:AngularFirestore,
	            private authService:AuthService,
	            private functions:RegistrationFunctions,
	            protected logger:NGXLogger) {
	}

	/**
	 * Registers a user with the system
	 * @param {NewUserRegistration} newUserRegistration
	 * @throws {ValidationException} validationException when
	 * @returns {Observable<WaihonaUser>}
	 */
	public registerUser$(newUserRegistration:NewUserRegistration):Observable<IServerError<NewUserRegistration> | CompleteUserRegistration> {
		this.validate(newUserRegistration);

		let registerUserSubject$:Subject<CompleteUserRegistration|IServerError<NewUserRegistration>> = new Subject();
		this.functions.register$(newUserRegistration).subscribe(response => {
			if(response instanceof ServerError) {
				//(response as IServerError<NewUserRegistration>).message;
				registerUserSubject$.next(response);
				registerUserSubject$.complete();
			} else {

				let completeUserRegistration:CompleteUserRegistration = response as CompleteUserRegistration;
				registerUserSubject$.next(completeUserRegistration);
				/*this.authService.login(newUserRegistration.email, newUserRegistration.password).subscribe(success => {
					console.log(`Successful login? ` + success);
					registerUserSubject$.next(completeUserRegistration);
					registerUserSubject$.complete();
				});*/
			}

		});
		return registerUserSubject$;
	}

	/**
	 * Validates a registerUser object
	 * @param newUserRegistration
	 * @throws {ValidationException} when errors occur
	 */
	public validate(newUserRegistration:NewUserRegistration):void {
		this.logger.info(`validating registration data from client`);
		const errors:ValidationError[] = validateSync(newUserRegistration);

		if (errors.length > 0) {
			this.logger.error("validation failed. errors: ", errors);
			throw new ValidationException(errors);
		}
	}

}
