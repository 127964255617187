import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {AngularFirestore, QueryFn, } from '@angular/fire/firestore';

import {UpcomingEvent} from "../domain/database/UpcomingEvent";
import firebase from "firebase";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {Observable} from "rxjs";


@Injectable({
    providedIn: 'root',
} as any)
export class UpcomingEventRepository extends AbstractFirestoreRepository<UpcomingEvent> {

    public queries = {
        byFutureDateNotCompleted: (date:Date = new Date()):QueryFn => {
            let probableStartTimeRange:Date = new Date(date.getTime());
            probableStartTimeRange.setHours(date.getHours()-4);

            let numWeeks:number = 2; //2 weeks from now
            let probableEndTimeRange:Date = new Date(date.getTime());
            probableEndTimeRange.setDate(probableEndTimeRange.getDate() + numWeeks * 7);

            let asc:OrderByDirection = "asc";

            return (ref) => {
                return ref.where("completed", "==", false)
                            .where("visible", "==", true)
                            .where("epochStartTime", '>=', probableStartTimeRange.getTime()).
/*
                            .where("epochEndTime", '<=', probableEndTimeRange.getTime()).
*/
                            orderBy("epochStartTime", asc);
            };
        }
    }

    constructor(protected db:AngularFirestore) {
        super(UpcomingEvent, db, db.collection('Config/remote-config/Upcoming-Events'));
    }

    public queryByFutureDateNotYetCompleted$(date:Date = new Date()) {
        return this.query$(this.queries.byFutureDateNotCompleted(date));
    }
    /* Get the next upcoming event or null */
    public nextEvent$(date:Date = new Date):Observable<UpcomingEvent> {
        return this.queryByFutureDateNotYetCompleted$(date).map<UpcomingEvent[], UpcomingEvent>((upcomingEvents):UpcomingEvent => {
            if(upcomingEvents.length == 0) {
                return null;
            }
            return upcomingEvents[0];
        });
    }
}
