import {Converter} from "../ConversionService";
import {GroupedCaptionSet} from '../../domain/GroupedCaptionSet';
import {classToPlain, plainToClass} from "class-transformer";
import {WordBankGroupedCaptionSet} from "../../domain/bank/WordBankGroupedCaptionSet";
import {NetflixMovie, NetflixMovieRef} from "../../domain/database/NetflixMovie";

export class NetflixMovieToNetflixMovieRefConverter implements Converter {

  public from:typeof NetflixMovie;
  public to:typeof NetflixMovieRef;

  constructor() {
    this.from = NetflixMovie;
    this.to = NetflixMovieRef;
  }

  public convert(source:NetflixMovie):NetflixMovieRef {
    let converted:NetflixMovieRef = new NetflixMovieRef()
      converted.guid = source.titleId;
      converted.title = source.title;

    return converted;


  }
}
