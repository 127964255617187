<div bsModal class="modal-content custom-modal" style="min-width:660px; min-height: 505px;">
	<div class="modal-header bg-info w-100">
		<h4 class="modal-title">ʻŌleloflix?</h4>
	</div>
	<div class="modal-body" style="min-width:560px; min-height: 315px;">
		<iframe width="560" height="315" src="https://www.youtube.com/embed/lurvsf-K66g?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	</div>
	<div class="modal-footer border-0">
		<button type="button" class="btn btn-dark" (click)="dismiss()">Close</button>
	</div>
</div>

