import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {RealtimePresenceService} from "../../app/services/RealtimePresenceService";
import {
  faLongArrowAltDown,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {User} from "../../app/domain/User";
@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],

})
export class UserStatusComponent implements OnInit {

  public faLongArrowAltDown:IconDefinition = faLongArrowAltDown;

  @Input() guid;

  @Input() size:string = "20px";

  @Input() url;

  @Input()
  public user:User;

  @Input()
  public isCurrent:boolean = false;

  public presence$;

  @Output()
  public click:EventEmitter<UserStatusComponent> =new EventEmitter<UserStatusComponent>();

  constructor(private presence:RealtimePresenceService) { }

  ngOnInit() {
    this.presence$ = this.presence.getPresence(this.guid);
  }

  public onClick() {
    this.click.emit(this);
  }

  public get tooltip():string {
  	return (this.user.nickname == null || this.user.nickname == '') ? this.user.easyName : this.user.nickname;
  }

}
