import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {AngularFirestore} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RemoteConfiguration} from '../domain/config/RemoteConfiguration';

@Injectable({
	providedIn: 'root',
} as any)
export class RemoteConfigurationRepository extends AbstractFirestoreRepository<RemoteConfiguration> {

	constructor(protected db:AngularFirestore) {
		super(RemoteConfiguration, db, db.collection("Config"));
	}
	public get$():Observable<RemoteConfiguration> {
		return super.watch$("remote-config");
	}
	public createConfiguration$(remoteConfig:RemoteConfiguration = new RemoteConfiguration()):Observable<RemoteConfiguration> {
		if(remoteConfig == null) {
			remoteConfig = new RemoteConfiguration();
		} else {
			//For now the guid for the type of config canʻt be anything other than "remote-config"
			remoteConfig.guid = "remote-config";
		}
		return super.save$(remoteConfig);
	}
}
