
import {Injectable} from "@angular/core";
import {TimedTextCaptionToTimedNetflixCaptionConverter} from './converters/TimedTextCaptionToTimedNetflixCaptionConverter';
import {GroupedCaptionSetToWordBankGroupedCaptionSetConverter} from "./converters/GroupedCaptionSetToWordBankGroupedCaptionSetConverter";
import {NetflixMovieToNetflixMovieRefConverter} from "./converters/NetflixMovieToNetflixMovieRefConverter";

@Injectable({
    providedIn: 'root',
} as any)
/** Conversion Service converts between registered types */
export class ConversionService {

    public converters:Array<Converter> = [];
    public fromMap:object = {};

    constructor() {
        this.register(new TimedTextCaptionToTimedNetflixCaptionConverter());
        this.register(new GroupedCaptionSetToWordBankGroupedCaptionSetConverter());
        this.register(new NetflixMovieToNetflixMovieRefConverter());
    }
    /** Registers a new Converter */
    public register(converter:Converter) {
        let fromScope = this.fromMap[converter.from];

        if(fromScope == null) {
            this.fromMap[converter.from] = {};
        }
        this.fromMap[converter.from][converter.to] = converter;
    }
    public convert(sourceObject:object, destinationType:any) {
        if(sourceObject == null || destinationType == null) {
            throw new ConversionException(null, ConversionException.SOURCE_WAS_NULL);
        }

        let converters:object = this.fromMap[sourceObject.constructor as any];
        if(converters == null) {
            //No mapping for source (so impossible there is one for destination
            throw new ConversionException(null, ConversionException.MESSAGE_NO_CONVERTER, sourceObject, destinationType);
        }
        let converter:Converter = this.fromMap[sourceObject.constructor as any][destinationType];
        if(converter == null) {
            //There is one for source but not one for destination
            throw new ConversionException(converter, ConversionException.MESSAGE_NO_CONVERTER,sourceObject, destinationType);
        }

        let destination;
        try {
            destination = converter.convert(sourceObject);
        } catch(error) {
            throw new ConversionException(converter, ConversionException.CONVERSION_ERROR,sourceObject, destinationType);
        }
        return destination;
    }



}

export interface Converter {
    readonly from;
    readonly to;

    convert(source:any):any;
}

export class ConversionException extends Error {

    public static MESSAGE_NO_CONVERTER="No converter was registered for the source and destination object type";
    public static SOURCE_WAS_NULL="The source type was null";
    public static CONVERSION_ERROR="There was a problem running the converter";

    public converter:Converter;
    public sourceObject:Object;
    public destinationType:any;

    constructor(converter:Converter, message:string, sourceObject?:any, destinationType?:any) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

        this.converter   = converter;
        this.sourceObject = sourceObject;
        this.destinationType = destinationType;
    }
}
