import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CaptionComment} from '../../app/domain/CaptionComment';
import {UserRef} from '../../app/domain/UserRef';
import {classToClass} from 'class-transformer';
import {CaptionCommentRepository} from '../../app/repository/CaptionCommentRepository';
import {SubscriptionCleaner} from '../../app/util/SubscriptionCleaner';
import {Subscription} from 'rxjs';
import firebase from "firebase";
import firestore = firebase.firestore;
import Timestamp = firestore.Timestamp;
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
	selector: 'app-caption-social',
	templateUrl: './caption-social.component.html',
	styleUrls: ['./caption-social.component.scss']
})
export class CaptionSocialComponent extends SubscriptionCleaner implements OnInit, OnChanges {

	@Input()
	public movieId:string;

	public subscription:Subscription;

	@Input()
	public captionGuid:string;

	public _captionIndex:number;

	@Output()
	public gotoCaption:EventEmitter<number> = new EventEmitter<number>();

	@Input()
	public userRef:UserRef;

	public newCaptionComment:CaptionComment;

	public comments:Array<CaptionComment> = [];

	constructor(private captionCommentRepository:CaptionCommentRepository) {
		super();
	}
	public name():string {
		return (this.userRef.nickname != '') ? this.userRef.nickname : this.userRef.guid
	}

	@Input()
	public set captionIndex(value:number) {
		this._captionIndex = value;
		this.resetCaptionComment();
		this.refreshSubscription();
	}

	public get captionIndex():number {
		return this._captionIndex;
	}

	ngOnInit() {
		this.resetCaptionComment();
		this.refreshSubscription();

	}
	public clear():void {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}
	ngOnChanges(changes:SimpleChanges):void {

	}
	public refreshSubscription():void {
		this.clear();

		this.subscription = this.trackSubscription(this.captionCommentRepository.list$(false, this.movieId).pipe(
			debounceTime(250), //wait 500 ms for other things to happen
			distinctUntilChanged() //ignore until it changes
		).subscribe(captions => {
			this.comments = captions.sort((caption, caption2)=> {
				return caption.captionIndex - caption2.captionIndex;
			});
			this.subscription.unsubscribe();
		}));
	}
	public submitComment():void {
		if (this.newCaptionComment.comment.trim().length == 0) {
			return;
		}
		this.captionCommentRepository.save$(this.newCaptionComment, this.movieId);
		this.resetCaptionComment()

		this.refreshSubscription();
	}

	public resetCaptionComment():void {
		this.newCaptionComment = new CaptionComment();
		this.newCaptionComment.movieId = this.movieId;
		this.newCaptionComment.captionGuid = this.captionGuid;
		this.newCaptionComment.captionIndex = this.captionIndex;
		this.newCaptionComment.date = Timestamp.now();
		this.newCaptionComment.userRef = classToClass(this.userRef);
		this.newCaptionComment.comment = "";
	}


    public goto(captionComment:CaptionComment) {
        this.gotoCaption.emit(captionComment.captionIndex);
    }

    public delete(captionComment:CaptionComment) {
		this.captionCommentRepository.delete$(captionComment.guid, this.movieId);
		this.refreshSubscription();
		this.gotoCaption.emit(this.captionIndex);
	}
}

