import {ZoomSettings} from './ZoomSettings';
import {Exclude, Type} from 'class-transformer';
import {YoutubeSettings} from './YoutubeSettings';


export class RemoteConfiguration {

	@Exclude({ toPlainOnly: true })
	public guid:string = "remote-config";

	public isOleloJamActive:boolean = false;
	public showOleloJamCountdown:boolean = false;
	public showOleloJamRegistration:boolean = false;

	@Type(()=> ZoomSettings)
	public zoom:ZoomSettings;

	@Type(()=> YoutubeSettings)
	public youtube:YoutubeSettings;
}
