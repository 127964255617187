import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {MovieLink} from "../domain/MovieLink";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {NetflixChoosableMovie} from "./NetflixMovieDirectoryRepository";

@Injectable({
    providedIn: 'root',
})
export class MovieLinkRepository extends AbstractFirestoreRepository<MovieLink> {
    constructor(protected db:AngularFirestore) {
        super(MovieLink, db, db.collection('MovieLinks'));
    }
}
