import {Exclude} from 'class-transformer';
import {Equals, IsEmail, IsNotEmpty, MinLength} from 'class-validator';
import firebase from 'firebase';
import firestore = firebase.firestore;
import Timestamp = firestore.Timestamp;
import {HasThisManyUpperCaseLetters} from '../validators/HasThisManyUpperCaseLetters';
import {HasThisManyLowerCaseLetters} from '../validators/HasThisManyLowerCaseLetters';
import {HasThisManyNumbers} from '../validators/HasThisManyNumbers';
import {EqualsOtherProperty} from '../validators/EqualsOtherProperty';


export class NewUserRegistration {

	@Exclude()
	public guid:string; //User GUID

	public created:Timestamp = Timestamp.now();

	@Equals(true, {message: 'In order to create a waihona account you must accept the Waihona Contributor and User Kuleana Agreement.'})
	public acceptsAgreement:boolean = false;

	public agreementVersion:string = '1.0';

	@MinLength(2)
	public firstName:string = '';

	@MinLength(2)
	public lastName:string = '';


	@IsEmail({allow_ip_domain: true, require_tld: false})
	public email:string = '';

	@HasThisManyUpperCaseLetters(1, {message: 'Must contain at least one uppercase letter'})
	@HasThisManyLowerCaseLetters(1, {message: 'Must contain at least one lowercase letter'})
	@HasThisManyNumbers(1, {message: 'Must contain at least one number'})
	@MinLength(8, {message: 'Minimum 8 characters'})
	public password:string = '';

	@EqualsOtherProperty('password', {message: 'Password confirmation must match the password'})
	public confirmPassword:string = '';


}
