import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-audio-record-item',
	templateUrl: './audio-record-item.component.html',
	styleUrls: ['./audio-record-item.component.sass']
})
export class AudioRecordItemComponent implements OnInit {


	@Input()
	public isRecording:boolean = false;

	@Input()
	public blobUrl?:string = null;

	constructor() {

	}

	ngOnInit() {
	}

}
