import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';


@Component({
  selector: 'app-chrome-extension-required-modal',
  templateUrl: './chrome-extension-required-modal.component.html',
  styleUrls: ['./chrome-extension-required-modal.component.scss']
})
export class ChromeExtensionRequiredModalComponent implements OnInit {

	public component:any;
	public content:{};

	constructor(public bsModalRef:BsModalRef, public router:Router) {


	}

	ngOnInit() {
	}
	public onProperties(properties:object):void {
		this.content = properties;
	}

	public dismiss() {
		this.bsModalRef.hide();
	}


	install() {
		window.open("https://olelo.link/oleloflix-chrome", "_blank");
	}

	reload() {
		window.location.reload();
	}
}
