import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {RemoteConfigurationRepository} from '../../repository/RemoteConfigurationRepository';
import {BsModalRef} from "ngx-bootstrap/modal";


@Component({
  selector: 'app-watch-party-extension-required-modal',
  templateUrl: './authentication-modal.component.html',
  styleUrls: ['./authentication-modal.component.scss']
})
export class AuthenticationModalComponent implements OnInit {

	public component:any;
	public content:{watchPartyUrl:string};

	constructor(public bsModalRef:BsModalRef, public router:Router, private remoteConfigurationRepository:RemoteConfigurationRepository) {

	}

	ngOnInit() {
	}
	public onProperties(properties:{watchPartyUrl:string}):void {
		this.content = properties;
	}

	public dismiss() {
		this.bsModalRef.hide();
	}

	install() {
		window.open("https://chrome.google.com/webstore/detail/netflix-party/oocalimimngaihdkbihfgmpkcpnmlaoa?hl=en", "_blank");
	}

	reload() {
		window.location.reload();
	}

	launchParty() {
		window.location.href = this.content.watchPartyUrl;
	}
}
