import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {AngularFirestore, CollectionReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {AbstractCompoundFirestoreRepository} from './AbstractCompoundFirestoreRepository';
import {CaptionComment} from "../domain/CaptionComment";

@Injectable({
	providedIn: 'root',
} as any)
export class CaptionCommentRepository extends AbstractCompoundFirestoreRepository<CaptionComment> {

	public by = {
		movieIdAndCaptionGuid: (movieId:string, captionGuid:string) => {
			return (ref:CollectionReference) => {
				return   ref.where("movieId", '==', movieId)
					.where("captionGuid", '==', captionGuid);
			};
		}
	};
	private _currentMovie:number = 0;

	public static get PATH():string {
		return 'Netflix/%1$s/Comments';
	};


/*	public updatePartial$(value:{guid:string,[key:string]:any}):Observable<CaptionComment> {
		return super.updatePartial$(value, this._currentMovie.toString());
	}*/

	constructor(protected db:AngularFirestore) {
		super(CaptionComment, db, CaptionCommentRepository.PATH);
	}

	public get currentMovie():number {
		return this._currentMovie;
	}

	public set currentMovie(value:number) {
		this._currentMovie = value;
		while (this.defaultParams.length > 0) {
			this.defaultParams.pop();
		}
		this.defaultParams.push(value.toString());
	}


}
