import firebase from 'firebase';

export class Patron {
    public patreonId:number;
    public oleloflixUserGuid:string = null; //If it matches a user guid it will match here...
    public amountCents:number = 0;//500
    public avatarUrl:string = "";
    public createdDate:string = "";//MM/DD/YYYY
    public firstName:string = "";
    public lastName:string = ""
    public isPaused:boolean = false;
    public patronType:PatronType;

    public get fullName():string {
        return `${this.firstName} ${this.lastName}`;
    }
    public get patronTypeLabel():string {
        switch (this.patronType) {
            case PatronType.Hulo:
                return "Hulō!";
            case PatronType.Lanakila:
                return "Lanakila!";
            case PatronType.Pookela:
                return "Poʻokela!";
        }
        return "";
    }
}

export enum PatronType {
    Hulo = "Hulo",
    Lanakila = "Lanakila",
    Pookela = "Pookela"
}