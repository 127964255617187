import {getMetadataStorage, validateSync, ValidationError} from 'class-validator';
import {Injectable} from '@angular/core';
import {AbstractControl, AbstractControlOptions, FormControl, FormGroup} from '@angular/forms';
import {validationMetadatasToSchemas} from 'class-validator-jsonschema';
import {ErrorMessage} from 'ng-bootstrap-form-validation';

@Injectable({
	providedIn: 'root',
} as any)
export class ValidationService {

	constructor() {

	}

	public createForm(DomainClass:new() => {}, initialState, updateOn:'change' | 'blur' | 'submit' = 'blur'):{ [key:string]:FormControl } {
		const metadatas = (getMetadataStorage() as any).validationMetadatas;
		const constraintMetadatas = (getMetadataStorage() as any).constraintMetadatas;
		const schemas = validationMetadatasToSchemas(metadatas);
		let formObject:{ [key:string]:FormControl } = {};

		let properties = schemas[DomainClass.name].properties;
		let keys:Array<string> = Object.keys(properties);

		for (let key of keys) {
			let property = properties[key] as { type:string };
			let initialValue = initialState != null ? initialState[key] : '';

			let controlOptions:AbstractControlOptions = {
				updateOn: updateOn,
				validators: [this.createFormValidator(initialState, key, property.type as PropertyType)]
			};
			let formControl:FormControl = new FormControl(initialValue, controlOptions);
			formObject[key] = formControl;
		}

		return formObject;
	}

	public changeControlMode(formGroup:FormGroup, key:string, domain:any):void {
		let DomainClass:new() => {} = domain.constructor;
		console.error("change control mode");
		const metadatas = (getMetadataStorage() as any).validationMetadatas;
		const schemas = validationMetadatasToSchemas(metadatas);
		let properties = schemas[DomainClass.name].properties;


		let keys:Array<string> = Object.keys(properties);
		if (keys.indexOf(key) == -1) {
			console.error('property not found in validation schema');
			return;
		}
		let property = properties[key] as { type:string };


		let updateOn:'change' | 'blur' | 'submit' = 'change';
		let existingFormControl:AbstractControl = formGroup.controls[key];

		let initialValue = domain != null ? domain[key] : '';

		let controlOptions:AbstractControlOptions = {
			updateOn: updateOn,
			validators: [this.createFormValidator(domain, key, property.type as PropertyType)]
		};

		let formControl:FormControl = new FormControl(initialValue, controlOptions);
		formGroup.setControl(key, formControl);
		formControl.markAllAsTouched();

	}

	public getErrorMessages(domain:any, property:string, formGroup?:FormGroup):ErrorMessage[] {
		let validationErrors:ValidationError[] = validateSync(domain);
		let matchingError:ValidationError = validationErrors.find(error => {
			return error.property == property;
		});
		if (matchingError == null) {
			if(formGroup) {
				//TODO: only change if its not currently the selected mode..
				if(formGroup.controls[property].updateOn == "blur") {
					this.changeControlMode(formGroup, property, domain);
				}
			}
			return [];
		}
		let keys = Object.keys(matchingError.constraints);
		let errors:ErrorMessage[] = [];
		for (let key of keys) {
			errors.push({
				error: key,
				format: (label, error2) => {
					return matchingError.constraints[key]
					// insert a space before first cap on the property name (gradeLevels==>grade Levels)
						.replace(/([A-Z])/g, ' $1')
						// uppercase the first character ("grade Levels ==> Grade Levels)
						.replace(/^./, function(str) {
							return str.toUpperCase();
						});
				}
			});
		}

		return errors;
	}

	public getValueAsType(value:any, type?:PropertyType):string | number | boolean {
		switch (type) {
			case PropertyType.string:
			case PropertyType.null:
			case null:
				return value;
			case PropertyType.number:
				if (value.toString().indexOf('.') != -1) {
					return Number.parseFloat(value);
				}
				return Number.parseInt(value);
			case PropertyType.object:
				console.error('Cannot handle objects');
				break;
			case PropertyType.array:
				console.error('Cannot handle arrays');
				break;
			case PropertyType.boolean:
				console.error('how should boolean be handled?');
				return value.toString().toLowerCase() == 'true' ? true : false;
		}
	}

	public createFormValidator(domain:any, property:string, type?:PropertyType) {

		return (control:AbstractControl):{ [key:string]:boolean } | null => {
			domain[property] = this.getValueAsType(control.value, type);

			let validationErrors:ValidationError[] = validateSync(domain);
			if (validationErrors.length == 0) {
				return null;
			}
			let matchingError:ValidationError = validationErrors.find(error => {
				return error.property == property;
			});
			if (matchingError == null) {
				return null;
			}

			let keys = Object.keys(matchingError.constraints);
			let errorList = {};
			for (let key of keys) {
				errorList[key] = {message: matchingError.constraints[key]};
			}

			return errorList;
		};
	}
}

export enum PropertyType {
	'string' = 'string',
	'number' = 'number',
	'object' = 'object',
	'array' = 'array',
	'boolean' = 'boolean',
	'null' = 'null'
}
