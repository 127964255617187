import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NewUserRegistration} from '../../../app/domain/registration/NewUserRegistration';
import {RegistrationService} from '../../../app/services/RegistrationService';
import {IServerError, ServerError} from '../../../app/services/functions/standard/AbstractFirebaseFunction';
import {OleloflixUser} from '../../../app/domain/users/OleloflixUser';
import {ValidationException} from '../../../app/services/exceptions/ValidationException';
import {FormGroup} from '@angular/forms';
import {ErrorMessage} from 'ng-bootstrap-form-validation';
import {ValidationService} from '../../../app/services/ValidationService';
import {CompleteUserRegistration} from '../../../app/domain/registration/CompleteUserRegistration';


@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: [
		'../auth.scss',
		'./register.component.scss'
	],
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
	@Input()
	public socialOptions:string;
	@Output()
	public forgotPassword:EventEmitter<any> = new EventEmitter<any>();
	@Output()
	public login:EventEmitter<any> = new EventEmitter<any>();
	@Output()
	public registrationComplete:EventEmitter<OleloflixUser>;


	public loaded:boolean = false;

	public error:string = "";
	public newUserRegistration:NewUserRegistration = new NewUserRegistration();
	public formGroup: FormGroup;


	constructor(private registrationService:RegistrationService, private validationService:ValidationService) {
		this.newUserRegistration.firstName = "Kalani";
		this.newUserRegistration.lastName = "Bright";
		this.newUserRegistration.email = "kapaakea@manastudios.com";
		this.newUserRegistration.password = "Password1?";
		this.newUserRegistration.confirmPassword = "Password1?";
		this.newUserRegistration.acceptsAgreement = true;
		this.newUserRegistration.agreementVersion =  "1.0";
		this.formGroup = new FormGroup(this.validationService.createForm(NewUserRegistration, this.newUserRegistration));
		this.formGroup.markAllAsTouched();
	}

	ngOnInit() {
		this.loaded = true;
	}

	public errorsFor(property:string):ErrorMessage[] {
		return this.validationService.getErrorMessages(this.newUserRegistration, property, this.formGroup);
	}


	/**
	 * Submit (if valid)  the registration information to the server
	 * @throws {ValidationException} if not Valid
	 */
	public onRegisterClick():void {
		console.log(`submitting ${this.newUserRegistration}`);
		try {
			this.registrationService.registerUser$(this.newUserRegistration).subscribe( response => {
				if(response instanceof ServerError) {
					this.error = (response as IServerError<NewUserRegistration>).message;
				} else {
					let completeUserRegistration:CompleteUserRegistration = (response as CompleteUserRegistration);
					console.log(`Login complete! ${completeUserRegistration}`);
			}
			});
		} catch(ex) {
			if(ex instanceof ValidationException) {
				this.error = ex.toString();
			} else {
				throw(ex);
			}
		}
	}

}

