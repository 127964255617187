import {Component, Input, OnInit} from '@angular/core';
import {UserRef} from '../../app/domain/UserRef';
import {UrlService} from '../../app/services/UrlService';
import {NetflixMovie} from '../../app/domain/database/NetflixMovie';
import {Router} from '@angular/router';
import {User} from '../../app/domain/User';
import {RealtimePresenceService} from '../../app/services/RealtimePresenceService';


import {
	faPlay,
	faClosedCaptioning,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {NetflixMovieService} from '../../app/services/NetflixMovieService';
import {CaptionDiary} from '../../app/domain/CaptionDiary';

@Component({
	selector: '.movie-undergoing-captioning',
	templateUrl: './movie-undergoing-captioning.component.html',
	styleUrls: ['./movie-undergoing-captioning.component.scss']
})
export class MovieUndergoingCaptioningComponent implements OnInit {

	public principalUser:UserRef;

	public icons = {
		faPlay: faPlay,
		faClosedCaptioning: faClosedCaptioning
	};

	@Input()
	public movie:NetflixMovie;

	@Input()
	public diary:CaptionDiary;

	@Input()
	public user:User;

	public desiredIndex:string ="";


	constructor(public urlService:UrlService, protected router:Router, protected netflixMovieService:NetflixMovieService) {
		this.principalUser = new UserRef();
		this.principalUser.guid = 'lāhui';
		this.principalUser.nickname = 'Lāhui';

		if(this.user && this.user.movieId) {
			this.desiredIndex[this.user.movieId] = this.user.caption.toString();
		}
		this.diary = new CaptionDiary();
	}


	ngOnInit() {

	}
	public goto(movieId:string, number?:string) {
		if(number == null) {
			this.router.navigate(["movies", movieId, 0]);
		} else {
			this.router.navigate(["movies", movieId, number]);
		}

	}
	public watch(movieId:string) {
		this.netflixMovieService.playNetflixMovie(movieId);
	}

	public joinWatchParty() {
		if(this.movie.watchPartyUrl && this.movie.isWatchPartyActive) {
			this.netflixMovieService.joinWatchParty(this.movie.watchPartyUrl);
		}
	}

}
