import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-audio-recording-list',
	templateUrl: './audio-recording-list.component.html',
	styleUrls: ['./audio-recording-list.component.sass']
})
export class AudioRecordingListComponent implements OnInit {

	@Input()
	public audioRecordings:Array<any>;

	constructor() {
	}

	ngOnInit() {
	}

}
