<div class="notice-bar upcoming-event">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-2">
        <a [attr.data-registrations]="this.registrationCount"><img src="{{thumbnail}}" alt="" class="img-thumbnail"></a>
      </div>
      <div class="col-lg-5 col-md-4 col-sm-5">
        <span class="label label-default">An ʻŌlelo Jam Event</span>
        <h3 class="short"><a href="javascript:void(0)" (click)="register()">{{title}}</a></h3>
        <span class="meta-data">{{this.toFriendlyDate}}<br>{{this.location}}</span>
        <button (click)="register()">Register and Join</button>
      </div>
      <div id="counter" class="col-lg-5 col-md-6 col-sm-5 counter" data-date="July 13, 2017">
        <div class="timer-col count-days" *ngIf="timeRemaining.days > 0"> <span id="days">{{timeRemaining.days}}</span> <span class="timer-type">days</span> </div>
        <div class="timer-col" *ngIf="timeRemaining.days > 0 || timeRemaining.hours > 0"> <span id="hours">{{timeRemaining.hours}}</span> <span class="timer-type">hrs</span> </div>
        <div class="timer-col" *ngIf="timeRemaining.days > 0 || timeRemaining.hours > 0 || timeRemaining.minutes > 0 "> <span id="minutes">{{timeRemaining.minutes}}</span> <span class="timer-type">mins</span> </div>
        <div class="timer-col" *ngIf="timeRemaining.days > 0 || timeRemaining.hours > 0 || timeRemaining.minutes > 0 || timeRemaining.seconds > 0"  > <span id="seconds">{{timeRemaining.seconds}}</span> <span class="timer-type">secs</span> </div>
        <div *ngIf="timeRemaining.days <= 0 && timeRemaining.hours <= 0 && timeRemaining.minutes <= 0 && timeRemaining.seconds <= 0">Starting soon. Please wait...</div>
      </div>
    </div>
  </div>
</div>
