<div class="col-12 container-fluid text-center bg-light border-dark p-2">
    <button (click)="on = true" *ngIf="!on" style="background-color: aquamarine">Show Help</button>
    <button (click)="on = false" *ngIf="on" style="background-color: lightcoral">Hide Help</button>
    <br/>
    <br/>
    The big buttons (<fa-layers size="1x">
        <fa-icon [icon]="icons.faClosedCaptioning" transform="shrink-2" [inverse]="false"></fa-icon>
    </fa-layers>,
    <fa-layers size="1x">
        <fa-icon [icon]="icons.faFlag" transform="shrink-2" [inverse]="false"></fa-icon>
    </fa-layers>,
    <fa-layers size="1x">
        <fa-icon [icon]="icons.faQuestion" transform="shrink-2" [inverse]="false"></fa-icon>
    </fa-layers>,
    <fa-layers size="1x">
        <fa-icon [icon]="icons.faCloudUploadAlt" transform="shrink-2" [inverse]="false"></fa-icon>
    </fa-layers>) change modes.
    <div *ngIf="on && toolMode == ToolMode.Translate" class="text-center">
        <b>This mode is for captioning.</b><br/>
    </div>
    <div *ngIf="on && toolMode == ToolMode.Question">
        <b>This mode is to lookup English or Hawaiian words on Wehewehe!</b><br/>
        <br/><b>Click on word to look it up!</b><br/>
    </div>
    <div *ngIf="on && toolMode == ToolMode.Flag">
        This mode is to navigate between flagged captions.
        <b>Flag them if you have an issue.</b><br/>
    </div>
    <div *ngIf="on && toolMode == ToolMode.Publish">
        <b>This mode is for publishing!</b><br/>
        You can can publish to Netflix here!<br/>
        <br/>
    </div>
    <hr/>
    <div *ngIf="on && toolMode == ToolMode.Translate" class="text-center">
        <b>Choose a name</b> Choose a name if you havenʻt already.  If you do not see a way to set a name. Reload your browser.<br/>

        <h4>HELP FOR TRANSLATE MODE</h4>

        <b>&#x3C; &#x3E;</b> You may click on the timeline to followThe < > buttons navigate you (can use keyboard left/right keys).<br/>
        <b>&#x3C; &#x3E;</b> The < > buttons navigate you (can use keyboard left/right keys).<br/>
        <b>Timeline</b> You may also click on the time.  You can follow a user by clicking on their icon, then clicking "Follow user"<br/>
        <fa-layers size="1x">
            <fa-icon [icon]="icons.faStepBackward" transform="shrink-2" [inverse]="false"></fa-icon>
        </fa-layers>
        <fa-layers size="1x">
            <fa-icon [icon]="icons.faStepForward" transform="shrink-2" [inverse]="false"></fa-icon>
        </fa-layers>
        Skip the the closest untranslated caption (or shift+keyboard arrow key).<br/>
        <b>Save happens automatically when navigating.</b><br/>
        No Hawaiian keyboard? Click the diacritical letters āēīōūʻ to type. (for capitals hold down shift+click).<br/><br/>

        <fa-layers size="1x">
            <fa-icon [icon]="icons.faCircle" transform="grow-10" [inverse]="false"></fa-icon>
            <fa-icon [icon]="icons.faFlag" transform="shrink-2" [inverse]="true"></fa-icon>
        </fa-layers> - Flag the caption (ask for help)<br/>

        <fa-layers size="1x">
            <fa-icon [icon]="icons.faCircle" transform="grow-10" [inverse]="false"></fa-icon>
            <fa-icon [icon]="icons.faEdit" transform="shrink-2" [inverse]="true"></fa-icon>
        </fa-layers> - Edit the caption<br/>

        <fa-layers size="1x">
            <fa-icon [icon]="icons.faCircle" transform="grow-10" [inverse]="false"></fa-icon>
            <fa-icon [icon]="icons.faUndo" transform="shrink-2" [inverse]="true"></fa-icon>
        </fa-layers> - Undo your changes to the caption<br/>

        <fa-layers size="1x">
            <fa-icon [icon]="icons.faCircle" transform="grow-10" [inverse]="false"></fa-icon>
            <fa-icon [icon]="icons.faSave" transform="shrink-2" [inverse]="true"></fa-icon>
        </fa-layers> - Saves the caption (...or, just navigate!)<br/>
        <!-- T -->
        <fa-layers size="1x">
            <fa-icon [icon]="icons.faCircle" transform="grow-10" [inverse]="false"></fa-icon>
            <fa-icon [icon]="icons.faGlobe" transform="shrink-2" [inverse]="true"></fa-icon>
        </fa-layers> - Cheat liʻiliʻi (Use a little Google Translate.)<br/>
    <hr/>
    <div class="text-center">
        <h4>GENERAL HELP</h4>
        <b>The Word Bank</b> is used to identify new words that may be needed.<br/><br/>
        <b>The comment functionality</b> is to enter, go to, or delete comments related to the current caption you are on.
        <br/>Use this to make notes.  (You can also use the flag tool (<fa-layers size="1x">
        <fa-icon [icon]="icons.faCircle" transform="grow-10" [inverse]="false"></fa-icon>
        <fa-icon [icon]="icons.faFlag" transform="shrink-2" [inverse]="true"></fa-icon>
    </fa-layers> - to flag the caption to ask for help.<br/> (if you have uncertain about a translation.)<br/>
    </div>
</div>


