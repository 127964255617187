import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectableCaption} from '../domain/SelectableCaption';
import {WordCursor} from "../../areas/captioning/captioning.component";

@Component({
  selector: 'app-selectable-caption-set',
  templateUrl: './selectable-caption-set.component.html',
  styleUrls: ['./selectable-caption-set.component.scss']
})
export class SelectableCaptionSetComponent implements OnInit {

  @Input()
  public captionWords:Array<SelectableCaption | string> = [];

  @Input()
  public selectedColor:string = "#000000";

  @Output()
  public onWordClick:EventEmitter<SelectableCaption> = new EventEmitter<SelectableCaption>();

  @Input()
  public cursor:WordCursor = new WordCursor("pointer");


  constructor() { }

  public isSelectableCaption(input:string | SelectableCaption):boolean {
    return input instanceof SelectableCaption;
  }

  ngOnInit() {

  }


  public onWordClicked(wordClicked:SelectableCaption) {
    console.log("Changing color of " + wordClicked.text + " from " + wordClicked.color + " to " + this.selectedColor);

    this.onWordClick.emit(wordClicked);
  }

/*  ngOnChanges(changes: SimpleChanges): void {
    this.cursor = changes["cursor"].currentValue;
  }*/
}
