<app-upcoming-event *ngIf="(event && settings && remoteConfig.showOleloJamCountdown && !settings.isActive)"
		[title]="event.title"
		[thumbnail]="event.thumbnail"
		[date]="event.date"
		[location]="event.location"
></app-upcoming-event>
<ng-container *ngIf="settings && settings.isActive">
	<div class="row bottom-section">
		<div class="card w-100 bg-dark pt-3" *ngIf="participationOption == ParticipationOption.participateActively">
			<p class="text-center w-100 text-white">
				An ʻŌlelo Jam in in Progress.<br/>
				<button (click)="launchZoom()">Join the ʻŌlelo Jam Event</button>
			</p>
		</div>
<!--		<div class="card" *ngIf="participationOption == ParticipationOption.watchAndListen">
			<button (click)="launchZoom();">Join the Jam (Participate Actively Instead)</button>
			<div class="col">
				<yt-player [videoId]="youtube.videoId" [options]="playerOptions" *ngIf="youtube && youtube.active && youtube.videoId"></yt-player>
			</div>
		</div>-->
	</div>
<!--	<div #target class="container video-window" [ngClass]="this.size">
		<div class="row">
			<div class="col text-left">
				<fa-layers size="1x">
					<fa-icon [icon]="icons.faGripVertical" [inverse]="true"></fa-icon>
				</fa-layers>
			</div>
			<div class="col text-center title" *ngIf="size != Size.small">
				Start ʻŌlelo Jam
			</div>-->
<!--			<div class="col text-right screen-size">
				<span *ngIf="size == Size.large" class="title">Screen Size&nbsp;</span>
				<fa-layers size="1x" [ngClass]="{option: true, selected: size == Size.minimize}">
					<fa-icon [icon]="icons.faWindowMinimize" [inverse]="true" (click)="size = Size.minimize"></fa-icon>
				</fa-layers>&nbsp;
				<fa-layers size="1x" [ngClass]="{option: true, selected: size == Size.small}">
					<fa-icon [icon]="icons.faDesktop" [inverse]="true" transform="shrink-6" (click)="size = Size.small"></fa-icon>
				</fa-layers>&nbsp;
				<fa-layers size="1x" [ngClass]="{option: true, selected: size == Size.medium}">
					<fa-icon [icon]="icons.faDesktop" [inverse]="true" transform="shrink-3" (click)="size = Size.medium"></fa-icon>
				</fa-layers>&nbsp;
				<fa-layers size="1x" [ngClass]="{option: true, selected: size == Size.large}">
					<fa-icon [icon]="icons.faDesktop" [inverse]="true" (click)="size = Size.large"></fa-icon>
				</fa-layers>
				&nbsp;<span>New Window &ndash;&gt;&nbsp;</span>
				<fa-layers size="1x" [ngClass]="{option: true}">
					<fa-icon [icon]="icons.faWindowRestore" [inverse]="true" (click)="newWindow()"></fa-icon>
				</fa-layers>
			</div>-->
<!--		</div>
		<div class="row" *ngIf="size == Size.medium || size == Size.large">-->
<!--			<div class="col-6 text-center text-light" (click)="participationOption = ParticipationOption.participateActively" >
				<button class="btn w-100" [ngClass]="{'btn-primary': participationOption == ParticipationOption.participateActively,'btn-dark': participationOption != ParticipationOption.participateActively}">Participate Actively (Zoom)</button>
			</div>
			<div class="col-6 text-center text-light" (click)="participationOption = ParticipationOption.watchAndListen">
				<button class="btn w-100" [ngClass]="{'btn-primary': participationOption == ParticipationOption.watchAndListen, 'btn-dark': participationOption != ParticipationOption.watchAndListen}">
					Participate without chat
				</button>
			</div>-->
<!--		</div>
		<div class="row bottom-section">
			<div class="card w-100" *ngIf="participationOption == ParticipationOption.participateActively">

				<p class="text-center w-100">
					A Jam in in Progress.  Click to Join the ʻŌlelo Jam.
					<button (click)="launchZoom()">Join the Jam (Participate Actively)</button>
				</p>
			</div>
			<div class="card" *ngIf="participationOption == ParticipationOption.watchAndListen">
				<button (click)="launchZoom();">Join the Jam (Participate Actively Instead)</button>
				<div class="col">
					<yt-player [videoId]="youtube.videoId" [options]="playerOptions" *ngIf="youtube && youtube.active && youtube.videoId"></yt-player>
				</div>
			</div>
		</div>
	</div>-->
<!--	<ngx-moveable
			[target]="target"
			[draggable]="true"
			[resizable]="false"
			[throttleDrag]="0"
			(dragStart)="onDragStart($event)"
			(drag)="onDrag($event)"
			(dragEnd)="onDragEnd($event)"
	></ngx-moveable>-->
</ng-container>

