import {Equals} from "class-validator";

export class UserAgreement {
	public agreementVersion:string = "1.0";

	@Equals(true, {message: "In order to create a waihona account you must accept the Waihona Contributor and User Kuleana Agreement."})
	public acceptsAgreement:boolean = false;

	public acceptedAgreementVersions:Array<string> = [];
}
