import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

	@Input()
	public itemsLoading:boolean;

	@Input()
	public listType:string = "items";

	public CB = {
		loading: {
			en: `Loading...`
		},
		no_items: {

			en: 'No movies found'
		}
	};

	constructor() {

	}

	ngOnInit() {

	}

}
