
export class ZoomSettings {
	public signatureEndpoint:string = 'https://oleloflix-signature.herokuapp.com/';
	public apiKey:string = 'YwpsEqZJSzqM7oqcEuShuw';
	public meetingNumber:number = 735641898;
	public role:number = 0;
	public leaveUrl:string = 'https://oleloflix.com';
	public passWord:string = 'olelojam';
	public isActive:boolean = false;
	public zoomLink:string;
}
