import {AfterViewInit, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {SelectableCaption} from './domain/SelectableCaption';
import {ActivatedRoute, Router} from '@angular/router';
import {NetflixMovieService} from './services/NetflixMovieService';
import {CaptionDiaryRepository} from './repository/CaptionDiaryRepository';
import {SubscriptionCleaner} from './util/SubscriptionCleaner';
import {useContainer} from 'class-validator';
import {ValidationService} from './services/ValidationService';
import {AuthService} from './services/AuthService';
import {ProvisioningService} from "./services/ProvisioningService";
import {NotificationService} from "./services/NotificationService";
import {OleloJamRegistrationRepository} from "./repository/OleloJamRegistrationRepository";


export interface SetBackup {
	set1:{ content:string, backup:Array<SelectableCaption | string> };
	set2:{ content:string, backup:Array<SelectableCaption | string> };
}

export enum PaintMode {
	'color' = 'color',
	'pick' = 'pick'
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent {

	public shouldDisplayOverlay:boolean = true;

	constructor(public provisioningService:ProvisioningService, public oleloJamRegistrationRepository:OleloJamRegistrationRepository) {


/* 	constructor(protected authService:AuthService, protected validationService:ValidationService) { */
/*		authService.handleAuthentication();

		// Attempt single sign-on authentication
		// if not authenticated
		if (authService.isAuthenticated()) {
			this.shouldDisplayOverlay = false;
		} else {
			authService.renewTokens();
		}
		// Hide the overlay when single sign-on auth is complete
		this.authService.onAuthenticationComplete().subscribe(
			status => this.shouldDisplayOverlay = !status
		);*/

	}

}
