import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';


@Component({
  selector: 'app-oleloflix-intro-modal-component',
  templateUrl: './oleloflix-intro-modal.component.html',
  styleUrls: ['./oleloflix-intro-modal.component.scss']
})
export class OleloflixIntroModalComponent implements OnInit {

	public component:any;
	public content:{};

	constructor(public bsModalRef:BsModalRef, public router:Router) {


	}

	ngOnInit() {
	}
	public onProperties(properties:object):void {
		this.content = properties;
	}

	public dismiss() {
		this.bsModalRef.hide();
	}

}
