<section class="fxt-template-animation fxt-template-layout2 has-animation"  [ngClass]="{loaded: loaded}">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-12 fxt-bg-color">
				<div class="fxt-content">
					<div class="fxt-header">
						<a href="javascript:void(0)" class="fxt-logo"><img src="./assets/oleloflix-icon.svg" alt="ʻŌleloflix"></a>
						<ul class="fxt-socials" *ngIf="socialOptions">
							<li class="fxt-google" *ngIf="socialOptions.indexOf('google')"><a href="#" title="google"><i class="fab fa-google-plus-g"></i><span>Google +</span></a></li>
							<li class="fxt-twitter" *ngIf="socialOptions.indexOf('twitter')"><a href="#" title="twitter"><i class="fab fa-twitter"></i><span>Twitter</span></a></li>
							<li class="fxt-facebook" *ngIf="socialOptions.indexOf('facebook')"><a href="#" title="Facebook"><i class="fab fa-facebook-f"></i><span>Facebook</span></a></li>
						</ul>
						<div class="fxt-style-line">
							<h1>Register</h1>
						</div>
					</div>
					<div class="fxt-form">
						<form [formGroup]="formGroup"  autocomplete="off">
							<div class="form-row m-0 p-0">
								<div class="col my-0 form-group pl-0" [customErrorMessages]="errorsFor('firstName')">
									<div class="fxt-transformY-50 fxt-transition-delay-1">
										<input type="text" class="form-control" name="firstName" placeholder="First name" formControlName="firstName" appAutofocus>
										<bfv-messages></bfv-messages>
									</div>
								</div>
								<div class="col my-0 form-group pr-0" [customErrorMessages]="errorsFor('lastName')">
									<div class="fxt-transformY-50 fxt-transition-delay-2">
										<input type="text" class="form-control" name="lastName" placeholder="Last Name" formControlName="lastName" required="required">
										<bfv-messages></bfv-messages>
									</div>
								</div>
							</div>

							<div class="form-group" [customErrorMessages]="errorsFor('email')">
								<div class="fxt-transformY-50 fxt-transition-delay-3">
									<input type="email" class="form-control" name="email" placeholder="Email Address" formControlName="email" required="required" autocomplete="off">
									<bfv-messages></bfv-messages>
								</div>
							</div>
							<div class="form-group" [customErrorMessages]="errorsFor('password')">
								<div class="fxt-transformY-50 fxt-transition-delay-4">
									<input type="password" class="form-control" name="password" placeholder="Password" formControlName="password"  required="required" autocomplete="off">
									<i toggle="#password" class="fa fa-fw fa-eye toggle-password field-icon"></i>
									<bfv-messages></bfv-messages>
								</div>
							</div>
							<div class="form-group" [customErrorMessages]="errorsFor('confirmPassword')">
								<div class="fxt-transformY-50 fxt-transition-delay-5">
									<input type="password" class="form-control" name="confirmPassword" placeholder="Confirm Password"  formControlName="confirmPassword" required="required" autocomplete="off">
									<i toggle="#confirmPassword" class="fa fa-fw fa-eye toggle-password field-icon"></i>
									<bfv-messages></bfv-messages>
								</div>
							</div>
							<div class="form-group" [customErrorMessages]="errorsFor('acceptsAgreement')">
								<div class="fxt-transformY-50 fxt-transition-delay-5">
									<div class="fxt-checkbox-area">
										<div class="checkbox">
											<input id="acceptsAgreement" type="checkbox" name="acceptsAgreement" formControlName="acceptsAgreement">
											<label for="acceptsAgreement">I agree to the terms of service</label>
										</div>
									</div>
								</div>
							</div>
							<div>
								{{error}}
							</div>
							<div class="form-group">
								<div class="fxt-transformY-50 fxt-transition-delay-6">
									<button type="submit" class="fxt-btn-fill" [disabled]="this.formGroup.invalid" (click)="onRegisterClick()">Register</button>
								</div>
							</div>
						</form>
					</div>
					<div class="fxt-footer">
						<div class="fxt-transformY-50 fxt-transition-delay-7">
							<p>Have you an account?<a href="javascript:void(0)" (click)="login.emit()" class="switcher-text">Log in</a></p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 fxt-none-991 fxt-bg-img" data-bg-image="img/figure/bg2-r.jpg"></div>
		</div>
	</div>
</section>
