import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from './AbstractFirestoreRepository';
import {NetflixMovie} from '../domain/database/NetflixMovie';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root',
} as any)
export class NetflixMovieRepository extends AbstractFirestoreRepository<NetflixMovie> {
	constructor(protected db:AngularFirestore) {
		super(NetflixMovie, db, db.collection('Netflix'));
	}
}
