import {Injectable} from '@angular/core';
import {NetflixStubData} from '../data/NetflixStubData';
import {CaptionFunctions} from './functions/old/CaptionFunctions';
import {NetflixMovieLocalizationRepository} from '../repository/netflix-movie-localization-repository.service';
import {NetflixMovieLocalization} from '../domain/database/NetflixMovieLocalization';
import {Observable, Subject} from 'rxjs';
import {NetflixMovieRepository} from '../repository/NetflixMovieRepository';
import {TimedNetflixCaptionRepository} from '../repository/TimedNetflixCaptionRepository';
import {BasicCaptionFunctions} from './functions/old/BasicCaptionFunctions';
import {LocalConfiguration} from '../domain/config/LocalConfiguration';
import {NotificationService} from './NotificationService';
import {WatchPartyExtensionRequiredModalComponent} from '../modals/watch-party-extension-required-modal/watch-party-extension-required-modal.component';
import {AddMovieRequest} from "../domain/forms/AddMovieRequest";
import {environment} from "../../environments/environment";
import {classToPlain} from "class-transformer";
import {HttpClient} from "@angular/common/http";
import {ChromeExtensionRequiredModalComponent} from "../modals/chrome-extension-required-modal/chrome-extension-required-modal.component";
import {MovieLink} from "../domain/MovieLink";

declare var chrome:any;
@Injectable({
	providedIn: 'root'
})
export class NetflixMovieService {

	public movie = {
		watchList: ()=> {
			return this.netflixMovieRepository.watchList$();
		},
		list: ()=> {
			return this.netflixMovieRepository.list$();
		},
		get: (guid:string) => {
			return this.netflixMovieRepository.get$(guid);
		}
	};
	public series = {

	}


	public configuration:LocalConfiguration = {
		isExtensionInstalled: false

	};
	public playingMovieId$:Subject<PlayMovie> = new Subject<PlayMovie>();


	constructor(private stubData:NetflixStubData, protected notificationService:NotificationService, private basicCaptionFunctions:BasicCaptionFunctions, private captionFunctions:CaptionFunctions, private timedNetflixCaptionRepository:TimedNetflixCaptionRepository, private netflixMovieRepository:NetflixMovieRepository, private netflixMovieLocalizationRepository:NetflixMovieLocalizationRepository, private httpClient:HttpClient) {

	}


	public setNetflixMovie(movieId:string):void {
		this.timedNetflixCaptionRepository.currentMovie = movieId;
		this.netflixMovieLocalizationRepository.currentMovie = movieId;
	}

	public getNetflixMovieData():Observable<NetflixMovieLocalization> {
		return this.netflixMovieLocalizationRepository.englishToHawaiian$();
	}

	public addCaption(titleId:string, title:string):void {
		console.log(`Adding Caption for ${titleId}`);
		this.captionFunctions.addCaption(titleId, title);
	}

	public updateNetflixMovie(titleId:string):void {
		this.basicCaptionFunctions.updateNetflixMovie(titleId);
	}

	public playMovieLink(movieLink:MovieLink):void {

	}
	public playNetflixMovie(movieId:string, options?:{seconds?:number, captionIndex?:number}):void {
		if(!this.configuration.isExtensionInstalled) {
			console.error("EXTENSION NOT INSTALLED.  IGNORING REQUEST.");
			this.notificationService.displayModal(ChromeExtensionRequiredModalComponent, this);
			return;
		} else {
			console.log(`playing ${movieId}`);
			let playMovie:PlayMovie = {
				movieId: movieId
			};
			if(options && options.seconds) {
				playMovie.seconds = options.seconds;
			}
			if(options && options.captionIndex) {
				playMovie.captionIndex = options.captionIndex;
			}

			this.playingMovieId$.next(playMovie);
		}
	}
	public stopNavigation():void {
		this.playingMovieId$.next(null);
	}

	public detectExtension():void {
		let body:HTMLBodyElement = document.getElementsByTagName("body").item(0) as HTMLBodyElement;
		let oleloflixAttribute:string = body.getAttribute("data-oleloflix-extension");
		let oleloflixAttributeExists:boolean = oleloflixAttribute != null && oleloflixAttribute != "";

		if(oleloflixAttributeExists) {
			this.configuration.isExtensionInstalled = true;
			this.configuration.version = oleloflixAttribute;
			console.info(`ʻŌleloflix Chrome Extension is installed.\nVersion ${this.configuration.version}.`)
		}
	}

	public joinWatchParty(watchPartyUrl:string) {
		if(!this.configuration.isExtensionInstalled) {
			console.error("EXTENSION NOT INSTALLED.  IGNORING REQUEST.");
			this.notificationService.displayModal(ChromeExtensionRequiredModalComponent, this);
			return;
		}

		this.notificationService.displayModal(WatchPartyExtensionRequiredModalComponent, this, {watchPartyUrl: watchPartyUrl});

	}

	public suggestNewMovie(addMovieRequest:AddMovieRequest):void {
		const url:string = environment.zapier.addMovieRequest;
		const body:any = JSON.stringify(classToPlain(addMovieRequest));

		this.httpClient.post(url, body).subscribe(
				data  => { console.log("Sent data.", data); },
				error  => { console.log("Error Occured.", error);}
			);
	}
}

export interface PlayMovie {
	movieId:string;
	captionIndex?:number;
	seconds?:number;
}
