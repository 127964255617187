<div class="popup">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<span class="popup-closed"><i class="ti-close" (click)="hide()"></i></span>
				<div class="popup-meta">
					<div class="popup-head">
						<h2>Add your favorite movie!</h2>
					</div>
					<form method="post" class="c-form" name="addMovieRequest">
<!--						<div class="form-radio">
							<div class="radio">
								<label>
									<input type="radio" name="radio" checked="checked"><i class="check-box"></i>Small: Mens (6-7) Womens (7-8)
								</label>
							</div>
							<div class="radio">
								<label>
									<input type="radio" name="radio"><i class="check-box"></i>Medium: Mens (8-9) Womens (9-11)
								</label>
							</div>
							<div class="radio">
								<label>
									<input type="radio" name="radio"><i class="check-box"></i>Large: Mens (10-11) Womens (11-12)
								</label>
							</div>
						</div>-->
						<div>
							<input type="text" placeholder="Movie Name" [(ngModel)]="addMovieRequest.movieName" [ngModelOptions]="{standalone: true}">
							<input type="text" placeholder="Netflix Movie Url"  [(ngModel)]="addMovieRequest.movieUrl" [ngModelOptions]="{standalone: true}">
						</div>
						<div>
							<input type="text" placeholder="Email Address" [(ngModel)]="addMovieRequest.emailAddress" [ngModelOptions]="{standalone: true}">
							<input type="text" placeholder="Your Name" [(ngModel)]="addMovieRequest.creditPlain" [ngModelOptions]="{standalone: true}">
<!--
							<textarea placeholder="Mailing Address for Slippers" rows="3"  [(ngModel)]="addMovieRequest.mailingAddress"></textarea>
-->
						</div>
						<div>
							<label>Comments for us</label>
							<textarea placeholder="Write any comments you have about ʻŌleloflix or us." rows="2"  [(ngModel)]="addMovieRequest.comments" [ngModelOptions]="{standalone: true}"></textarea>
						</div>
						<div>
							<button data-ripple="" type="submit" class="main-btn" (click)="submit()">Submit</button>
							<a data-ripple="" (click)="this.hide()" class="main-btn3 cancel">Close</a>
						</div>
					</form>

	<!--				<div class="Rpt-meta">
						<span>Mahalo for helping to translate a movie!  Please enter the details for the movie and what size slippers you would like after the fact</span>
						<div class="change-photo">
							<figure><img src="images/resources/admin2.jpg" alt=""></figure>
							<div class="edit-img">
								<form class="edit-phto">
									<label class="fileContainer">
										<i class="fa fa-camera-retro"></i>
										Upload Group Avatar
										<input type="file">
									</label>
								</form>
							</div>
						</div>
					</div>-->
				</div>
			</div>
		</div>
	</div>
</div>

