import {Toast, ToasterService} from "angular2-toaster";
import {Injectable} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Subscription} from 'rxjs';
import {IModal} from '../modals/IModal';

@Injectable({
	providedIn: 'root',
} as any)
export class NotificationService {

	public static MODAL_DEFAULT_INITIAL_STATE = {
		backdrop: 'static',
		animated: true
	};
	public modalRef: BsModalRef;
	public subscriptions: Subscription[] = [];

	constructor(private toasterService:ToasterService,
	            private modalService:BsModalService,) {
	}

	/** Displays a component using a Modal Class, the component, and any properties to set the component with, by default has a pretty good initial state ... or you can pass one in */
	public displayModal<T extends object>(ComponentClass:any, componentHandler?:any, properties:T = {} as any, initialState:any = NotificationService.MODAL_DEFAULT_INITIAL_STATE):BsModalRef {

		let modalRef:BsModalRef = this.modalService.show(ComponentClass, initialState);

		//Set the properties on the modal instance the same as what was sent in in object form
		let copy:any = {};
		let keys:Array<string> = Object.keys(properties);
		let hasProperties:boolean = keys.length > 0;

		if(hasProperties) {
			keys.forEach(
				key => {
					let value:any = properties[key];
					copy[key] = value;
				}
			);
			try {
				(modalRef.content as IModal).onProperties(copy);
			} catch(error) {
				console.error("NotificationService had properties but did not implement IModal")
			}
		}

		//set the component handler
		modalRef.content.component = componentHandler;
		return modalRef;
	}

	public displayToast(pathObject:IPathObject, type:ToastType, params = {}) {
		const toast:Toast = {
			type: type,
			title: pathObject.title,
			body: pathObject.body,
			showCloseButton: true
		};

		this.toasterService.pop(toast);
		return toast;
	}

	public unsubscribe() {
		this.subscriptions.forEach((subscription: Subscription) => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	}


}
export interface IPathObject {
	title:string;
	body:string;
}

export enum ToastType {
	"warning"="warning",
	"error"="error",
	"success"="success",
	"info"="info"
}
