import {Injectable} from '@angular/core';
import {
	AbstractFirestoreRepository, OrderByCriterion, OrderByDirections,
	QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from './AbstractFirestoreRepository';
import {NetflixMovie} from '../domain/database/NetflixMovie';
import {AngularFirestore, QueryFn} from '@angular/fire/firestore';
import {FlashcardCollection} from "../domain/flashcard/FlashcardCollection";

@Injectable({
	providedIn: 'root',
} as any)
export class FlashcardCollectionRepository extends AbstractFirestoreRepository<FlashcardCollection> {
	public by = {
		server: {
			criterion:{
				owner:(ownerIdentifier:string) =>
					QueryCriterion.create("owner", WhereFilterOperations.EqualTo, ownerIdentifier),
			},
			order: {
				byIndexedTitle: ()=> OrderByCriterion.create("title", OrderByDirections.Ascending)
			},
			criteria:{
				all:() => new QueryCriteriaBuilder()
					.orderBy(this.by.server.order.byIndexedTitle()).toCriteria(),
				owner:(ownerIdentifier:string) => new QueryCriteriaBuilder()
					.where(this.by.server.criterion.owner(ownerIdentifier)).toCriteria(),
			},
			queryFn: {
				all:  ():QueryFn => new QueryCriteriaBuilder(this.by.server.criteria.all()).toQueryFn(),
				owner:  (ownerIdentifier:string):QueryFn => new QueryCriteriaBuilder(this.by.server.criteria.owner(ownerIdentifier)).toQueryFn(),
			},
		},
		client: {
			filters: {
				accessible: (item: FlashcardCollection, owner: string) => {
					return item.owner == owner || item.owner == null;
				}
			}
		}
	};

	constructor(protected db:AngularFirestore) {
		super(FlashcardCollection, db, db.collection('FlashcardCollections'));
	}
}
