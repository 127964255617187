import {AbstractFirestoreRepository} from "../AbstractFirestoreRepository";
import {Patron} from "../../domain/patrons/Patron";
import {AngularFirestore} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
} as any)
export class PatronRepository extends AbstractFirestoreRepository<Patron> {

    constructor(protected db:AngularFirestore) {
        super(Patron, db, db.collection('Patrons'));
    }

}