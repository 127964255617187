import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToolMode} from "../captioning/captioning.component";
import {
  faArrowLeft, faCheck,
  faCloudUploadAlt,
  faEye,
  faPlay,
  faStepBackward,
  faStepForward,
  faPiggyBank, IconDefinition, faCircle, faEdit, faUndo, faSave, faGlobe, faFlag, faClosedCaptioning, faQuestion,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-help-assist',
  templateUrl: './help-assist.component.html',
  styleUrls: ['./help-assist.component.scss']
})
export class HelpAssistComponent implements OnInit {
  get on(): boolean {
    return this._on;
  }
  @Input()
  set on(value: boolean) {
    this._on = value;
    this.update.emit(value);
  }


  private _on:boolean = true;

  @Output()
  public update:EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input()
  public toolMode:ToolMode = ToolMode.Translate;
  public icons = {
    faCloudUploadAlt: faCloudUploadAlt,
    faArrowLeft: faArrowLeft,
    faEye: faEye,
    faPiggyBank: faPiggyBank,
    faPlay: faPlay,
    faStepForward: faStepForward,
    faStepBackward: faStepBackward,
    faCheck: faCheck,
    faCircle: faCircle,
    faEdit: faEdit,
    faUndo: faUndo,
    faSave: faSave,
    faGlobe: faGlobe,
    faFlag: faFlag,
    faClosedCaptioning: faClosedCaptioning,
    faQuestion: faQuestion
  };

  constructor() { }

  ngOnInit() {

  }

  public get ToolMode() {
    return ToolMode;
  }

}
