import {PlayerOptions} from 'yt-player-angular';

export class YoutubeSettings {

	public active:boolean = false;
	public videoId:string = '';

	public playerOptions:PlayerOptions = {
		width: 300,
		height: 200,
		autoplay: true,
		controls: false,
		keyboard: false,
		fullscreen: true,
		annotations: false,
		modestBranding: true,
		related: false,
		info: false,
		timeupdateFrequency: 500,
		playsInline: true
	};

}
