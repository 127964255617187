import {Subscription} from "rxjs";
import {OnDestroy} from "@angular/core";

// TODO: Add Angular decorator.
export class SubscriptionCleaner implements OnDestroy {

	protected _trackedSubscriptions:Array<Subscription> = [];

	public trackSubscription(subscription:Subscription):Subscription {
		this._trackedSubscriptions.push(subscription);
		return subscription;
	}

	public unsubscribeAndStopTracking(subscription:Subscription):Subscription {
		let index:number = this._trackedSubscriptions.indexOf(subscription);
		if(index !== -1) {
			this._trackedSubscriptions.splice(index, 1);
		}
		subscription.unsubscribe();
		return subscription;
	}

	public cleanUpSubscriptions():void {
		for(let i:number = 0; i < this._trackedSubscriptions.length; i++) {
			let subscription:Subscription = this._trackedSubscriptions[i];
			subscription.unsubscribe();
		}
		this._trackedSubscriptions = [];
	}

	ngOnDestroy():void {
		this.cleanUpSubscriptions();
	}

}
