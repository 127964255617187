import {Injectable} from '@angular/core';
import {AngularFireDatabase, QueryFn} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import firebase from "firebase";
import {classToPlain, plainToClass} from 'class-transformer';
import {UserRepository} from '../repository/UserRepository';
import {OnlineStatus, User} from '../domain/User';
import {animals} from "angular-animals";

@Injectable({
	providedIn: 'root'
})
export class RealtimePresenceService {

	private _user:User = null;
	public user$:Subject<User> = new Subject();

	constructor(private db:AngularFireDatabase, protected  userRepository:UserRepository) {

		this.onUserLoggedIn().subscribe(user => {
			if (user != null) {

				console.log(`Login detected.  ${user.guid}`);
				this._user = user;
				this.cleanupOnDisconnect();
				this.setPresence(user, OnlineStatus.online);
				this.user$.next(this._user);
			} else {

			}
		});
	}

	public get user():User {
		return this._user;
	}

	public get timestamp() {
		return firebase.database.ServerValue.TIMESTAMP;
	}

	public getPresence(uid:string):Observable<User> {
		return this.db.object(`status/${uid}`).valueChanges().pipe(
			map<Object, User>((plainObject) => {
				return plainToClass(User, plainObject);
			})
		);
	}

	public setCaptionIndex(captionIndex:number) {
		if (this._user) {
			this._user.caption = captionIndex;
			this.userRepository.set(this._user);
			return this.db.object(`status/${this._user.guid}`).update({caption: captionIndex});
		}
	}

	public setNickname(nickname:string) {
		if (this._user) {
			this._user.nickname = nickname;
			this.userRepository.set(this._user);
			return this.db.object(`status/${this._user.guid}`).update({nickname: nickname});
		}
	}

	public async setPresence(user:User, status:OnlineStatus) {
		user.status = status;
		user.timestamp = this.timestamp;
		if (user) {
			let plainObject:any = classToPlain<User>(this._user);

			return this.db.object(`status/${user.guid}`).update(plainObject);
		}
	}

	// User navigates to a new tab, case 3
	public onAway() {
		document.onvisibilitychange = (e) => {
			if (document.visibilityState === 'hidden') {
				this.setPresence(this._user, OnlineStatus.away);
			} else {
				this.setPresence(this._user, OnlineStatus.online);
			}
		};
	}

	public cleanupOnDisconnect() {
		if (this._user) {
			this.db.object(`status/${this._user.guid}`).query.ref.onDisconnect()
				.remove(() => {
					//removed
					console.log(`this.user.guid ${this._user.guid} removed.`);
				});
		}
	}

	public getUsers(movieId:number = null):Observable<User[]> {
		let usersRef:firebase.database.Reference = this.db.database.ref('status');
		let orderByGuid:QueryFn = (ref) => {
			return ref.orderByChild('guid');
		};

		return this.db.list(usersRef, orderByGuid).snapshotChanges().pipe(
			map(actions => {
				return actions.map(item => {

					const plain = item.payload.val();
					const key = item.payload.key;
					const user:User = plainToClass(User, plain);
					return user;
				});
			})
		);
	}
	protected validateName(user:User):boolean {
		if(user == null) {
			return false;
		}
		//console.log("validateName: " + animals.includes(user.avatar.animal));
		return animals.includes(user.avatar.animal);
	}
	// Updates status when logged-in connection to Firebase starts
	public onUserLoggedIn():Observable<User> {
		let subject:Subject<User> = new Subject<User>();

		let user:User = this.userRepository.get();
		if(!this.validateName(user)) {
			console.log("invalid user name. Deleting cookie");
			this.userRepository.delete();
			let newUser:User = new User();
			this.userRepository.set(newUser);
			subject.next(newUser);
		} else {
			if (user != null && user.guid != '') {
				setTimeout(() => {
					subject.next(user);
				}, 2000);

			} else {
				setTimeout(() => {
					let newUser:User = new User();
					this.userRepository.set(newUser);
					subject.next(newUser);
				}, 2000);
			}
		}

		return subject;
	}

}
