<div class="grid">
	<figure class="effect-bubba">
		<a href="javascript:void(0)" (click)="view()">
			<img [src]="series.image" class="effect-background"/>
		</a>
		<a href="javascript:void(0)" (click)="view()">
			<figcaption>
				<div class="row p-0 m-0 d-flex movie-buttons view-series">
					<div class="col-12 m-0 p-0">
						<a class="viewSeriesButton d-block w-100" [routerLink]="['/series', series.guid]">
							<fa-icon [icon]="icons.faArrowRight" transform="shrink-2" [inverse]="false"></fa-icon>
							<span class="playButtonText"> View Series</span>
						</a>
					</div>
				</div>
			</figcaption>
		</a>
	</figure>
</div>