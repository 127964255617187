import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, Subscription} from 'rxjs';


const baseUrl = 'https://www.omdbapi.com/?apikey=faf5bd7b';

@Injectable({
	providedIn: 'root'
})
export class OmdbMovieService {

	constructor(private http: HttpClient) { }

	public getMovies(query: string): Observable<ImdbSearchResults> {
		return this.http.get(baseUrl, {
			params: {
				s: query
			}
		}) as Observable<ImdbSearchResults>;
	}

	public getMovieDetails(imdbID: string): Observable<any> {
		return this.http.get(baseUrl, {
			params: {
				i: imdbID
			}
		});
	}
}

export interface ImdbSearchResults {
	'Search':Array<ImdbSearchResult>;
	'totalResults':string; //'2';
	'Response':string; // 'True';
}

export interface ImdbSearchResult {
	'Title':string;// 'Thor: Ragnarok',
	'Year':string; // '2017',
	'imdbID':string; // 'tt3501632',
	'Type':string;// 'movie',
	'Poster':string;// 'https://m.media-amazon.com/images/M/MV5BMjMyNDkzMzI1OF5BMl5BanBnXkFtZTgwODcxODg5MjI@._V1_SX300.jpg'
}
