/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./grid-timeline-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./grid-timeline-block.component";
var styles_GridTimelineBlockComponent = [i0.styles];
var RenderType_GridTimelineBlockComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_GridTimelineBlockComponent, data: {} });
export { RenderType_GridTimelineBlockComponent as RenderType_GridTimelineBlockComponent };
export function View_GridTimelineBlockComponent_0(_l) { return i1.ɵvid(2, [], null, null); }
export function View_GridTimelineBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rect", [["class", "bar"]], null, null, null, View_GridTimelineBlockComponent_0, RenderType_GridTimelineBlockComponent)), i1.ɵdid(1, 114688, null, 0, i2.GridTimelineBlockComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GridTimelineBlockComponentNgFactory = /*@__PURE__*/ i1.ɵccf("svg rect.bar", i2.GridTimelineBlockComponent, View_GridTimelineBlockComponent_Host_0, { fillColor: "fillColor", x: "x", y: "y", width: "width", height: "height", entry: "entry" }, {}, []);
export { GridTimelineBlockComponentNgFactory as GridTimelineBlockComponentNgFactory };

