import {SelectableCaption} from './SelectableCaption';
import * as _ from 'lodash';
import {Type} from 'class-transformer';
import {UserRef} from './UserRef';

export class GroupedCaptionSet {
	@Type(() => SelectableCaption)
	public set1:Array<SelectableCaption | string> = [];
	@Type(() => SelectableCaption)
	public set2:Array<SelectableCaption | string> = [];

	protected _set1Content:string = '';
	protected _set2Content:string = '';

	public _set2GoogleTranslatedContent:string = "";

	public static create(set1Text:string = '', set2Text:string = '') {
		let instance:GroupedCaptionSet = new GroupedCaptionSet(set1Text, set2Text);
		return instance;
	}

	public constructor(set1Text:string = '', set2Text:string = '') {
		this.set1Content = set1Text;
		this.set2Content = set2Text;
		this.set1 = this.createSelectableCaptionSet(set1Text);
		this.set2 = this.createSelectableCaptionSet(set2Text);
	}



	public get set1Content():string {
		return this._set1Content;
	}

	public set set1Content(value:string) {
		if (value != this.set1Content) {
			this.set1 = this.createSelectableCaptionSet(value);
		}
		this._set1Content = value;
	}


	public get set2Content():string {
		return this._set2Content;
	}

	public set set2Content(value:string) {
		if (value != this.set2Content) {
			this.set2 = this.createSelectableCaptionSet(value);
		}
		this._set2Content = value;
	}


	public get usedColors():Array<string> {
		return _.uniq(_.concat(this.getColorsInSet(this.set1), this.getColorsInSet(this.set2)));
	}

	public rerender():void {
		let renderedText:string = '';
		for (let i:number = 0; i < this.set1.length; i++) {
			const value:SelectableCaption | string = this.set1[i];
			if (value instanceof SelectableCaption) {
				renderedText += value.text;
			} else {
				renderedText += value;
			}
			this._set1Content = renderedText;
		}
		//set 2
		renderedText = '';
		for (let i:number = 0; i < this.set2.length; i++) {
			const value:SelectableCaption | string = this.set2[i];
			if (value instanceof SelectableCaption) {
				renderedText += value.text;
			} else {
				renderedText += value;
			}
			this._set2Content = renderedText;
		}
	}

	public getColorsInSet(set:Array<SelectableCaption | string>):Array<string> {
		const colorsInUse:Array<string> = [];
		for (let i:number = 0; i < set.length; i++) {
			// Black doesnʻt count (since it is default color)
			const current:SelectableCaption | string = set[i];
			if (current instanceof SelectableCaption) {
				if (current.color !== '#000000' && current.color !== '000000' && current.color !== undefined) {
					colorsInUse.push('#' + current.color);
				}
			}
		}
		return colorsInUse;
	}

	protected createSelectableCaptionSet(input:string):Array<SelectableCaption | string> {
		const setArray:Array<string> = input.split(' ');
		const result:Array<SelectableCaption | string> = [];

		for (let i:number = 0; i < setArray.length; i++) {
			const value:string = setArray[i];
			const caption:SelectableCaption = SelectableCaption.create(value);

			result.push(caption);
			if (i + 1 !== setArray.length) {
				result.push(' ');
			}
		}
		return result;
	}

}
