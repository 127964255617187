import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit} from '@angular/core';
import {NetflixMovieService} from '../../app/services/NetflixMovieService';
import {Router} from '@angular/router';

@Component({
	selector: 'app-oleloflix-splash',
	templateUrl: './oleloflix-splash.component.html',
	styleUrls: ['./oleloflix-splash.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OleloflixSplashComponent implements OnInit {

	@Input()
	private stopNavigation:boolean = false;

	@Input()
	public movieId:string = '';

	@Input()
	public seconds:number;

	@Input()
	public captionIndex:number;


	constructor(protected netflixMovieService:NetflixMovieService, protected router:Router) {

	}


	ngOnInit() {
		console.log("OleloflixSplashComponent: " + this.movieId);
		if(this.movieId != '' && this.movieId != null) {
			let captionIndex:number = this.cleanCaptionIndex();
			setTimeout(() => {
				if(this.secondsIsValid(this.seconds)) {
					if (!this.stopNavigation) {
						window.location.href = `https://www.netflix.com/watch/${this.movieId}?t=${this.seconds}&sl=en/#/movies/${this.movieId}/${captionIndex}`;
					}
				} else {
					if (!this.stopNavigation) {
						window.location.href = `https://www.netflix.com/watch/${this.movieId}?sl=en/#/movies/${this.movieId}/${captionIndex}`;
					}
				}

			}, 2500);
		}

	}

	public secondsIsValid(seconds:number):boolean {
		return !isNaN(seconds) && seconds != null && seconds != 0 && typeof seconds != "string";
	}

	public cleanCaptionIndex():number {
		if(!isNaN(this.captionIndex) && this.captionIndex != null && this.captionIndex != 0 && typeof this.captionIndex != "string") {
			return this.captionIndex;
		}
		return 0;
	}


}
