import {Component, OnInit} from '@angular/core';
import {UserRepository} from '../../app/repository/UserRepository';
import {User} from '../../app/domain/User';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthService} from '../../app/services/AuthService';
import {ZoomSettings} from "../../app/domain/config/ZoomSettings";
import {ParticipationOption} from "../../app/olelo-jam/olelo-jam.component";
import {RemoteConfigurationRepository} from "../../app/repository/RemoteConfigurationRepository";
import {UrlService} from "../../app/services/UrlService";
import {UpcomingEventRepository} from "../../app/repository/UpcomingEventRepository";
import {NotificationService} from "../../app/services/NotificationService";
import {SubscriptionCleaner} from "../../app/util/SubscriptionCleaner";
import {YoutubeSettings} from "../../app/domain/config/YoutubeSettings";

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('fadeInOut', [
			state('initial', style({
				opacity: 0
			})),
			state('final', style({
				opacity: 1
			})),
			transition('final=>initial', animate('1000ms')),
			transition('initial=>final', animate('1500ms'))
		]),
	]
})
export class HeaderComponent extends SubscriptionCleaner implements OnInit {

	public settings:ZoomSettings;
	private participationOption:ParticipationOption = ParticipationOption.participateActively;
	public youtube:YoutubeSettings;

	public windowFocus;

	public search = {
		query: ""
	}

	public user:User;

	public navMenu = {
		languages: false
	}

	public settingsVisible:boolean = false;


	constructor(protected remoteConfigurationRepository:RemoteConfigurationRepository,
				protected urlService:UrlService,
				protected upcomingEventRepository:UpcomingEventRepository,
				protected notificationService:NotificationService,
				private userRepository:UserRepository
				) {
		super();
		this.trackSubscription(super.trackSubscription(remoteConfigurationRepository.get$().subscribe(remoteConfig => {
			this.settings = remoteConfig.zoom;
		})));

		setTimeout(() => {
			this.user = this.userRepository.get();
		}, 500)
	}


	ngOnInit() {
	}

	public get source():string {
		//return `https://us04web.zoom.us/wc/${this.settings.meetingNumber}/start`;
		return this.settings.zoomLink;
	}

	public get youtubeSource():string {
		return `https://www.youtube.com/watch?v=${this.youtube.videoId}`;
	}

	public getAvatarUrl():string {
		if(this.user == null) {
			return "";
		}
		return ("https://api.adorable.io/avatars/:size/".replace(":size", "50") + this.user.guid.replace(" ", "-"));
	}
	public searchWehewehe(value:string) {
		this.search.query = value;
		let wehewheUrl:string = 'https://hilo.hawaii.edu/wehe/?q=' + this.search.query;
		window.open(wehewheUrl, "wehewehe");
		this.search.query = "";


	}

	onSearchClear() {
		this.search.query = "";
	}

	logout() {
		/*this.authService.logout();*/
	}

	public launchZoom() {
		//window.location.href = this.settings.zoomLink;
		this.participationOption = ParticipationOption.participateActively;
		this.newWindow();
	}
	public get hasOleloJamWindow():boolean {
		return this.windowFocus && window.focus && !this.windowFocus.closed;
	}
	private newWindow():void {
		if(this.hasOleloJamWindow) {
			this.focus();
		} else {
			if(this.participationOption == ParticipationOption.watchAndListen) {
				this.windowFocus = window.open(this.youtubeSource, 'jam', 'width=600,height=350');
				focus();
			} else if(this.participationOption == ParticipationOption.participateActively) {
				this.windowFocus = window.open(this.source, 'jam', 'width=800,height=550');
				focus();
			}
		}

		//some other code

	}
	public focus(): void {
		if (this.windowFocus && window.focus) {
			this.windowFocus.focus();
		}

		if (!this.windowFocus.closed) {
			this.windowFocus.focus();
		}
	}


}
