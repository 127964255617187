export enum Auth0AdapterExceptions {
	UNKNOWN = "UNKNOWN",
	UNABLE_TO_AUTO_LOGIN = "UNABLE_TO_AUTO_LOGIN"
}
export class Auth0AdapterException extends Error {

	public static readonly MAP:{[key:string]:string} = {
		UNKNOWN: "Unknown error",
		UNABLE_TO_AUTO_LOGIN: "Unable to auto-login the user in.  They need to re-enter their credentials",
	};
	public static readonly s = Auth0AdapterExceptions;

	public error:Auth0AdapterExceptions;
	public data:any;

	constructor(error:Auth0AdapterExceptions, data?:any, message?:string) {
		super(error); // 'Error' breaks prototype chain here
		Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
		this.error = error;
		this.data = data;
		if(message == null) {
			this.message = Auth0AdapterException.MAP[error];
		}
	}
	public map() {
		return Auth0AdapterException.MAP;
	}

}
