import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
})
export class ArrayFilterPipe implements PipeTransform {

	transform(list:any[], property:string, query:string|boolean):any {
		if(property == null || property == '') {
			return list;
		}
		return list.filter(item => {
			if(typeof query == 'boolean') {
				return (item[property] == query);
			}
			return (item[property]).toLowerCase().includes(query.toLowerCase());

		});
	}

}
