import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CaptionDiaryRepository} from '../repository/CaptionDiaryRepository';
import {NetflixMovieService} from '../services/NetflixMovieService';

@Component({
	selector: 'app-site',
	templateUrl: './site.component.html',
	styleUrls: ['./site.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SiteComponent implements OnInit {

	public isNavigating:boolean = false;
	public navigatingMovieId:string = '';
	public seconds:number = null;
	public captionIndex:number = null;

	constructor(protected router:Router, private diary:CaptionDiaryRepository, protected netflixMovieService:NetflixMovieService, protected route:ActivatedRoute) {

		this.netflixMovieService.playingMovieId$.subscribe(playMovie => {
			this.isNavigating = (String(playMovie.movieId) != '');
			this.navigatingMovieId = (String(playMovie.movieId));
			this.seconds = (playMovie.seconds);
			this.captionIndex = (playMovie.captionIndex);
		});
	}

	ngOnInit() {
		setTimeout(() => {
			this.netflixMovieService.detectExtension();
		}, 1000);
	}

}
