import {Injectable} from '@angular/core';
import {TimedNetflixCaption} from '../domain/database/TimedNetflixCaption';
import {AngularFirestore, CollectionReference} from '@angular/fire/firestore';
import {AbstractCompoundFirestoreRepository} from './AbstractCompoundFirestoreRepository';
import {Observable, Subscription} from 'rxjs';

@Injectable({
	providedIn: 'root',
} as any)
export class TimedNetflixCaptionRepository extends AbstractCompoundFirestoreRepository<TimedNetflixCaption> {

	public by = {
		flagged: (flagStatus:boolean) => {
			return (ref:CollectionReference) => {
				return ref.where("flagged", '==', flagStatus);
			};
		},

	};

	private _currentMovie:string = null;

	public static get PATH():string {
		return 'Netflix/%1$s/Captions/en-haw/Timings';
	};

	constructor(protected db:AngularFirestore) {
		super(TimedNetflixCaption, db, TimedNetflixCaptionRepository.PATH);

	}

	public watchList$(...params):Observable<TimedNetflixCaption[]> {


		let watchListObs:Observable<TimedNetflixCaption[]> = super.watchList$(...params);
		let s:Subscription = watchListObs.subscribe(results => {
			s.unsubscribe();
			console.log(results.length);
		});
		return watchListObs;
	}


	public get currentMovie():string {
		return this._currentMovie;
	}

	public set currentMovie(value:string) {
		this._currentMovie = value;
		while (this.defaultParams.length > 0) {
			this.defaultParams.pop();
		}
		this.defaultParams.push(value);
	}



}
