import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CaptioningComponent} from '../areas/captioning/captioning.component';
import {StartCaptioningComponent} from '../areas/start-captioning/start-captioning.component';
import {SiteComponent} from './site/site.component';
import {CallbackComponent} from './areas/auth/callback/callback.component';
import {ListPatronsComponent} from "../areas/patrons/list-patrons/list-patrons.component";
import {ListEpisodesComponent} from "../areas/series/list-episodes/list-episodes.component";
import {LoginComponent} from "../areas/auth/login/login.component";
import {RegisterComponent} from "../areas/auth/register/register.component";

export const routes:Routes = [

	{
		path: '',
		component:SiteComponent,
		children: [
/*			{
				path: 'callback',
				component: CallbackComponent
			},*/
			{
				path: '',
				component: StartCaptioningComponent
			},
			{
				path: 'series/:seriesGuid',
				component: ListEpisodesComponent
			},
			{
				path: 'series/:seriesGuid/episodes/:movieId/:captionId',
				component: CaptioningComponent
			},
			{
				path: 'movies/:movieId/:captionId',
				component: CaptioningComponent
			},
			{
				path: 'patrons',
				component: ListPatronsComponent
			},
			{
				path: 'login',
				component: LoginComponent,

			},
			{
				path: 'register',
				component: RegisterComponent,

			},

		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
