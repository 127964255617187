<div class="grid">
	<figure class="effect-bubba">
		<a [routerLink]="['/movies', movie.titleId, 0]">
			<img [src]="movie.image" class="effect-background"/>
		</a>
		<figcaption>
			<div class="row p-0 m-0 d-flex movie-buttons" *ngIf="movie.buttons == null">
				<div class="col-6 m-0 p-0">
					<a class="playButton d-block w-100" href="javascript:void(0);" (click)="watch(movie.titleId)">
						<fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
						<span class="playButtonText"> Play</span>
					</a>
				</div>
				<div class="col-6  m-0 p-0">
					<a class="playButton grey d-block w-100" [routerLink]="['/movies', movie.titleId, 0]">
						<fa-icon [icon]="icons.faClosedCaptioning" transform="shrink-2" [inverse]="false"></fa-icon>
						<span class="playButtonText"> Caption</span>
					</a>
				</div>
			</div>
			<div class="row p-0 m-0 d-flex movie-buttons" *ngIf="movie.buttons != null">
				<div class="col m-0 p-0" *ngIf="movie.buttons.play">
					<a class="playButton d-block w-100" href="javascript:void(0);" (click)="watch(movie.titleId)">
						<fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
						<span class="playButtonText"> Play</span>
					</a>
				</div>
				<div class="col  m-0 p-0" *ngIf="movie.buttons.caption">
					<a class="playButton grey d-block w-100" [routerLink]="['/movies', movie.titleId, 0]">
						<fa-icon [icon]="icons.faClosedCaptioning" transform="shrink-2" [inverse]="false"></fa-icon>
						<span class="playButtonText"> Caption</span>
					</a>
				</div>
			</div>
			<div class="row p-0 m-0 d-flex movie-buttons watch-party" *ngIf="movie.watchPartyUrl && movie.isWatchPartyActive">
				<div class="col-12 m-0 p-0">
					<a class="playButton d-block w-100" href="javascript:void(0);" (click)="joinWatchParty()">
						<fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
						<span class="playButtonText"> Join Watch Party</span>
					</a>
				</div>
			</div>
			<a [routerLink]="['/movies', movie.titleId, 0]">
				<h2>{{movie.title}}<br/>
<!--
				<img [src]="urlService.avatar.url(principalUser.guid)" class="clip-circle" [ngbTooltip]="principalUser.easyName">
-->
				</h2>
				<h1 *ngIf="diary">{{diary.prettyPercentage}}</h1>
			</a>
		</figcaption>
	</figure>
</div>
