import {Component, Input, OnInit} from '@angular/core';
import {MovieLink} from "../../app/domain/MovieLink";
import {NetflixMovieService} from "../../app/services/NetflixMovieService";
import {UrlService} from "../../app/services/UrlService";
import {faClosedCaptioning, faPlay} from "@fortawesome/free-solid-svg-icons";
import {Provider} from "../../app/domain/providers/Provider";

@Component({
    selector: 'app-movie-link',
    templateUrl: './movie-link.component.html',
    styleUrls: ['./movie-link.component.scss']
})
export class MovieLinkComponent implements OnInit {

    @Input()
    public movieLink: MovieLink;
    public image: string = null;

    public icons = {
        faPlay: faPlay,
        faClosedCaptioning: faClosedCaptioning
    };

    constructor(protected netflixMovieService: NetflixMovieService, protected urlService: UrlService) {
    }

    ngOnInit() {

    }

    public navigate() {
        if (this.movieLink && this.movieLink.link) {
            window.location.href = this.movieLink.link;
        }
    }

    public imageUrl():string {
        return this.urlService.poster.url(Provider.Other, this.movieLink.guid);
    }
}
