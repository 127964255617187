import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {OleloJamRegistrationRepository} from '../../repository/OleloJamRegistrationRepository';
import {OleloJamRegistration} from '../../domain/olelojam/OleloJamRegistration';
import {RealtimePresenceService} from '../../services/RealtimePresenceService';


@Component({
  selector: 'app-olelo-jam-registration-modal',
  templateUrl: './olelo-jam-registration-modal.component.html',
  styleUrls: ['./olelo-jam-registration-modal.component.scss']
})
export class OleloJamRegistrationModalComponent implements OnInit {

	public component:any;
	public content:{};

	public registration:OleloJamRegistration = new OleloJamRegistration();

	public isValidInput:boolean = true;


	constructor(public bsModalRef:BsModalRef, public router:Router, private oleloJamRegistrationRepository:OleloJamRegistrationRepository, private realtimePresenceService:RealtimePresenceService) {

	}

	ngOnInit() {

	}
	public onProperties(properties:object):void {
		this.content = properties;
	}

	public dismiss() {
		this.bsModalRef.hide();
	}


	submit() {
		this.registration.currentJam = "avatar-the-last-airbender/season-1/episode-3";
		this.isValidInput = this.registration.firstName != "" && this.registration.lastName  != "" && this.registration.email  != "" && this.registration.reasonForAttending != "" ;
		if(!this.isValidInput) {
			return;
		}
		this.oleloJamRegistrationRepository.save$(this.registration);
		this.realtimePresenceService.setNickname(`${this.registration.firstName} ${this.registration.lastName}`);
		this.dismiss();
	}
}
