import {Converter} from "../ConversionService";
import {GroupedCaptionSet} from '../../domain/GroupedCaptionSet';
import {classToPlain, plainToClass} from "class-transformer";
import {WordBankGroupedCaptionSet} from "../../domain/bank/WordBankGroupedCaptionSet";

export class GroupedCaptionSetToWordBankGroupedCaptionSetConverter implements Converter {

  public from:typeof GroupedCaptionSet;
  public to:typeof WordBankGroupedCaptionSet;

  constructor() {
    this.from = GroupedCaptionSet;
    this.to = WordBankGroupedCaptionSet;
  }

  public convert(source:GroupedCaptionSet):WordBankGroupedCaptionSet {

    //Create the target object
    let plain = classToPlain(source);
    plain['guid'] = null;
    let asClass:WordBankGroupedCaptionSet = plainToClass(WordBankGroupedCaptionSet, plain);
    asClass.generateGuid();
    return asClass;
  }
}
