import {Injectable} from "@angular/core";
import {AbstractCookieRepository} from "./AbstractCookieRepository";
import {CookieService} from "ngx-cookie-service";
import {User} from "../domain/User";

@Injectable()
export class UserRepository extends AbstractCookieRepository<User> {

  constructor(cookieService:CookieService) {
        super(User, cookieService,null,"user");
  }

}

