<section class="fxt-template-animation fxt-template-layout2 has-animation" [ngClass]="{loaded: loaded}">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-12 fxt-bg-color">
				<div class="fxt-content">
					<div class="fxt-header">
						<a href="javascript:void(0)" class="fxt-logo"><img src="./assets/oleloflix-icon.svg" alt="ʻŌleloflix"></a>
<!--						<h1>Sign In</h1>-->
<!--						<ul class="fxt-socials">
							<li class="fxt-google"><a href="#" title="google"><i class="fab fa-google-plus-g"></i><span>Google +</span></a></li>
							<li class="fxt-twitter"><a href="#" title="twitter"><i class="fab fa-twitter"></i><span>Twitter</span></a></li>
							<li class="fxt-facebook"><a href="#" title="Facebook"><i class="fab fa-facebook-f"></i><span>Facebook</span></a></li>
						</ul>-->
						<div class="fxt-style-line">
							<h1>Sign In</h1>
						</div>
					</div>
					<div class="fxt-form">
						<form [formGroup]="formGroup"  autocomplete="off">
							<div class="form-group" [customErrorMessages]="errorsFor('username')">
								<div class="fxt-transformY-50 fxt-transition-delay-1">
									<input type="email" class="form-control" name="email" placeholder="Email Address" required="required" appAutofocus>
									<bfv-messages></bfv-messages>
								</div>
							</div>
							<div class="form-group" [customErrorMessages]="errorsFor('password')">
								<div class="fxt-transformY-50 fxt-transition-delay-2" >
									<input type="password" class="form-control" name="password" placeholder="Password" required="required">
									<i toggle="#password" class="fa fa-fw fa-eye toggle-password field-icon"></i>
									<bfv-messages></bfv-messages>
								</div>
							</div>
							<div class="form-group">
								<div class="fxt-transformY-50 fxt-transition-delay-3">
									<div class="fxt-checkbox-area">
										<div class="checkbox">
											<input id="checkbox1" type="checkbox">
											<label for="checkbox1">Keep me logged in</label>
										</div>
										<a (click)="forgotPassword.emit();" class="switcher-text">Forgot Password</a>
									</div>
								</div>
							</div>
							<div>
								{{error}}
							</div>
							<div class="form-group">
								<div class="fxt-transformY-50 fxt-transition-delay-4">
									<button type="submit" class="fxt-btn-fill" [disabled]="this.formGroup.invalid" (click)="onLoginClick()">Log in</button>
								</div>
							</div>
						</form>
					</div>
					<div class="fxt-footer">
						<div class="fxt-transformY-50 fxt-transition-delay-5">
							<p>Don't have an account?<a (click)="register.emit()" class="switcher-text">Register</a></p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 fxt-none-991 fxt-bg-img" data-bg-image="img/figure/bg2-l.jpg"></div>
		</div>
	</div>
</section>
