
export class SelectableCaption {

  public text:string = "";
  public color:string = "#000000";

  public constructor(text:string = "", color:string = "#000000") {
    this.text = text;
    this.color = color;
  }

  public static create(text:string = "", color:string = "#000000") {
    return new SelectableCaption(text, color);
  }

  public toString():string {
    return this.text;
  }
}
