import {Exclude} from 'class-transformer';

export class OleloJamRegistration {

	@Exclude({toPlainOnly:true})
	public guid:string = null;

	public firstName:string = "";
	public lastName:string = "";
	public email:string = "";
	public reasonForAttending:string = "";
	public currentJam:string = null;

}
