import {Injectable} from "@angular/core";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable} from "rxjs";

import {AbstractFirebaseFunctionsFactory} from "./standard/AbstractFirebaseFunctionsFactory";
import {AbstractFirebaseFunction, IServerError} from "./standard/AbstractFirebaseFunction";
import {NewUserRegistration} from '../../domain/registration/NewUserRegistration';
import {OleloflixUser} from '../../domain/users/OleloflixUser';
import {NGXLogger} from 'ngx-logger';
import {CompleteUserRegistration} from '../../domain/registration/CompleteUserRegistration';

interface RegistrationFunctionWrappers {
	register: AbstractFirebaseFunction<NewUserRegistration, CompleteUserRegistration>,
}

@Injectable({
	providedIn: 'root',
} as any)
export class RegistrationFunctions extends AbstractFirebaseFunctionsFactory {

	public methods:RegistrationFunctionWrappers;

	constructor(protected functions:AngularFireFunctions, protected logger:NGXLogger) {
		super(functions, {register: [NewUserRegistration, CompleteUserRegistration]});
	}

	public register$(message:NewUserRegistration):Observable<CompleteUserRegistration | IServerError<NewUserRegistration>> {
		this.logger.info(`RegistrationFunctions::register$`);

		let response = this.methods.register.call(message);
		this.logger.info(`RegistrationFunctions::register$ done`);
		return response;
	}

}
