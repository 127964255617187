import {Component, Directive, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OleloEntryButtonComponent} from '../olelo-entry-button/olelo-entry-button.component';

@Component({
  selector: 'app-olelo-entry-controls',
  templateUrl: './olelo-entry-controls.component.html',
  styleUrls: ['./olelo-entry-controls.component.scss']
})
export class OleloEntryControlsComponent implements OnInit {
	get isUpperCase():boolean {
		return this._isUpperCase;
	}

	@ViewChild("a")
	public a:OleloEntryButtonComponent;

	@ViewChild("e")
	public e:OleloEntryButtonComponent;

	@ViewChild("i")
	public i:OleloEntryButtonComponent;

	@ViewChild("o")
	public o:OleloEntryButtonComponent;

	@ViewChild("u")
	public u:OleloEntryButtonComponent;

	@ViewChild("okina")
	public okina:OleloEntryButtonComponent;

	private _isUpperCase:boolean = false;

	@Output()
	public letter:EventEmitter<string> = new EventEmitter();
	private _capsOn:boolean = false;
	private _shiftOn:boolean;

    constructor() { }

	ngOnInit() {


	}

	public get capsOn():boolean {
		return this._capsOn;
		this.recalculateUpper();
	}

	public set capsOn(value:boolean) {
		console.log(`caps lock is ${value}`);
		this._capsOn = value;
	}
	public get shiftOn():boolean {
		return this._shiftOn;
	}
	public set shiftOn(value:boolean) {
		this._shiftOn = value;
		this.recalculateUpper();
	}
	public recalculateUpper():void {
    	let capsAndShift:boolean = this._capsOn && this._shiftOn;
    	let justOne:boolean = this._capsOn || this._shiftOn;
    	if(capsAndShift || justOne) {
    		this._isUpperCase = true;
	    } else {
    		this._isUpperCase = false;
	    }
    	if(this._isUpperCase) {
    		this.a.label = this.a.label.toUpperCase();
		    this.e.label = this.e.label.toUpperCase();
		    this.i.label = this.i.label.toUpperCase();
		    this.o.label = this.o.label.toUpperCase();
		    this.u.label = this.u.label.toUpperCase();
	    } else {
		    this.a.label = this.a.label.toLowerCase();
		    this.e.label = this.e.label.toLowerCase();
		    this.i.label = this.i.label.toLowerCase();
		    this.o.label = this.o.label.toLowerCase();
		    this.u.label = this.u.label.toLowerCase();
	    }
	}

	onClick(value:OleloEntryButtonComponent) {
    	console.log("controls click event: " + value.label)
		this.letter.emit(value.label);
	}
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
	@Output()
	public capsLock:EventEmitter<Boolean> = new EventEmitter<Boolean>();
	private alreadyBroadcastOnce:boolean = false;

	@HostListener('window:keydown', ['$event'])
	public onKeyDown(event: KeyboardEvent): void {
		const capsOn = event.getModifierState && event.getModifierState('CapsLock');

		this.capsLock.emit(capsOn);
	}
	@HostListener('window:keyup', ['$event'])
	public onKeyUp(event: KeyboardEvent): void {
		const capsOn = event.getModifierState && event.getModifierState('CapsLock');
		this.capsLock.emit(capsOn);
	}
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[shift]' })
export class TrackShiftDirective {
	@Output()
	public shift:EventEmitter<Boolean> = new EventEmitter<Boolean>();

	@HostListener('window:keydown', ['$event'])
	public onKeyDown(event: KeyboardEvent): void {
		const capsOn = event.getModifierState && event.getModifierState('Shift');
		this.shift.emit(capsOn);
	}
	@HostListener('window:keyup', ['$event'])
	public onKeyUp(event: KeyboardEvent): void {
		const capsOn = event.getModifierState && event.getModifierState('Shift');
		this.shift.emit(capsOn);
	}
}
