<div class="grid">
    <figure class="effect-bubba">
        <a [routerLink]="movieLink.link">
            <img [src]="this.imageUrl()" class="effect-background"/>
        </a>
        <figcaption>
            <div class="row p-0 m-0 d-flex movie-buttons">
                <div class="col-12 m-0 p-0">
                    <a class="playButton d-block w-100" href="javascript:void(0);" (click)="navigate()">
                        <fa-icon [icon]="icons.faPlay" transform="shrink-2" [inverse]="false"></fa-icon>
                        <span class="playButtonText"> Play</span>
                    </a>
                </div>
            </div>
            <a [routerLink]="movieLink.link">
                <h2>{{movieLink.title}}</h2>
            </a>
        </figcaption>
    </figure>
</div>
