<div bsModal class="modal-content custom-modal">
    <div class="modal-header bg-info w-100">
        <h4 class="modal-title">Word Bank Edit Mode</h4>
    </div>
    <div class="modal-body form-group" *ngIf="flashcardCollection">
        <div class="input-group mb-3" *ngIf="mode == FlashCardCollectionModalComponentMode.Create">
            <input type="text" class="form-control" placeholder="Enter a Title..." [(ngModel)]="this.flashcardCollection.title" >
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="create()" [disabled]="this.flashcardCollection.title < 5">Add to Bank</button>
            </div>
            <small>5 Character minimum</small>
        </div>
        <div class="input-group mb-3" *ngIf="mode == FlashCardCollectionModalComponentMode.View  || mode == FlashCardCollectionModalComponentMode.Edit">
            <h1>{{flashcardCollection.title}}</h1>
            {{flashcardCollection.configuration.visibility}}
        </div>
    </div>
</div>
