import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AddMovieRequest} from "../../../app/domain/forms/AddMovieRequest";
import {NetflixMovieService} from "../../../app/services/NetflixMovieService";

@Component({
	selector: 'app-add-slipper-modal',
	templateUrl: './add-slipper-modal.component.html',
	styleUrls: ['./add-slipper-modal.component.scss']
})
export class AddSlipperModalComponent implements OnInit, OnDestroy {

	public component:any;
	public content:any;

	public addMovieRequest:AddMovieRequest = new AddMovieRequest();

	constructor(public bsModalRef:BsModalRef, private netflixMovieService:NetflixMovieService) {

	}

	public ngOnInit():void {

	}

	public ngOnDestroy() {

	}
	public onProperties(properties:object):void {
		this.content = properties;
	}

	public hide():void {
		this.bsModalRef.hide();
	}

	public submit():void {
		this.netflixMovieService.suggestNewMovie(this.addMovieRequest);
		this.hide();
	}
}
