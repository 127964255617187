import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CaptionDiary, CaptionDiaryEntry, CaptionDiaryEntryRangeSummary} from '../../app/domain/CaptionDiary';
import {Rectangle} from 'angular-svg';
import {CaptionDiaryService} from '../../app/services/CaptionDiaryService';

@Component({
  selector: 'app-grid-timeline',
  templateUrl: './grid-timeline.component.html',
  styleUrls: ['./grid-timeline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTimelineComponent implements OnInit, AfterViewInit {

	@ViewChild("mysvg")
	public mysvg:SVGGraphicsElement;

	@Input()
	public count:number = 500;

	@Input()
	public captionDiary:CaptionDiary;


	@Output()
	public selected:EventEmitter<CaptionDiaryEntry> = new EventEmitter<CaptionDiaryEntry>()




	public fillColor = {
		regular: "white",
		over: "#7AC143"
	};



	public innerWidth: any;

  constructor(private captionDiaryService:CaptionDiaryService) {
/*  	this.captionDiaryService.watchDiary$().subscribe(captionDiary => {
  	    this.captionDiary = captionDiary;
    });*/

  }

  ngOnInit() {
	  this.innerWidth = window.innerWidth;
	  console.log(`innerWidth is ${window.innerWidth} and cell width for ${this.count} is ${this.getGridCellWidth()}`)


  }
  public getGridCellWidth() {
		return window.innerWidth/this.count;
  }
  public getGridCellX(count:number) {
  	return count * (this.getGridCellWidth()) ;
  }

  public getRectangle(count):Rectangle {
  	let rectangle:Rectangle = {
		    x: this.getGridCellX(count),
			y: 0,
		    rx: 0,
		    ry: 0,
			  height: 5,
			  width: this.getGridCellWidth(),
			  stroke: null,
    };
  	return rectangle;
  }

	ngAfterViewInit():void {
  	    //console.log("SVG width is:  " + this.mysvg.getBBox().width);

	}
	public createRange():Array<CaptionDiaryEntryRangeSummary> {
	  	let spacing:number = Math.floor(this.captionDiary.length / this.count);
		//let floorSpacing:number = Math.floor(spacing);

		let captions:Array<CaptionDiaryEntry> = this.captionDiary.toArray();
		let favorites:Array<CaptionDiaryEntryRangeSummary> = [];

		for(let i:number = 0; i < this.count; i++) {
			let startInternalRangeIndex = i * spacing;
			let endInternalRangeIndex = (i * spacing) + (spacing);
			let captionRange:Array<CaptionDiaryEntry> = captions.slice(startInternalRangeIndex, endInternalRangeIndex);
			let captionRangeFavorite:CaptionDiaryEntry;


			let completeItems:Array<CaptionDiaryEntry> = captionRange.filter(captionDiaryEntry => {
				return captionDiaryEntry.complete;
			});
			let partialItems:Array<CaptionDiaryEntry> = captionRange.filter(captionDiaryEntry => {
				return !captionDiaryEntry.complete; //partial means not complete
			});
			let flaggedItems:Array<CaptionDiaryEntry> = captionRange.filter(captionDiaryEntry => {
				return captionDiaryEntry.flagged;
			});
			if(flaggedItems.length > 0) {
				captionRangeFavorite = flaggedItems[0];
			} else if(completeItems.length > 0 && partialItems.length > 0) {
				captionRangeFavorite = partialItems[0];
			} else if(completeItems.length) {
				captionRangeFavorite = completeItems[0];
			} else {
				captionRangeFavorite = captionRange[0];
			}

			let captionRangeFavoriteAsFinal:CaptionDiaryEntryRangeSummary = new CaptionDiaryEntryRangeSummary();
			captionRangeFavoriteAsFinal.complete = captionRangeFavorite.complete;
			captionRangeFavoriteAsFinal.flagged = captionRangeFavorite.flagged;
			captionRangeFavoriteAsFinal.index = captionRangeFavorite.index;
			captionRangeFavoriteAsFinal.partial = (completeItems.length > 0 && partialItems.length > 0)
			captionRangeFavoriteAsFinal.subtitleId = captionRangeFavorite.subtitleId;


			favorites.push(captionRangeFavoriteAsFinal);
		}


		return favorites;
	}

	public onTimelineIndexClicked(entry:CaptionDiaryEntry) {
  	    console.log("index: " + entry.index);
		this.selected.emit(entry);

	}

	foo(event:MouseEvent) {

		// @ts-ignore (for event.layerX missing in Typescript now)
		let percentage:number = event.layerX/innerWidth;
		console.log(percentage +"%")
		let designatedCaptionIndex:number = Math.floor(percentage * this.captionDiary.length);

		let entry:CaptionDiaryEntry = this.captionDiary.toArray()[designatedCaptionIndex];
		if(entry) {
			this.onTimelineIndexClicked(entry);
		}

		//this.onTimelineIndexClicked()
	}
}
