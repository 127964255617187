import {EventEmitter, Injectable} from '@angular/core';
import {PostMessageHandler} from 'typescript-postmessagehandler';

@Injectable()
export class PostMessageService {

  public messageHandler:PostMessageHandler;

  public skipClicked:EventEmitter<{}> = new EventEmitter();

  constructor() {
    this.register("secret-key");
  }

  public register(key:string):void {
      this.messageHandler = new PostMessageHandler(key, window.parent, document.referrer);
      this.messageHandler.subscribe((data) => this.onReceive(data));
  }
  public send(data:{}):void {
    this.messageHandler.send(data);
  }

  public onReceive(data:{}):void {
    console.log("data received:\n" + JSON.stringify(data, null, 2));
    this.skipClicked.next(data);

//    const MessageHandler = new PostMessageHandler('my-secret-key', window.parent, document.referrer);

  }


}
