<div bsModal class="modal-content custom-modal">
	<div class="modal-header bg-info w-100">
		<h4 class="modal-title">Word Bank Edit Mode</h4>
	</div>
	<div class="modal-body form-group" *ngIf="wordBank">
		<div class="input-group mb-3" *ngIf="mode == WordBankEditModalComponentMode.Create">
			<input type="text" class="form-control" placeholder="English Word..." [(ngModel)]="createWord" >
			<div class="input-group-append">
				<button class="btn btn-outline-secondary" type="button" (click)="createSuggestion()">Add to Bank</button>
			</div>
		</div>
		<ul class="list-group" *ngIf="mode == WordBankEditModalComponentMode.View || mode == WordBankEditModalComponentMode.AddSuggestion">
			<li class="list-group-item p-2">{{wordBank.word}}</li>
			<li class="list-group-item p-2">{{wordBank.chosenSuggestion}}</li>
		</ul>
		<ul class="list-group" *ngIf="mode == WordBankEditModalComponentMode.View || mode == WordBankEditModalComponentMode.AddSuggestion">
			<li class="list-group-item" *ngFor="let suggestion of wordBank.suggestions">{{suggestion}} 	<button class="btn btn-sm btn-outline-secondary"  *ngIf="!wordBank.locked" type="button" (click)="chooseSuggestion(suggestion)">Choose</button> <button class="btn btn-outline-danger btn-sm" type="button" (click)="removeSuggestion(suggestion)">-</button></li>
		</ul>
		<div class="input-group mb-3" *ngIf="mode == WordBankEditModalComponentMode.View || mode == WordBankEditModalComponentMode.AddSuggestion">
			<input type="text" class="form-control" placeholder="Suggestion..." [(ngModel)]="newWordSuggestion" style="border: solid black 1px;height: 42px;line-height: 42px;">
			<div class="input-group-append">
				<button class="btn btn-outline-secondary" type="button" (click)="addSuggestion()">Suggest</button>
			</div>
		</div>
<!--
		<p class="text-center color-red" *ngIf="!isValidInput">Please make sure you enter all fields</p>
-->
	</div>
	<div class="modal-footer border-0">
		<button type="button" class="btn btn-danger" (click)="wantsToDelete = true;" *ngIf="!wantsToDelete">Delete</button>
	</div>
	<ng-container *ngIf="wantsToDelete">
		<div class="modal-footer border-0">
			<b>Are you sure?</b><br/>
			<button type="button" class="btn btn-danger" (click)="delete();dismiss()">Yes, Delete!</button>
			<button type="button" class="btn btn-secondary" (click)="wantsToDelete = false">Nevermind</button>
		</div>
	</ng-container>
	<div class="modal-footer border-0">
		<button type="button" class="btn btn-light" (click)="dismiss()">Close</button>
	</div>
</div>
