<input type="text" (keyup.enter)="submitComment()" [(ngModel)]="this.newCaptionComment.comment" class="w-75">&nbsp;<button (click)="submitComment()">Submit</button>


<div *ngFor="let caption of comments">
	<div class="card bg-light">
		<div class="card-body bg">
				<div class="row">
					<div class="col-md-2">
						<span class="font-weight-bold">{{caption.userRef.easyName}}@{{caption.captionIndex}}</span>
					</div>
					<div class="col-md-8 text-left">
						<span>{{caption.comment}}</span>
					</div>
					<div class="col-md-1">
						<button class="text-sm-center" (click)="goto(caption)">Go To</button>
					</div>
					<div class="col-md-1">
						<button class="text-sm-center bg-red text-black-50" (click)="delete(caption)">Delete</button>
					</div>
				</div>
		</div>
	</div>
</div>


