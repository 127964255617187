<h1>Mahalo to our Supporters!</h1>
<ng-container *ngFor="let tier of (tiers | keyvalue)">
    <div class="central-meta" *ngIf="tiers[tier.key].length > 0">
        <div class="title-block">
            <div class="row">
                <div class="col-6 text-left">
                    <div class="align-left">
                        <h5>Mahalo {{getTierLabel(tier.key)}} Members</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" >
        <div class="row merged20">
            <div class="col-lg-4 col-md-6 col-sm-6 app-patron-in-list" [patron]="patron" *ngFor="let patron of tier.value"></div>
        </div>
    </div>
</ng-container>